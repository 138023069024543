import React, { useEffect, useMemo, useState } from "react";
import { CAMPAIGN_COLUMNS, INFLUENCER_CAMPAIGN_COLUMNS } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  SkeletonText,
  Spinner,
  Table,
  TableContainer,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import Checkbox1 from "./Checkbox";
import { EmailStatusModal } from "../Modal/EmailStatusModal";
import CampaignApprovalDialogBox from "../Modal/CampaignApprovalDialogBox";
import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { IoMdTrash } from "react-icons/io";
import CampaignDeleteDialogBox from "../Modal/CampaignDeleteDialogBox";
import CampaignsRejectDialogBox from "../Modal/CampaignsRejectDialogBox";
import { BsFiletypeCsv, BsFilter, BsBuildingFillAdd } from "react-icons/bs";
import { PulseLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import "./Scouting.css"
const CampaignTable = () => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const toast = useToast();
  const { colorMode } = useColorMode();
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const columns = useMemo(() => {
    if (role === "Influencer") {
      return INFLUENCER_CAMPAIGN_COLUMNS || [];
    } else if (role === "Admin") {
      return CAMPAIGN_COLUMNS || [];
    } else if (role === "Creator Manager") {
      return CAMPAIGN_COLUMNS || [];
    }
  }, [role]);
  const [roles, setRoles] = useState(role);
  // const columns = useMemo(() => CAMPAIGN_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [campaignData, setCampaignData] = useState([]);
  const [sequences, setSequences] = useState([]);
  // const [emailList, setEmailList] = useState([]);
  const [userEmails, setUserEmails] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  // const [campaignDetails, setCampaignDetails] = useState(null);
  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);
  const [isOpenApprovalModal, setIsOpenApprovalModal] = useState(false);

  const [isOpenCampaignDeleteDialogBox, setIsOpenCampaignDeleteDialogBox] =
    useState(false);
  const [isOpenCampaignRejectDialogBox, setIsOpenCampaignRejectDialogBox] =
    useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [lastTapTime, setLastTapTime] = useState(0);

  const [analyticsData, setAnalyticsData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [inputAccountValue, setInputAccountValue] = useState("");
  const [inputCompanyValue, setInputCompanyValue] = useState("");
  const [inputTalentValue, setInputTalentValue] = useState("");

  const [isAccountSearchLoading, setIsAccountSearchLoading] = useState(false);
  const [isCompanySearchLoading, setIsCompanySearchLoading] = useState(false);
  const [isTalentSearchLoading, setIsTalentSearchLoading] = useState(false);

  const [filteredAccountData, setFilteredAccountData] = useState([]);
  const [filteredCompanyData, setFilteredCompanyData] = useState([]);
  const [filteredTalentData, setFilteredTalentData] = useState([]);

  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedTalents, setSelectedTalents] = useState([]);
  const [selectedRejectRows, setSelectedRejectRows] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);

  const [isExportLoading, setIsExportLoading] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: campaignData,
      initialState: { pageIndex: 0, pageSize: 15 },
      manualPagination: true,
      pageCount,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (roles !== "Influencer") {
          return [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <Checkbox1 {...getToggleAllRowsSelectedProps()} />
              ),
              Cell: ({ row }) => (
                <Checkbox1 {...row.getToggleRowSelectedProps()} />
              ),
            },
            ...columns,
          ];
        }
        return columns;
      });
    }
  );

  const fetchCampaignData = async () => {
    try {
      const statusParams = selectedStatus
        .map((status) => `status=${status}`)
        .join("&");

      const accountParams = selectedAccounts
        .map((account) => `account=${account}`)
        .join("&");

      const companyParams = selectedCompanies
        .map((company) => `company_name=${company}`)
        .join("&");
      const talentParams = selectedTalents
        .map((talent) => `influencer_talent_name=${talent}`)
        .join("&");

      const response1 = await fetch(
        BASE_URL +
          `/campaigns/?${statusParams}&${accountParams}&${companyParams}&${talentParams}&page=${
            pageIndex + 1
          }&page_size=${pageSize}&ordering=-updated_ts`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data1 = await response1.json();
      if (data1.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      setCampaignData(data1?.campaigns_data);
      setPageCount(data1?.paginator?.total_pages);
      setTotalItems(data1?.paginator?.total_items);
      setIsLoading(false);
      fetchSequences();
      fetchUserEmails();
    } catch (error) {
      // setIsLoading(false);

      toast({
        title: "Fetch Error.",
        description: "Failed to fetch campaign details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchCampaignData();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [
    pageIndex,
    pageSize,
    filterValue,
    selectedStatus,
    selectedAccounts,
    selectedCompanies,
    selectedTalents,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSequences();
      fetchUserEmails();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleCellBlur = () => {
    setIsEditing(null);
    setEditingCell(null);
    handleSaveRow();
  };

  const handleCellClick = (row, cell) => {
    if (cell.column.id === "company_specialties") {
      setIsEditing(row.original.campaign_id);

      setEditingCell(cell.column.id);
      setEditedRowData([row.original]);
    }
  };
  // .................................................
  const handleSaveRow = async () => {
    const payload = {
      campaign_data: editedRowData,
    };

    try {
      const response = await fetch(BASE_URL + `/update-campaigns/`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        toast({
          title: "Company Specialties Updated",
          description: "Company updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to update Campaiegn details.");
      }
    } catch (error) {
      if (error.response.data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      toast({
        title: "Update Failed",
        description: "Failed to update Campaiegn details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  // .................................................
  const handleCampaignRejectModal = (row) => {
    setSelectedRejectRows(row.original);
    setIsOpenCampaignRejectDialogBox(true);
  };

  const handleEmailStatusClick = (row) => {
    setSelectedCampaign(row.original);
    // setEmailStatusId(row.original.email_schedule)
    setIsOpenStatusModal(true);
  };
  const handleApproveClick = (row) => {
    setSelectedCampaign(row.original);
    // setEmailStatusId(row.original.email_schedule)
    setIsOpenApprovalModal(true);
  };
  //get all sequences
  const fetchSequences = async () => {
    // navigate("/admin/sequences/new", { relative: "path" });
    try {
      const response = await fetch(BASE_URL + `/allsequence/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        setSequences(data.Seqeunce);
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Sequences",
        description: "Failed to fetch",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const fetchUserEmails = async () => {
    try {
      const response = await fetch(BASE_URL + "/get-user-emails/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setUserEmails(data.User_Emails);

      setIsLoading(false);
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "User Emails",
        description: "Failed to fetch",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleCheckboxChange = (value) => {
    gotoPage(0);
    const currentIndex = selectedStatus.indexOf(value);
    const newSelected = [...selectedStatus];

    currentIndex === -1
      ? newSelected.push(value)
      : newSelected.splice(currentIndex, 1);

    setSelectedStatus(newSelected);
  };

  const handleAccountClick = (account) => {
    gotoPage(0);
    // setInputAccountValue('');
    if (!selectedAccounts?.includes(account)) {
      setSelectedAccounts([...selectedAccounts, account]);
    }
  };

  const handleCompanyClick = (company) => {
    gotoPage(0);
    // setInputCompanyValue('');
    if (!selectedCompanies?.includes(company)) {
      setSelectedCompanies([...selectedCompanies, company]);
    }
  };

  const handleTalentClick = (talent) => {
    gotoPage(0);
    // setInputTalentValue('');
    if (!selectedTalents?.includes(talent)) {
      setSelectedTalents([...selectedTalents, talent]);
    }
  };

  const handleRemoveAccount = (index) => {
    const updatedAccountData = selectedAccounts.filter(
      (_, item) => item !== index
    );
    setSelectedAccounts(updatedAccountData);
  };

  const handleRemoveCompany = (index) => {
    const updatedCompanyData = selectedCompanies.filter(
      (_, item) => item !== index
    );
    setSelectedCompanies(updatedCompanyData);
  };

  const handleRemoveTalent = (index) => {
    const updatedTalentData = selectedTalents.filter(
      (_, item) => item !== index
    );
    setSelectedTalents(updatedTalentData);
  };

  const resetAllFilter = () => {
    setSelectedStatus([]);
    setSelectedAccounts([]);
    setSelectedCompanies([]);
    setSelectedTalents([]);
  };

  const resetAllField = () => {
    setInputAccountValue("");
    setInputCompanyValue("");
    setInputTalentValue("");
  };

  const handleDeleteRows = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      const campaignIds = selectedFlatRows.map(
        (row) => row?.original?.campaign_id
      );
      const response = await fetch(BASE_URL + "/delete_campaigns/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ campaign_ids: campaignIds }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      if (response.ok) {
        setIsLoading(false);
        setCampaignData((prevData) =>
          prevData.filter(
            (campaign) => !campaignIds.includes(campaign.campaign_id)
          )
        );
        fetchCampaignData();

        toast({
          title: "Campaign Deleted",
          description: "Campaigns deleted successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenCampaignDeleteDialogBox(false);
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete campaign details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenCampaignDeleteDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete campaign details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setIsOpenCampaignDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  const handleRejectRows = async (selectedRejectRows) => {
    try {
      setIsLoading(true);
      const campaignIds = [selectedRejectRows?.campaign_id];

      const payloads = { campaign_ids: campaignIds };
      const response = await fetch(BASE_URL + "/campaign-reject-status/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payloads),
      });
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      if (response.ok) {
        setIsLoading(false);
        setSelectedRejectRows("");
        setCampaignData((prevData) =>
          prevData.filter(
            (campaign) => !campaignIds.includes(campaign.campaign_id)
          )
        );
        fetchCampaignData();

        toast({
          title: "Campaign Rejected",
          description: "Campaigns rejected successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenCampaignRejectDialogBox(false);
      } else {
        toast({
          title: "Rejection Failed",
          description: "Failed to Reject campaign details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        setSelectedRejectRows("");
        setIsOpenCampaignRejectDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Rejection Failed",
        description: "Failed to Reject campaign details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      setSelectedRejectRows("");
      setIsOpenCampaignRejectDialogBox(false);

      console.error("Error:", error);
    }
  };

  const fetchAccounts = async () => {
    try {
      setIsAccountSearchLoading(true);
      const response = await fetch(
        BASE_URL + `/account-search/?account=${inputAccountValue}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }

      setFilteredAccountData(data?.accounts);
      setIsAccountSearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputAccountValue.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchAccounts();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputAccountValue]);

  const fetchCompanies = async () => {
    try {
      setIsCompanySearchLoading(true);
      const response = await fetch(
        BASE_URL + `/company-search/?company_name=${inputCompanyValue}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      setFilteredCompanyData(data?.company_names);
      setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputCompanyValue.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchCompanies();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputCompanyValue]);

  const fetchTalents = async () => {
    try {
      setIsTalentSearchLoading(true);
      const response = await fetch(
        BASE_URL + `/talent-search/?talent_name=${inputTalentValue}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      setFilteredTalentData(data?.talent_names);

      setIsTalentSearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputTalentValue.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchTalents();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputTalentValue]);

  const handleExportCSV = async () => {
    const statusParams = selectedStatus
      .map((status) => `status=${status}`)
      .join("&");
    const accountParams = selectedAccounts
      .map((account) => `account=${account}`)
      .join("&");
    const companyParams = selectedCompanies
      .map((company) => `company_name=${company}`)
      .join("&");
    const talentParams = selectedTalents
      .map((talent) => `influencer_talent_name=${talent}`)
      .join("&");

    try {
      setIsExportLoading(true);
      const response = await fetch(
        BASE_URL +
          `/campaigns_csv/?${statusParams}&${accountParams}&${companyParams}&${talentParams}&page=${
            pageIndex + 1
          }&page_size=${pageSize}&ordering=-updated_ts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const csvData = await response.text();

      try {
        const data = JSON.parse(csvData);
        if (data.code === "token_not_valid") {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }

      const headersMap = {
        created_ts: "Date Created",
        company_name: "Company Name",
        influencer_talent_name: "Talent Name",
        campaign_status: "Campaign Status",
        total_email_count: "Emails Sent",
        total_views: "Total Views",
        replied: "Replied",
        replied_date: "Replied Date",
        "updated_ts\r": "Last Update",
        "account\r": "Email Account",
      };
      const rows = csvData.split("\n").map((row) => row.split(","));

      const headers = rows.shift(); // Extract headers

      const newHeaders = headers.map((header) => headersMap[header]); // Map original headers to new headers

      rows.unshift(newHeaders);
      // Convert date format
      rows.forEach((row) => {
        row[0] = combineDateTime(row[0]);
        row[7] = combineDateTime(row[7]);
        row[8] = combineDateTime(row[8]);
      });

      // Convert back to CSV format
      const modifiedCsvData = rows.map((row) => row.join(",")).join("\n");

      function combineDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        if (isNaN(date.getTime())) {
          return dateTimeString; // Return original string if invalid date
        }
        return date
          .toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
          .replace(",", "");
      }

      setIsExportLoading(false);
      const blob = new Blob([modifiedCsvData], { type: "text/csv" });
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = String(currentDate.getFullYear()).slice(2); // Get last two digits of the year

      const dateFormatted = `${month}${day}${year}`;

      let csvName = "Campaign Analytics - ";

      if (
        selectedStatus.length > 0 ||
        selectedTalents.length > 0 ||
        selectedCompanies.length > 0
      ) {
        if (selectedStatus.length > 0) {
          csvName += selectedStatus.join("_");
        }
        if (selectedTalents.length > 0) {
          csvName += " - " + selectedTalents.join("_");
        }
        if (selectedCompanies.length > 0) {
          csvName += " - " + selectedCompanies.join("_");
        }
        csvName += ` - ${dateFormatted}.csv`;
      } else {
        csvName += ` ${dateFormatted}.csv`;
      }

      const blobUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = csvName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error fetching CSV data:", error);
    }
  };

  return (
    <Box px={5} py={3} className="camp_table_padding">
      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              {/* <Box onClick={() => setIsOpenCampaignDeleteDialogBox(true)} bg={"none"} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}><IoMdTrash size={20} color='red' /></Box> */}
              {roles !== "Influencer" && (
                <Button
                  onClick={() => setIsOpenCampaignDeleteDialogBox(true)}
                  size={"sm"}
                  colorScheme="red"
                  variant="solid"
                >
                  <IoMdTrash size={20} color="white" />
                </Button>
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>
              <Menu closeOnSelect={false}>
                <MenuButton
                  height={"2rem"}
                  minWidth={"2.3rem"}
                  as={IconButton}
                  aria-label="Options"
                  icon={<BsFilter size={18} />}
                  variant="outline"
                />
                <MenuList>
                  <Flex flexDirection={"column"}>
                    <Flex
                      className="flex-none "
                      justifyContent={"space-between"}
                    >
                      <MenuGroup>
                        <Flex flexDirection={"column"}>
                          <Flex
                            w={"320px"}
                            px={3}
                            pb={2}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text fontSize={"sm"} fontWeight={600}>
                              Status
                            </Text>
                            {selectedStatus.length > 0 && (
                              <Tag
                                borderRadius="full"
                                size={"sm"}
                                colorScheme="red"
                                variant="outline"
                                cursor={"pointer"}
                                onClick={() => setSelectedStatus([])}
                              >
                                {selectedStatus.length > 0 &&
                                  `Clear (${selectedStatus.length})`}
                              </Tag>
                            )}
                          </Flex>
                          <Flex px={3} gap={5}>
                            <Flex flexDirection={"column"} gap={1}>
                              <Checkbox
                                value="Active"
                                colorScheme="green"
                                onChange={() => handleCheckboxChange("Active")}
                                isChecked={selectedStatus.includes("Active")}
                              >
                                Active
                              </Checkbox>
                              <Checkbox
                                value="Approved"
                                colorScheme="green"
                                onChange={() =>
                                  handleCheckboxChange("Approved")
                                }
                                isChecked={selectedStatus.includes("Approved")}
                              >
                                Approved
                              </Checkbox>
                              {roles !== "Influencer" && (
                                <Checkbox
                                  value="Queue"
                                  colorScheme="green"
                                  onChange={() => handleCheckboxChange("Queue")}
                                  isChecked={selectedStatus.includes("Queue")}
                                >
                                  Queue
                                </Checkbox>
                              )}
                            </Flex>
                            <Flex flexDirection={"column"} gap={1}>
                              <Checkbox
                                value="Ended"
                                colorScheme="green"
                                onChange={() => handleCheckboxChange("Ended")}
                                isChecked={selectedStatus.includes("Ended")}
                              >
                                Ended
                              </Checkbox>
                              {roles !== "Influencer" && (
                                <Checkbox
                                  value="Pending"
                                  colorScheme="green"
                                  onChange={() =>
                                    handleCheckboxChange("Pending")
                                  }
                                  isChecked={selectedStatus.includes("Pending")}
                                >
                                  Pending
                                </Checkbox>
                              )}
                            </Flex>
                          </Flex>
                        </Flex>
                      </MenuGroup>

                      <MenuGroup>
                        <Flex flexDirection={"column"}>
                          <Flex
                            w={"320px"}
                            px={3}
                            pb={2}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Text fontSize={"sm"} fontWeight={600}>
                              Company
                            </Text>
                            {selectedCompanies.length > 0 && (
                              <Tag
                                borderRadius="full"
                                size={"sm"}
                                colorScheme="red"
                                variant="outline"
                                cursor={"pointer"}
                                onClick={() => setSelectedCompanies([])}
                              >
                                {selectedCompanies.length > 0 &&
                                  `Clear (${selectedCompanies.length})`}
                              </Tag>
                            )}
                          </Flex>
                          <Box px={3}>
                            <InputGroup>
                              <Input
                                w={"300px"}
                                value={inputCompanyValue}
                                onChange={(e) => {
                                  setInputCompanyValue(e.target.value);
                                  setIsCompanySearchLoading(true);
                                }}
                                placeholder="Search for companies..."
                              />
                              {/* {
                                                        inputCompanyValue &&
                                                        <InputRightElement>
                                                            <Button variant='outline' colorScheme='facebook' h='100%' borderTopLeftRadius={0} borderBottomLeftRadius={0} size='sm' onClick={() => handleAdd('company')}><AddIcon /></Button>
                                                        </InputRightElement>
                                                    } */}
                              {inputCompanyValue ? (
                                <InputRightElement>
                                  {isCompanySearchLoading ? (
                                    <Spinner w={4} h={4} color="gray.400" />
                                  ) : (
                                    <CloseIcon
                                      cursor={"pointer"}
                                      w={2.5}
                                      h={2.5}
                                      color="gray.400"
                                      onClick={() => setInputCompanyValue("")}
                                    />
                                  )}
                                </InputRightElement>
                              ) : (
                                <InputRightElement>
                                  <SearchIcon w={3} h={3} color="gray.400" />
                                </InputRightElement>
                              )}
                            </InputGroup>

                            {inputCompanyValue && (
                              <Card mt={1}>
                                {filteredCompanyData?.length > 0 && (
                                  <Box
                                    my={1}
                                    maxH={"145px"}
                                    overflowY={"auto"}
                                    sx={{
                                      "&::-webkit-scrollbar": { width: "4px" },
                                      "&::-webkit-scrollbar-thumb": {
                                        background: "#dddddd",
                                        borderRadius: "100vh",
                                      },
                                    }}
                                  >
                                    {filteredCompanyData?.map(
                                      (company, index) => (
                                        <Text
                                          px={3}
                                          py={1}
                                          fontSize={"sm"}
                                          _hover={{
                                            bg: "#EDF2F7",
                                            cursor: "pointer",
                                          }}
                                          key={index}
                                          onClick={() =>
                                            handleCompanyClick(company)
                                          }
                                        >
                                          {company}
                                        </Text>
                                      )
                                    )}
                                  </Box>
                                )}
                              </Card>
                            )}

                            <Box
                              mt={1}
                              maxW={"300px"}
                              maxH={"55px"}
                              overflow={"auto"}
                              sx={{
                                "&::-webkit-scrollbar": { width: "3px" },
                                "&::-webkit-scrollbar-track": {
                                  background: "#f1f1f1",
                                  borderRadius: "10px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  background: "#888",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              {selectedCompanies?.map((value, index) => (
                                <Tag
                                  mr={1}
                                  mb={1}
                                  key={index}
                                  size="md"
                                  variant="subtle"
                                  colorScheme="blackAlpha"
                                >
                                  <TagLabel>{value}</TagLabel>
                                  <TagCloseButton
                                    onClick={() => handleRemoveCompany(index)}
                                  />
                                </Tag>
                              ))}
                            </Box>
                          </Box>
                        </Flex>
                      </MenuGroup>
                    </Flex>

                    <Flex
                      className="flex-none "
                      justifyContent={"space-between"}
                      mt={2}
                    >
                      {roles !== "Influencer" && (
                        <>
                          <MenuGroup>
                            <Flex flexDirection={"column"}>
                              <Flex
                                w={"320px"}
                                px={3}
                                pb={2}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Text fontSize={"sm"} fontWeight={600}>
                                  Account
                                </Text>
                                {selectedAccounts.length > 0 && (
                                  <Tag
                                    borderRadius="full"
                                    size={"sm"}
                                    colorScheme="red"
                                    variant="outline"
                                    cursor={"pointer"}
                                    onClick={() => setSelectedAccounts([])}
                                  >
                                    {selectedAccounts.length > 0 &&
                                      `Clear (${selectedAccounts.length})`}
                                  </Tag>
                                )}
                              </Flex>
                              <Box px={3}>
                                <InputGroup>
                                  <Input
                                    w={"300px"}
                                    value={inputAccountValue}
                                    onChange={(e) => {
                                      setInputAccountValue(e.target.value);
                                      setIsAccountSearchLoading(true);
                                    }}
                                    placeholder="Search for accounts..."
                                  />
                                  {/* {
                                                        inputAccountValue &&
                                                        <InputRightElement>
                                                            <Button variant='outline' colorScheme='facebook' h='100%' borderTopLeftRadius={0} borderBottomLeftRadius={0} size='sm' onClick={() => handleAdd('account')}><AddIcon /></Button>
                                                        </InputRightElement>
                                                    } */}
                                  {inputAccountValue ? (
                                    <InputRightElement>
                                      {isAccountSearchLoading ? (
                                        <Spinner w={4} h={4} color="gray.400" />
                                      ) : (
                                        <CloseIcon
                                          cursor={"pointer"}
                                          w={2.5}
                                          h={2.5}
                                          color="gray.400"
                                          onClick={() =>
                                            setInputAccountValue("")
                                          }
                                        />
                                      )}
                                    </InputRightElement>
                                  ) : (
                                    <InputRightElement>
                                      <SearchIcon
                                        w={3}
                                        h={3}
                                        color="gray.400"
                                      />
                                    </InputRightElement>
                                  )}
                                </InputGroup>

                                {inputAccountValue && (
                                  <Card mt={1}>
                                    {filteredAccountData?.length > 0 && (
                                      <Box
                                        my={1}
                                        maxH={"145px"}
                                        overflowY={"auto"}
                                        sx={{
                                          "&::-webkit-scrollbar": {
                                            width: "4px",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            background: "#dddddd",
                                            borderRadius: "100vh",
                                          },
                                        }}
                                      >
                                        {filteredAccountData?.map(
                                          (account, index) => (
                                            <Text
                                              px={3}
                                              py={1}
                                              fontSize={"sm"}
                                              _hover={{
                                                bg: "#EDF2F7",
                                                cursor: "pointer",
                                              }}
                                              key={index}
                                              onClick={() =>
                                                handleAccountClick(account)
                                              }
                                            >
                                              {account}
                                            </Text>
                                          )
                                        )}
                                      </Box>
                                    )}
                                  </Card>
                                )}

                                <Box
                                  mt={1}
                                  maxW={"300px"}
                                  maxH={"55px"}
                                  overflow={"auto"}
                                  sx={{
                                    "&::-webkit-scrollbar": { width: "3px" },
                                    "&::-webkit-scrollbar-track": {
                                      background: "#f1f1f1",
                                      borderRadius: "10px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      background: "#888",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  {selectedAccounts?.map((value, index) => (
                                    <Tag
                                      mr={1}
                                      mb={1}
                                      key={index}
                                      size="md"
                                      variant="subtle"
                                      colorScheme="blackAlpha"
                                    >
                                      <TagLabel>{value}</TagLabel>
                                      <TagCloseButton
                                        onClick={() =>
                                          handleRemoveAccount(index)
                                        }
                                      />
                                    </Tag>
                                  ))}
                                </Box>
                              </Box>
                            </Flex>
                          </MenuGroup>

                          <MenuGroup>
                            <Flex flexDirection={"column"}>
                              <Flex
                                w={"320px"}
                                px={3}
                                pb={2}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Text fontSize={"sm"} fontWeight={600}>
                                  Talent
                                </Text>
                                {selectedTalents.length > 0 && (
                                  <Tag
                                    borderRadius="full"
                                    size={"sm"}
                                    colorScheme="red"
                                    variant="outline"
                                    cursor={"pointer"}
                                    onClick={() => setSelectedTalents([])}
                                  >
                                    {selectedTalents.length > 0 &&
                                      `Clear (${selectedTalents.length})`}
                                  </Tag>
                                )}
                              </Flex>
                              <Box px={3}>
                                <InputGroup>
                                  <Input
                                    w={"300px"}
                                    value={inputTalentValue}
                                    onChange={(e) => {
                                      setInputTalentValue(e.target.value);
                                      setIsTalentSearchLoading(true);
                                    }}
                                    placeholder="Search for talents..."
                                  />
                                  {/* {
                                                        inputTalentValue &&
                                                        <InputRightElement>
                                                            <Button variant='outline' colorScheme='facebook' h='100%' borderTopLeftRadius={0} borderBottomLeftRadius={0} size='sm' onClick={() => handleAdd('talent')}><AddIcon /></Button>
                                                        </InputRightElement>
                                                    } */}
                                  {inputTalentValue ? (
                                    <InputRightElement>
                                      {isTalentSearchLoading ? (
                                        <Spinner w={4} h={4} color="gray.400" />
                                      ) : (
                                        <CloseIcon
                                          cursor={"pointer"}
                                          w={2.5}
                                          h={2.5}
                                          color="gray.400"
                                          onClick={() =>
                                            setInputTalentValue("")
                                          }
                                        />
                                      )}
                                    </InputRightElement>
                                  ) : (
                                    <InputRightElement>
                                      <SearchIcon
                                        w={3}
                                        h={3}
                                        color="gray.400"
                                      />
                                    </InputRightElement>
                                  )}
                                </InputGroup>

                                {inputTalentValue && (
                                  <Card mt={1}>
                                    {filteredTalentData?.length > 0 && (
                                      <Box
                                        my={1}
                                        maxH={"145px"}
                                        overflowY={"auto"}
                                        sx={{
                                          "&::-webkit-scrollbar": {
                                            width: "4px",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            background: "#dddddd",
                                            borderRadius: "100vh",
                                          },
                                        }}
                                      >
                                        {filteredTalentData?.map(
                                          (talent, index) => (
                                            <Text
                                              px={3}
                                              py={1}
                                              fontSize={"sm"}
                                              _hover={{
                                                bg: "#EDF2F7",
                                                cursor: "pointer",
                                              }}
                                              key={index}
                                              onClick={() =>
                                                handleTalentClick(talent)
                                              }
                                            >
                                              {talent}
                                            </Text>
                                          )
                                        )}
                                      </Box>
                                    )}
                                  </Card>
                                )}

                                <Box
                                  mt={1}
                                  maxW={"300px"}
                                  maxH={"55px"}
                                  overflow={"auto"}
                                  sx={{
                                    "&::-webkit-scrollbar": { width: "3px" },
                                    "&::-webkit-scrollbar-track": {
                                      background: "#f1f1f1",
                                      borderRadius: "10px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      background: "#888",
                                      borderRadius: "10px",
                                    },
                                  }}
                                >
                                  {selectedTalents?.map((value, index) => (
                                    <Tag
                                      mr={1}
                                      mb={1}
                                      key={index}
                                      size="md"
                                      variant="subtle"
                                      colorScheme="blackAlpha"
                                    >
                                      <TagLabel>{value}</TagLabel>
                                      <TagCloseButton
                                        onClick={() =>
                                          handleRemoveTalent(index)
                                        }
                                      />
                                    </Tag>
                                  ))}
                                </Box>
                              </Box>
                            </Flex>
                          </MenuGroup>
                        </>
                      )}
                    </Flex>

                    {(selectedStatus.length > 0 ||
                      selectedAccounts.length > 0 ||
                      selectedCompanies.length > 0 ||
                      selectedTalents.length > 0) && (
                      <Flex
                        px={3}
                        pt={2}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Flex gap={5}>
                          <Button
                            size="sm"
                            colorScheme="red"
                            variant="link"
                            onClick={resetAllFilter}
                          >{`Reset All (${
                            selectedStatus.length +
                            selectedAccounts.length +
                            selectedCompanies.length +
                            selectedTalents.length
                          })`}</Button>
                          {(inputAccountValue ||
                            inputCompanyValue ||
                            inputTalentValue) && (
                            <Button
                              size="xs"
                              colorScheme="red"
                              variant="outline"
                              onClick={resetAllField}
                            >
                              Clear All
                            </Button>
                          )}
                        </Flex>
                        <Text fontSize="sm" as={"b"} color="green">
                          {totalItems} results found
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </MenuList>
              </Menu>
              {/* <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <SearchIcon color='gray.400' />
                                </InputLeftElement>
                                <Input type='text' placeholder='Search campaign...' width={"350px"} borderRadius={"100vh"} value={filterValue} onChange={(e) => { setFilterValue(e.target.value); gotoPage(0); }} />
                                {
                                    filterValue && (
                                        <InputRightElement cursor={"pointer"}>
                                            <CloseIcon w={3} h={3} color='gray.400' onClick={() => setFilterValue("")} />
                                        </InputRightElement>
                                    )
                                }
                            </InputGroup> */}
            </Box>
            <Box display="flex" gap="3" alignItems="center">
              {role == "Influencer" && (
                // <Link
                //   to="https://forms.gle/fRd9cDVG6VWSWNMB7"
                //   target="_blank"
                // >
                //  <BsBuildingFillAdd size={20}/>
                // </Link>
                <Button
                  onClick={() =>
                    window.open("https://forms.gle/fRd9cDVG6VWSWNMB7", "_blank")
                  }
                  size={"sm"}
                  colorScheme="blue"
                  variant="solid"
                >
                  <BsBuildingFillAdd size={20} color="white" />
                </Button>
              )}
              <Button
                isLoading={isExportLoading}
                spinner={<PulseLoader size={8} color="purple" />}
                spinnerPlacement="end"
                loadingText="Exporting"
                onClick={handleExportCSV}
                size={"sm"}
                leftIcon={<BsFiletypeCsv />}
                colorScheme="purple"
                variant="outline"
              >
                Export as CSV
              </Button>
            </Box>
          </Flex>
        )}
        <Box
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(100vh - 180px)"}
          w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{
            position: "relative",
          }}
        >
          <TableContainer
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"} className="fix-header">
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                    {roles !== "Influencer" && (
                      <Th color={"#FFFFFF"}>Actions</Th>
                    )}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  const isRowEditing = isEditing === row?.original?.campaign_id;
                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;

                        return (
                          <Td
                            {...cell.getCellProps()}
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                          >
                            {isCellEditing ? (
                              <Textarea
                                style={{ resize: "both", minWidth: "250px" }}
                                // w="250px"
                                type="text"
                                value={row.values[cell.column.id]}
                                onChange={(e) => {
                                  const fieldName = cell.column.id;
                                  const updatedValue = e.target.value;
                                  const updatedcampaignid =
                                    row.original.campaign_id;
                                  row.values[fieldName] = updatedValue;
                                  const updatedEditedRowData = (
                                    editedRowData || []
                                  ).map((rowData) => {
                                    if (
                                      rowData.campaign_id === updatedcampaignid
                                    ) {
                                      return {
                                        ...rowData,
                                        [fieldName]: updatedValue,
                                      };
                                    }
                                    return rowData;
                                  });
                                  setEditedRowData(updatedEditedRowData);
                                }}
                                onBlur={() => handleCellBlur()}
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                      {roles !== "Influencer" && (
                        <Td>
                          <Flex gap={2}>
                            {row.original.campaign_status === "Pending" && (
                              <Button
                                colorScheme="yellow"
                                size="sm"
                                onClick={() => {
                                  handleApproveClick(row);
                                }}
                              >
                                {" "}
                                Approve
                              </Button>
                            )}

                            {row.original.campaign_status === "Pending" && (
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => {
                                  handleCampaignRejectModal(row);
                                }}
                              >
                                {" "}
                                Reject
                              </Button>
                            )}

                            <Button
                              isDisabled
                              // isDisabled={row.original.campaign_status === 'Draft'?true:false}
                              colorScheme="teal"
                              size="sm"
                              onClick={() => {
                                handleEmailStatusClick(row);
                              }}
                            >
                              {" "}
                              Email Status
                            </Button>
                          </Flex>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex alignItems={"center"} justifyContent={"space-between"} className="camp_page_cont">
          <Flex>
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Flex>

          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft
                size={24}
                onClick={() => previousPage()}
                disabled={pageIndex === 0}
              />
            </Box>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronRight
                size={24}
                onClick={() => nextPage()}
                disabled={pageIndex === pageCount - 1}
              />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>
      {/* Modal */}
      {selectedCampaign && (
        <EmailStatusModal
          isOpen={isOpenStatusModal}
          onClose={() => setIsOpenStatusModal(false)}
          campaign={selectedCampaign}
        />
      )}
      {selectedCampaign && (
        <CampaignApprovalDialogBox
          sequences={sequences}
          emailList={userEmails}
          isOpen={isOpenApprovalModal}
          // onClose={() => setIsOpenApprovalModal(false)}
          setIsOpenApprovalModal={setIsOpenApprovalModal}
          fetchCampaignData={fetchCampaignData}
          selectedCampaign={selectedCampaign}
        />
      )}

      <CampaignDeleteDialogBox
        isOpen={isOpenCampaignDeleteDialogBox}
        onClose={() => {
          setIsOpenCampaignDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />

      <CampaignsRejectDialogBox
        isOpen={isOpenCampaignRejectDialogBox}
        onClose={() => {
          setIsOpenCampaignRejectDialogBox(false);
        }}
        isLoading={isLoading}
        selectedRejectRows={selectedRejectRows}
        handleRejectRows={handleRejectRows}
      />
    </Box>
  );
};

export default CampaignTable;
