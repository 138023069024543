import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
} from "@chakra-ui/react";
import { Box, Flex, useColorMode, useToast } from "@chakra-ui/react";
import axios from "axios";

const InfluencerMailModel = ({
  isOpen,
  onClose,
  data,
  fetchinfluencerDealsData,
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const toast = useToast();

  const sendMailOffer = async () => {
    const object = {
      influencer_id: data[0]?.original?.influencer,
      deal_id: data[0]?.original?.deal_id,
    };
    try {
      const result = await axios.post(
        `${BASE_URL}/send-mail-to-inf/ `,
        object,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        toast({
          title: "Success",
          description: "Mail send successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        fetchinfluencerDealsData();
      }
    } catch (error) {
      fetchinfluencerDealsData();

      toast({
        title: "Failed",
        description: "Error in sending mail.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Deals Offer
          </AlertDialogHeader>
          <AlertDialogBody>
            Would you like to send an offer to {data[0]?.original?.creator}?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              size={"sm"}
              onClick={() => {
                onClose();
                fetchinfluencerDealsData();
              }}
            >
              No
            </Button>
            <Button
              size={"sm"}
              colorScheme="red"
              loadingText="Deleting"
              spinnerPlacement="end"
              onClick={sendMailOffer}
              ml={3}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default InfluencerMailModel;
