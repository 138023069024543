import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Select,
  SkeletonText,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  Textarea,
  Input,
  Menu,
  MenuGroup,
  InputGroup,
  Card,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputRightElement,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";
import { IoMdTrash, IoIosArrowBack } from "react-icons/io";
import { format, parseISO, set } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "../Table/links.css";
import axios from "axios";
import {
  FaFileContract,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaFacebook,
  FaSnapchat,
  FaGlobe,
} from "react-icons/fa";
import { Tag, Wrap, TagLabel } from "@chakra-ui/react";
import { FaFileCircleXmark } from "react-icons/fa6";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";

const ViewDeals = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();

  const [ID, setID] = useState("");
  const [TalName, setTalName] = useState("");
  const [InfluencersId, setInfluencersId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [viewData, setViewData] = useState("");
  const [viewData1, setViewData1] = useState("");
  const [deleteInvoiceId, setDeleteInvoiceId] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [roles, setRoles] = useState(role);
  const [mainPath, setMainPath] = useState();

  const [editedInvoice, setEditedInvoice] = useState("");

  const [inputCompanyValue, setInputCompanyValue] = useState("");
  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companySearch, setCompnaySearch] = useState(false);

  const [inputValue, setInputValue] = useState({
    products: "",
    tracking_info: "",
    deliverables: "",
    usage: "",
    exclusivity: "",
    contract: "",
    live_links: "",
    cm_split: "",
    manager_split: "",
    manager_payout: "",
    payment_term: "",
    cm_payout: "",
    creator_payout: "",
    invoice_contact: "",
    notes: "",
    created_ts: viewData.created_ts,
    stage: viewData.stage,
    creator: viewData.creator,
    deal_id: viewData.deal_id,
    deal_name: viewData.deal_name,
    deal_type: viewData.deal_type,
    deal_amount: viewData.deal_amount,
    date_received: viewData.date_received,
    influencer: viewData.influencer,
  });

  const [brandRep, setBrandRep] = useState([]);
  const [brandRepValue, setBrandRepValue] = useState("");
  const [date, setDate] = useState({
    rough_draft_date: null,
    live_date: null,
    invoice_date: null,
    payout_date: null,
    date_received: null,
  });

  useEffect(() => {
    if (viewData) {
      // Extract invoice numbers from viewData1
      const invoiceNumbers = Array.isArray(viewData1)
        ? viewData1.map((invoice) => invoice.invoice_number).filter(Boolean) // Ensure valid invoice numbers
        : [];

      setInputCompanyValue(viewData?.brand_agency);
      setCompany(viewData?.brand_agency);
      setBrandRepValue(viewData?.brand_rep);
      setInputValue({
        products: viewData.products,
        tracking_info: viewData.tracking_info,
        deliverables: viewData.deliverables,
        usage: viewData.usage,
        exclusivity: viewData.exclusivity,
        contract: viewData.contract,
        live_links: viewData.live_links,
        cm_split: viewData.cm_split,
        manager_split: viewData.manager_split,
        manager_payout: viewData.manager_payout,
        payment_term: viewData.payment_term,
        cm_payout: viewData.cm_payout,
        creator_payout: viewData.creator_payout,
        invoice_contact: viewData.invoice_contact,
        invoice: invoiceNumbers.join(","),
        created_ts: viewData.created_ts,
        notes: viewData.notes,
        stage: viewData.stage,
        creator: viewData.creator,
        deal_id: viewData.deal_id,
        deal_name: viewData.deal_name,
        deal_type: viewData.deal_type,
        deal_amount: viewData.deal_amount,
        influencer: viewData.influencer,
      });
      setDate({
        rough_draft_date: viewData.rough_draft_date,
        live_date: viewData.live_date,
        invoice_date: viewData.invoice_date,
        payout_date: viewData.payout_date,
        date_received: viewData.date_received,
      });
    }
  }, [viewData]);

  useEffect(() => {
    if (viewData1 && editedInvoice) {
      // Extract current invoice numbers from inputValue

      const originalInvoiceNumbers = viewData1.map(
        (item) => item.invoice_number
      );

      // Find removed invoice numbers
      const removedInvoiceNumbers = originalInvoiceNumbers.filter(
        (number) => !editedInvoice.includes(number)
      );

      // Find removed invoice details
      const removedInvoices = viewData1.filter((item) =>
        removedInvoiceNumbers.includes(item.invoice_number)
      );

      const RemoveInvoicesId = removedInvoices.map((item) => item.id);

      setDeleteInvoiceId(RemoveInvoicesId);
    }
  }, [viewData1, editedInvoice]);

  const handleDeleteInvoice = async () => {
    try {
      const response = await fetch(`${BASE_URL}/delete-invoices/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ invoice_ids: deleteInvoiceId }),
      });

      switch (response.status) {
        case 200:
          // Update view data by removing the deleted invoices
          setViewData((prevData) => ({
            ...prevData,
            invoices: prevData.invoices.filter(
              (invoice) => !deleteInvoiceId.includes(invoice.id)
            ),
          }));

          await getDeals(); // Make sure to await if getDeals is asynchronous

          toast({
            title: "Deletion Success",
            description: "Invoice Deleted",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });

          // Uncomment if needed
          // setIsOpenDealsDeleteDialogBox(false);
          break;
        case 401:
          toast({
            title: "Deletion Failed",
            description: "Unauthorized. Please log in again.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "Deletion Failed",
            description: "Failed to delete Invoice.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });

          // Uncomment if needed
          // setIsOpenDealsDeleteDialogBox(false);
          break;
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete Invoice.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      // Uncomment if needed
      // setIsOpenDealsDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (viewData1) {
      const rowinvoices = viewData.invoices.map((row) => {
        return row;
      });
      const invoiceNumbers = Array.isArray(rowinvoices)
        ? viewData1.map((invoice) => invoice.invoice_number).filter(Boolean) // Ensure valid invoice numbers
        : [];

      setInputValue({
        products: viewData.products,
        tracking_info: viewData.tracking_info,
        deliverables: viewData.deliverables,
        usage: viewData.usage,
        exclusivity: viewData.exclusivity,
        contract: viewData.contract,
        live_links: viewData.live_links,
        cm_split: viewData.cm_split,
        manager_split: viewData.manager_split,
        manager_payout: viewData.manager_payout,
        payment_term: viewData.payment_term,
        cm_payout: viewData.cm_payout,
        creator_payout: viewData.creator_payout,
        invoice_contact: viewData.invoice_contact,
        invoice: invoiceNumbers.join(","),
        created_ts: viewData.created_ts,
        notes: viewData.notes,
        stage: viewData.stage,
        creator: viewData.creator,
        deal_id: viewData.deal_id,
        deal_name: viewData.deal_name,
        deal_type: viewData.deal_type,
        deal_amount: viewData.deal_amount,
        influencer: viewData.influencer,
      });
    }
  }, [viewData1]);

  useEffect(() => {
    if (deleteInvoiceId.length > 0) {
      handleDeleteInvoice();
    }
  }, [deleteInvoiceId.length]);

  useEffect(() => {
    calculatePayouts();
  }, [inputValue.deal_amount, inputValue.manager_split, inputValue.cm_split]);

  useEffect(() => {
    const Pathname = window?.location?.pathname;
    const Parts = Pathname.split("/");
    const Extractpath = Parts[Parts.length - 1];
    const Tal_name = Parts[Parts.length - 2];
    setTalName(Tal_name);
    setID(Extractpath);
  });

  const getDeals = async (ID) => {
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${BASE_URL}/get-single-deal/`,
        { deal_id: location?.state?.data?.deal_id || ID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setViewData(result.data.deal_data);
      setInfluencersId(result.data.deal_data.influencer);

      if (result) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ID) {
      getDeals(ID);
    }
  }, [ID]);

  // Call getDeals when updateApi changes
  useEffect(() => {
    if (updateApi && ID) {
      getDeals(ID);
    }
  }, [updateApi, ID]);

  useEffect(() => {
    getDeals();
  }, []);

  useEffect(() => {
    if (updateApi) {
      getDeals();
    }
  }, [updateApi]);

  useEffect(() => {
    const routesConfig = [
      {
        path: "/admin",
        role: "Admin",
      },
      {
        path: "/creator",
        role: "Creator Manager",
      },
      {
        path: "/influencer",
        role: "Influencer",
      },
    ];

    const userRoute = routesConfig.find((route) => route.role === roles)?.path;

    setMainPath(userRoute);
  }, [roles]);

  const handleExit = () => {
    const allDealsPage = localStorage.getItem("allDealsPage");
    if (allDealsPage) {
      const TabIndex = localStorage.getItem("TabIndex");
      const allDealsPage = localStorage.getItem("allDealsPage");
      const DealsPage = localStorage.getItem("page");
      navigate(
        `${mainPath}/deals?page=${DealsPage}&AllPages=${allDealsPage}&Tabs=${TabIndex}`
      );
    } else if (roles === "Influencer") {
      navigate(`${mainPath}/All_Deals?page=1`);
    } else {
      let talentName = location.state?.talent_name; // Try to get talent_name from location.state
      if (!talentName) {
        const pathnameParts = window.location.pathname.split("/");
        talentName = pathnameParts[pathnameParts.length - 2]; // Assuming talent_name is the second last part of the pathname
      }

      let id = location.state?.influencer_id;
      if (!id) {
        id = InfluencersId;
      }

      navigate(`${mainPath}/${talentName}/cm/${id}`, {
        state: {
          id: id,
          // id: location.state?.influencer_id,
          tal_name: talentName, // Use the extracted or fallback talentName
        },
      });

      // navigate(`/admin/${location.state.talent_name} `, { state: { id: location.state.influencer_id, tal_name: location.state.talent_name } });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null
    const parsedDate = parseISO(dateString);
    return format(parsedDate, "MM/dd/yyyy");
  };

  const handleSave = async () => {
    const updatedData = {
      brand_agency: inputCompanyValue,
      brand_rep: brandRepValue,
      ...inputValue,
      ...date,
    };

    try {
      const response = await fetch(BASE_URL + "/update-deals/", {
        method: "PUT",
        body: JSON.stringify({ deal_data: updatedData }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      toast({
        title: "Deal Updated",
        description: "Deal updated successfully.",
        status: "success",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      setIsEditing(false);
      setUpdateApi(true);
    } catch (error) {
      console.error("Save failed:", error);
      toast({
        title: "Error",
        description: "Error updating deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      setUpdateApi(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setUpdateApi(false);
    setCompnaySearch(false);
  };

  const renderLiveLinks = (links) => {
    if (!links) return "No links available";

    return links?.split(",")?.map((link, index) => {
      const trimmedLink = link.trim();
      const formattedLink =
        trimmedLink.startsWith("http://") || trimmedLink.startsWith("https://")
          ? trimmedLink
          : `https://${trimmedLink}`;
      const domain = new URL(`https://${trimmedLink}`).hostname;

      return (
        <span
          key={index}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <>
            {getIconForDomain(domain)}{" "}
            <a
              href={`${formattedLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Link
            </a>
          </>
        </span>
      );
    });
  };

  const renderProducts = (links) => {
    if (!links) return "No Products";

    return links?.split(",")?.map((link, index) => {
      const trimmedLink = link.trim();
      const formattedLink =
        trimmedLink.startsWith("http://") || trimmedLink.startsWith("https://")
          ? trimmedLink
          : `https://${trimmedLink}`;
      const domain = new URL(`https://${trimmedLink}`).hostname;

      return (
        <span
          key={index}
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <>
            {getIconForDomain(domain)}{" "}
            <a
              href={`${formattedLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Product
            </a>
          </>
        </span>
      );
    });
  };

  const getIconForDomain = (domain) => {
    if (domain.includes("youtube.com") || domain.includes("youtu.be")) {
      return <FaYoutube />;
    } else if (domain.includes("instagram.com")) {
      return <FaInstagram />;
    } else if (domain.includes("tiktok.com")) {
      return <FaTiktok />;
    } else if (domain.includes("facebook.com")) {
      return <FaFacebook />;
    } else if (domain.includes("snapchat.com")) {
      return <FaSnapchat />;
    } else {
      return <FaGlobe />;
    }
  };

  const formatAmount = (value) => {
    const Formatvalue = parseFloat(value);
    const newValue = Formatvalue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return newValue;
  };

  const handleBrandRepo = async () => {
    try {
      const response = await fetch(BASE_URL + "/prospects-search/", {
        method: "POST",
        body: JSON.stringify({ company_name: inputCompanyValue }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setBrandRep(data.prospects);
      if (data.message === "No prospects found for the given company name") {
        setBrandRepValue("");
      }
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: "Failed to fetch prospect Deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (company) {
      handleBrandRepo();
    }
  }, [company]);

  const fetchCompanies = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/companies-search/?company_search=${inputCompanyValue}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setCompanyList(data.companies);
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (inputCompanyValue) {
      const timeout = setTimeout(() => {
        fetchCompanies();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [inputCompanyValue]);

  const handleChange = (event) => {
    const name = event.target.name;

    const value = event.target.value;

    setInputValue((prev) => ({ ...prev, [name]: value }));
    if (name === "invoice") {
      // Process the value for invoice field
      const newItems = value
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean); // Split by comma and clean up

      // Set the edited invoice data
      setEditedInvoice(newItems);
    }
  };

  const handleDateChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate((prev) => ({ ...prev, [name]: value || null }));
  };

  const handleCompanyValue = (e) => {
    setCompnaySearch(true);
    setInputCompanyValue(e.target.value);
  };

  const handleClick = (company) => {
    setCompnaySearch(false);
    setCompany(company);
    setInputCompanyValue(company);
  };

  const formatNotes = (notes) => {
    return notes?.split("\n")?.map((line, index) => <p key={index}>{line}</p>);
  };

  const formatDeliverables = (deliverables) => {
    return deliverables
      ?.split("\n")
      ?.map((line, index) => <p key={index}>{line}</p>);
  };

  const formatUsage = (usage) => {
    return usage?.split("\n")?.map((line, index) => <p key={index}>{line}</p>);
  };

  const formatProducts = (products) => {
    return products
      ?.split(",")
      ?.map((line, index) => <p key={index}>{line}</p>);
  };

  const formatSplits = (value) => {
    if (value == null || value === "") {
      return "";
    }
    const Values = `${value}%`;
    return Values;
  };

  const calculatePayouts = () => {
    const dealAmount = parseFloat(inputValue.deal_amount) || 0;
    const managerSplit = parseFloat(inputValue.manager_split) || 0;
    const cmSplit = parseFloat(inputValue.cm_split) || 0;

    const creatorPayout = dealAmount * (1 - (managerSplit + cmSplit) / 100);
    const managerPayout = dealAmount * (managerSplit / 100);
    const cmPayout = dealAmount * (cmSplit / 100);

    setInputValue((prevState) => ({
      ...prevState,
      creator_payout: `${creatorPayout.toFixed(2)}`,
      manager_payout: `${managerPayout.toFixed(2)}`,
      cm_payout: `${cmPayout.toFixed(2)}`,
    }));
  };

  useEffect(() => {
    if (viewData) {
      const invoiceNumber = viewData?.invoices.map((row) => {
        return {
          id: row.invoice_id,
          invoice_number: row.invoice_number,
        };
      });

      setViewData1(invoiceNumber);
    }
  }, [viewData]);

  const handleContractRemove = async () => {
    setIsLoading(true); // Set loading state to true at the start

    const dealId = viewData?.deal_id;

    try {
      const response = await fetch(`${BASE_URL}/deal-contract/${dealId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();

        toast({
          title: "Deletion Success",
          description: "Contract file has been deleted.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Deletion Failed",
          description: "There was an error deleting your contract.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "There was an error deleting the contract.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error deleting contract:", error);
    } finally {
      setIsLoading(false); // Set loading state to false at the end
      getDeals(); // Fetch updated deals data
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file) => {
    const Deal_ids = viewData?.deal_id;

    if (!file) return;
    const formData = new FormData();
    formData.append("contract_file", file);

    try {
      const response = await fetch(`${BASE_URL}/deal-contract/${Deal_ids}/`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // If you need to pass a token
        },
      });
      const data = await response.json();

      if (data) {
        //    setReview(data.review.response)
        toast({
          title: "File uploaded",
          description: "Your file has been uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Upload failed",
          description: "There was an error uploading your file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Upload faileds",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
      getDeals();
      //   window.location.reload()
    }
  };

  return (
    <>
      <ChakraProvider />

      <Box px={5} py={3} className="deal_page_container">
        <SkeletonText
          noOfLines={20}
          spacing="2"
          skeletonHeight="6"
          isLoaded={!isLoading}
        >
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>
              <Flex gap={4}>
                <Button
                  mb={3}
                  onClick={() => handleExit()}
                  size={"sm"}
                  // colorScheme="custom.black"
                  // variant="solid"
                  bg="custom.black"
                  color="custom.white"
                  _hover={{ bg: "custom.black", color: "custom.white" }}
                >
                  <IoIosArrowBack size={20} />
                </Button>
              </Flex>
            </Box>

            {roles !== "Influencer" && (
              <>
                <Box>
                  <Flex gap={4}>
                    {viewData?.contract && (
                      <Button
                        size={"sm"}
                        colorScheme="red"
                        variant="solid"
                        onClick={() => {
                          handleContractRemove();
                        }}
                      >
                        <FaFileCircleXmark />
                      </Button>
                    )}
                    <Button size={"sm"} colorScheme="blue" onClick={handleEdit}>
                      {isEditing ? "Cancel" : "Edit"}
                    </Button>
                    {isEditing && (
                      <Button
                        size={"sm"}
                        colorScheme="blue"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    )}
                  </Flex>
                </Box>
              </>
            )}
          </Flex>
          <Box>
            <div className="head_deal">
              <h1 className="deal_Brand_agency">{viewData.deal_name}</h1>
              <ul className="deal_list tab_deatil">
                <li>
                  <span>Date Recieved:&nbsp;</span>
                  {isEditing ? (
                    <Menu>
                      <Box>
                        <MenuGroup>
                          <Input
                            type="date"
                            name="date_received"
                            value={date.date_received}
                            onChange={handleDateChange}
                            // onBlur={handleBlur}
                            autoFocus
                          />
                        </MenuGroup>
                      </Box>
                    </Menu>
                  ) : (
                    <p>{formatDate(viewData?.date_received)}</p>
                  )}
                </li>

                <li>
                  <span>Deal Amount:&nbsp;</span>
                  {isEditing ? (
                    <Menu>
                      <Box>
                        <MenuGroup>
                          <Input
                            type="text"
                            name="deal_amount"
                            value={inputValue.deal_amount}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            autoFocus
                          />
                        </MenuGroup>
                      </Box>
                    </Menu>
                  ) : (
                    <p>{formatAmount(viewData?.deal_amount)}</p>
                  )}
                </li>

                <li>
                  <span>Stage:&nbsp;</span>
                  {isEditing ? (
                    <Menu>
                      <Box>
                        <MenuGroup>
                          <Select
                            name="stage"
                            value={inputValue.stage}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            autoFocus
                          >
                            <option value="New Lead">New Lead</option>
                            <option value="Rates Sent">Rates Sent</option>
                            <option value="Offer with Talent">
                              {" "}
                              Offer with Talent
                            </option>
                            <option value="Verbal Approval">
                              Verbal Approval
                            </option>
                            <option value="Contract Pending">
                              Contract Pending
                            </option>
                            <option value="Production">Production</option>
                            <option value="Live">Live</option>
                            <option value="Invoiced">Invoiced</option>
                            <option value="Collected">Collected</option>
                            <option value="Closed Won">Closed Won</option>
                            <option value="Closed Lost">Closed Lost</option>
                            <option value="Gifting Only">Gifting Only</option>
                          </Select>
                        </MenuGroup>
                      </Box>
                    </Menu>
                  ) : (
                    <p> {viewData?.stage}</p>
                  )}
                </li>

                <li>
                  <span>Deal Type:&nbsp;</span>
                  {isEditing ? (
                    <Menu>
                      <Box>
                        <MenuGroup>
                          <Select
                            name="deal_type"
                            value={inputValue.deal_type}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            autoFocus
                          >
                            <option value="Inbox lead">Inbox lead</option>
                            <option value="Outreach">Outreach</option>
                            <option value="Upsell">Upsell</option>
                            <option value="Usage only">Usage only</option>
                          </Select>
                        </MenuGroup>
                      </Box>
                    </Menu>
                  ) : (
                    <p>{viewData?.deal_type}</p>
                  )}
                </li>
              </ul>
            </div>
          </Box>
          <Box>
            <Tabs className="tabs">
              <TabList>
                <Tab className="tab_head">Details</Tab>
                <Tab className="tab_head">Dates/Links</Tab>
                <Tab className="tab_head">Payout</Tab>
                {roles !== "Influencer" && (
                  <Tab className="tab_head">Notes</Tab>
                )}
              </TabList>

              <TabPanels
                className="deals_details"
                //maxH="365px"
                overflowY="auto"
                pr="2"
                w="250px"
                sx={{
                  "&::-webkit-scrollbar": { width: "2px" },
                  "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                  "&::-webkit-scrollbar-thumb": { background: "#888" },
                  "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}
              >
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">Brand/Agency</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <InputGroup>
                              <Input
                                type="text"
                                name="brand_agency"
                                className="custom-dropdown"
                                value={inputCompanyValue}
                                onChange={handleCompanyValue}
                                // onBlur={handleBlur}
                                autoFocus
                              />
                              <InputRightElement>
                                {companySearch ? (
                                  <Spinner w={4} h={4} color="gray.400" />
                                ) : (
                                  <CloseIcon
                                    cursor={"pointer"}
                                    w={2.5}
                                    h={2.5}
                                    color="gray.400"
                                    onClick={() => setInputCompanyValue("")}
                                  />
                                )}
                              </InputRightElement>
                            </InputGroup>
                            {companySearch && (
                              <Card mt={1}>
                                {companyList?.length > 0 && (
                                  <Box
                                    my={1}
                                    maxH={"145px"}
                                    overflowY={"auto"}
                                    sx={{
                                      "&::-webkit-scrollbar": {
                                        width: "4px",
                                      },
                                      "&::-webkit-scrollbar-thumb": {
                                        background: "#dddddd",
                                        borderRadius: "100vh",
                                      },
                                    }}
                                  >
                                    {companyList?.map((company, i) => (
                                      <Text
                                        px={3}
                                        py={1}
                                        fontSize={"sm"}
                                        _hover={{
                                          bg: "#EDF2F7",
                                          cursor: "pointer",
                                        }}
                                        key={i}
                                        onClick={() => handleClick(company)}
                                      >
                                        {company}
                                      </Text>
                                    ))}
                                  </Box>
                                )}
                              </Card>
                            )}
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{viewData?.brand_agency}</span>
                    )}
                  </div>

                  {roles !== "Influencer" && (
                    <div className="form_list">
                      <span className="detail">Brand Rep</span>
                      {isEditing ? (
                        <Menu>
                          <Box>
                            <MenuGroup>
                              <Select
                                name="brand_rep"
                                className="custom-dropdown"
                                style={{ resize: "both", minWidth: "200px" }}
                                fontSize="sm"
                                value={brandRepValue}
                                onChange={(e) =>
                                  setBrandRepValue(e.target.value)
                                }
                                // onBlur={handleBlur}
                                autoFocus
                              >
                                <option>Select a Rep</option>
                                {brandRep?.map((opt) => {
                                  return (
                                    <option value={opt.full_name}>
                                      {opt.full_name}
                                    </option>
                                  );
                                })}
                              </Select>
                            </MenuGroup>
                          </Box>
                        </Menu>
                      ) : (
                        <span>{viewData?.brand_rep}</span>
                      )}
                    </div>
                  )}

                  <div className="form_list">
                    <span className="detail">Product Links</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="products"
                              value={inputValue.products}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {renderProducts(viewData?.products)}</span>
                    )}
                  </div>
                  <div className="form_list">
                    <span className="detail">Tracking Info</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="tracking_info"
                              value={inputValue.tracking_info}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{viewData.tracking_info}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Delieverables</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Textarea
                              type="text"
                              name="deliverables"
                              value={inputValue.deliverables}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{formatDeliverables(viewData.deliverables)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Usage</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Textarea
                              type="text"
                              name="usage"
                              value={inputValue.usage}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{formatUsage(viewData.usage)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Exclusivity</span>{" "}
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="exclusivity"
                              value={inputValue.exclusivity}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{viewData?.exclusivity}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Contract</span>

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        width: "10%",
                      }}
                    >
                      {viewData?.contract !== null ? (
                        <Button colorScheme="blue">
                          <a
                            href={
                              "https://api.creativesmatter.com/" +
                              viewData.contract
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>FEA</p>
                            <FaFileContract />
                          </a>
                        </Button>
                      ) : roles !== "Influencer" ? (
                        <div class="upload_btn">
                          <Button Size={"sm"} colorScheme="blue">
                            <FaUpload size={10} />
                          </Button>

                          <Input
                            type="file"
                            onChange={(e) => handleFileChange(e)}
                            style={{ cursor: "pointer" }}
                            accept=".pdf,.doc,.docx"
                          />
                        </div>
                      ) : (
                        "None"
                      )}
                    </span>
                  </div>
                </TabPanel>

                {/* <===============================================================================================> */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">Rough Date</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="date"
                              name="rough_draft_date"
                              value={date.rough_draft_date}
                              onChange={handleDateChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{formatDate(viewData?.rough_draft_date)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Live Date</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="date"
                              name="live_date"
                              value={date.live_date}
                              onChange={handleDateChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatDate(viewData?.live_date)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Live Links</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="live_links"
                              value={inputValue.live_links}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {renderLiveLinks(viewData?.live_links)}</span>
                    )}
                  </div>
                </TabPanel>

                {/* <===============================================================================================> */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  {roles !== "Influencer" && (
                    <>
                      <div className="form_list">
                        <span className="detail">CM's Split</span>
                        {isEditing ? (
                          <Menu>
                            <Box>
                              <MenuGroup>
                                <Input
                                  type="number"
                                  name="cm_split"
                                  value={inputValue.cm_split}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  autoFocus
                                />
                              </MenuGroup>
                            </Box>
                          </Menu>
                        ) : (
                          <span> {formatSplits(viewData?.cm_split)}</span>
                        )}
                      </div>

                      <div className="form_list">
                        <span className="detail">Manager's Split</span>
                        {isEditing ? (
                          <Menu>
                            <Box>
                              <MenuGroup>
                                <Input
                                  type="number"
                                  name="manager_split"
                                  value={inputValue.manager_split}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  autoFocus
                                />
                              </MenuGroup>
                            </Box>
                          </Menu>
                        ) : (
                          <span>{formatSplits(viewData?.manager_split)}</span>
                        )}
                      </div>
                    </>
                  )}
                  <div className="form_list">
                    <span className="detail">Payment Terms</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="payment_term"
                              value={inputValue.payment_term}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {viewData?.payment_term}</span>
                    )}
                  </div>
                  {roles !== "Influencer" && (
                    <div className="form_list">
                      <span className="detail">Invoice Contact</span>
                      {isEditing ? (
                        <Menu>
                          <Box>
                            <MenuGroup>
                              <Input
                                type="text"
                                name="invoice_contact"
                                value={inputValue.invoice_contact}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                autoFocus
                              />
                            </MenuGroup>
                          </Box>
                        </Menu>
                      ) : (
                        <span>{viewData?.invoice_contact}</span>
                      )}
                    </div>
                  )}
                  <div className="form_list">
                    <span className="detail">Invoice Date</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="date"
                              name="invoice_date"
                              value={date.invoice_date}
                              onChange={handleDateChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatDate(viewData?.invoice_date)}</span>
                    )}
                  </div>

                  {roles !== "Influencer" && (
                    <div className="form_list">
                      <span className="detail">Invoice</span>
                      {isEditing ? (
                        <Menu>
                          <Box>
                            <MenuGroup>
                              <Input
                                type="text"
                                name="invoice"
                                value={inputValue.invoice}
                                onChange={(event) => handleChange(event)}
                                // onBlur={handleBlur}
                                autoFocus
                              />
                            </MenuGroup>
                          </Box>
                        </Menu>
                      ) : (
                        // (<span>{viewData?.invoice}</span>)}
                        <Wrap
                          maxH="50px"
                          overflowY="auto"
                          pr="2"
                          sx={{
                            "&::-webkit-scrollbar": { width: "2px" },
                            "&::-webkit-scrollbar-track": {
                              background: "#f1f1f1",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              background: "#888",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              background: "#555",
                            },
                            overflowY: "scroll",
                          }}
                        >
                          {Array.isArray(viewData1) &&
                            viewData1.map((invoice, index) => {
                              // Log each invoice number during mapping

                              return (
                                invoice.invoice_number.trim().length > 0 && (
                                  <Tag
                                    key={index}
                                    variant="solid"
                                    colorScheme="black"
                                    size="sm"
                                    borderRadius="full"
                                  >
                                    <TagLabel color="black">
                                      <a
                                        href="#"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "steelblue",
                                          textDecoration: "none",
                                          "&:hover": {
                                            textDecoration: "underline",
                                            color: "#2A4D6D",
                                          },
                                        }}
                                      >
                                        {invoice.invoice_number}
                                      </a>
                                    </TagLabel>
                                  </Tag>
                                )
                              );
                            })}
                        </Wrap>
                      )}
                    </div>
                  )}

                  <div className="form_list">
                    <span className="detail">Payout Date</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="date"
                              name="payout_date"
                              value={date.payout_date}
                              onChange={handleDateChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatDate(viewData?.payout_date)}</span>
                    )}
                  </div>

                  <>
                    <div className="form_list">
                      <span className="detail">Creator's commission</span>
                      {formatAmount(viewData?.creator_payout)}
                    </div>
                    {roles !== "Influencer" && (
                      <>
                        <div className="form_list">
                          <span className="detail">Manager's commission</span>
                          {formatAmount(viewData?.manager_payout)}
                        </div>
                        <div className="form_list">
                          <span className="detail">CM's commission</span>
                          {formatAmount(viewData?.cm_payout)}
                        </div>
                      </>
                    )}
                  </>
                </TabPanel>

                {/* <===============================================================================================> */}
                {roles !== "Influencer" && (
                  <TabPanel
                    className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                  >
                    <div className="form_list">
                      {isEditing ? (
                        <Menu>
                          <Box>
                            <MenuGroup>
                              <Textarea
                                type="text"
                                name="notes"
                                value={inputValue.notes}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                autoFocus
                              />
                            </MenuGroup>
                          </Box>
                        </Menu>
                      ) : (
                        <span>{formatNotes(viewData.notes)}</span>
                      )}
                    </div>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        </SkeletonText>
      </Box>
    </>
  );
};

export default ViewDeals;
