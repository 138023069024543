import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { FaUsers, FaDollarSign, FaFileContract } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { FiUserPlus } from "react-icons/fi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import downImage from "../../menu.png";
import upImage from "../../close.png";
import {
  MdOutlineAlternateEmail,
  MdOutlineCampaign,
  MdOutlineDashboardCustomize,
  MdOutlineEmail,
  MdOutlinePersonSearch,
  MdPersonSearch,
} from "react-icons/md";
import { PiUploadSimple } from "react-icons/pi";
import { FcGoogle } from "react-icons/fc";
import GmailAuth from "../../GmailAuth";
import { AiOutlineApi } from "react-icons/ai";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { TbCameraStar } from "react-icons/tb";
import { useNotification } from "../../services/NotificationContext";
// import { FaFileContract } from 'react-icons/fa';

const NavigationPanel = ({ mainPath }) => {
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  const navigate = useNavigate();
  const [isMoreMenuVisible, setIsMoreMenuVisible] = useState(false);
  const [isMainMenuVisible1, setIsMainMenuVisible1] = useState(false);
  const [isMobileView, setisMobileView] = useState(window.innerWidth <= 786);
  const [showScouting, setShowScouting] = useState(false);

  const { totalCount, handleEmailRead, createNotification, getNotifications } = useNotification();

  useEffect(() => {
    handleEmailRead();

  }, [])

  useEffect(() => {
    createNotification();
    getNotifications();
  })

  // const response = await createNotification({ message: notificationData });
  useEffect(() => {
    const handleResize = () => {
      setisMobileView(window.innerWidth <= 786);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCloseToggle = () => {
    setIsMainMenuVisible1(false);

  };

  const handleOpenToggle = () => {
    setIsMainMenuVisible1(true);

  };

  const handleToggleMenu2 = () => {
    setIsMainMenuVisible1(false);
  };
  const handleResetTab = () => {
    localStorage.setItem("TabIndex", 0);
  };
  const location = useLocation();
  const path = location.pathname;

  const { colorMode } = useColorMode();

  const Email = localStorage.getItem("userEmail");

  const handleLogout = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("token");
    localStorage.removeItem("Role");
    window.location.href = "/login";
  };
  return (
    <Box
      className="menu-full  menu_style"
      boxShadow={
        colorMode === "light" ? "base" : "4px 0px 8px rgba(0, 0, 0, 0.1)"
      }
      display={"flex"}
      //flex={"1"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height={"100vh"}
      w={"250px"}
      maxWidth={"300px"}
    >
      <Box className="menu-mob">
        <Flex
          className="menu-left"
          m={"15px 20px"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Avatar
            size={"2xl"}
            name={Email}
            src="http://creativesmatter.com/wp-content/uploads/2024/07/CM-Logo-App-Header.gif"
            w={"60%"}
            h={"60%"}
          />
          <div className="name-box">
            <Heading size={"md"} mt={"10px"} mb={"2px"} color={"#black"}>
              Creatives Matter
            </Heading>
            <Text
              fontSize={"12px"}
              display={"flex"}
              alignItems={"center"}
              gap={"5px"}
              color={"gray.500"}
            >
              <TfiEmail />
              {Email}
            </Text>
          </div>
        </Flex>
        <Divider className="mob-none" borderColor={"green.500"} />

        <Box m={"15px 20px"} className="inner_links">
          {isMobileView ? (
            <>
              {/* <Link onClick={handleToggleMenu}>
                                <Flex alignItems={"center"} gap={"10px"} mb={"12px"} display={{ base: 'flex', md: 'none' }}>
                                    <img src={isMainMenuVisible1 ? upImage : downImage} alt="Menu Icon" width={22} height={22} />
                                </Flex>
                            </Link> */}
              {isMainMenuVisible1 ? (
                <div onClick={handleCloseToggle}>
                  <Flex
                    alignItems={"center"}
                    gap={"10px"}
                    mb={"12px"}
                    display={{ base: "flex", md: "none" }}
                  >
                    <img src={upImage} alt="Menu Icon" width={22} height={22} />
                  </Flex>
                </div>
              ) : (
                <div onClick={handleOpenToggle}>
                  <Flex
                    alignItems={"center"}
                    gap={"10px"}
                    mb={"12px"}
                    display={{ base: "flex", md: "none" }}
                  >
                    <img
                      src={downImage}
                      alt="Menu Icon"
                      width={22}
                      height={22}
                    />
                  </Flex>
                </div>
              )}

              <div className="menu-mobile">
                {isMainMenuVisible1 && (
                  <>
                    <Link
                      to={`${mainPath}/dashboard`}
                      className={
                        path === `${mainPath}/dashboard` ? "active" : ""
                      }
                    >
                      <Flex alignItems={"center"} gap={"10px"} mb={"12px"}>
                        <MdOutlineDashboardCustomize
                          size={22}
                          className={
                            path === `${mainPath}/dashboard`
                              ? "active_icon"
                              : "inactive_icon"
                          }
                        />
                        <Text onClick={handleCloseToggle}>Dashboard</Text>
                      </Flex>
                    </Link>

                    {role !== "Influencer" && (
                      <Link
                        to={`${mainPath}/Inbox`}
                        className={path === `${mainPath}/Inbox` ? "active" : ""}
                      >
                        <Flex alignItems={"center"} gap={"10px"} mb={"12px"} >
                          <IoIosMail
                            size={22}
                            className={
                              path === `${mainPath}/Inbox`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text onClick={handleCloseToggle} style={{ position: "relative" }}>
                            Inbox
                            {totalCount !== 0 && (
                              <span
                                className="notice"
                                style={{
                                  position: "absolute",
                                  left: "50px",
                                  top: "0px",
                                  backgroundColor: "rgb(248, 9, 9)",
                                  color: "white",
                                  borderRadius: "10%",
                                  fontSize: "15px",
                                  height: "24px",
                                  width: "35px",
                                }}
                              >
                                {totalCount}
                              </span>
                            )}
                          </Text>
                        </Flex>
                      </Link>
                    )}

                    {role !== "Influencer" ? (
                      <Link
                        to={`${mainPath}/deals?page=1`}
                        className={path === `${mainPath}/deals` ? "active" : ""}
                      >
                        <Flex alignItems={"center"} gap={"10px"} mb={"12px"}>
                          <FaDollarSign
                            size={22}
                            className={
                              path === `${mainPath}/deals`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text onClick={handleCloseToggle}>Deals</Text>
                        </Flex>
                      </Link>
                    ) : (
                      <Link
                        to={`${mainPath}/All_Deals?page=1`}
                        className={
                          path === `${mainPath}/All_Deals` ? "active" : ""
                        }
                      >
                        <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                          <FaDollarSign
                            size={20}
                            className={
                              path === `${mainPath}/All_Deals`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text fontSize={15} onClick={handleCloseToggle}>Deals</Text>
                        </Flex>
                      </Link>
                    )}

                    <NavLink to={`${mainPath}/Outreach`}>
                      <Flex alignItems={"center"} gap={"10px"} mb={"12px"}>
                        <MdOutlineCampaign
                          size={22}
                          className={
                            path === `${mainPath}/Outreach`
                              ? "active_icon"
                              : "inactive_icon"
                          }
                        />
                        <Text onClick={handleCloseToggle}>Outreach</Text>
                      </Flex>
                    </NavLink>

                    {role !== "Influencer" && (
                      <NavLink to={`${mainPath}/scouting`}>
                        <Flex alignItems={"center"} gap={"10px"} mb={"12px"}>
                          <TbCameraStar
                            size={22}
                            className={
                              path === `${mainPath}/scouting`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text onClick={handleCloseToggle}>Scouting</Text>
                        </Flex>
                      </NavLink>
                    )}

                    {role !== "Influencer" && (
                      <NavLink to={`${mainPath}/Contract_Review`}>
                        <Flex alignItems={"center"} gap={"10px"} mb={"12px"}>
                          <FaFileContract
                            size={22}
                            className={
                              path === `${mainPath}/Contract_Review`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text onClick={handleCloseToggle}>Contracts</Text>
                        </Flex>
                      </NavLink>
                    )}

                    {role !== "Influencer" && (
                      <div
                        onClick={() => setIsMoreMenuVisible(!isMoreMenuVisible)}
                      >
                        <Flex alignItems={"center"} gap={"10px"}>
                          {!isMoreMenuVisible && <RxCaretDown size={22} />}
                          <Text>{!isMoreMenuVisible && "More"}</Text>
                        </Flex>
                      </div>
                    )}

                    {role === "Influencer" && (
                      <NavLink to={`${mainPath}/profile`}>
                        <Flex
                          alignItems={"center"}
                          gap={"10px"}
                          mb={"12px"}
                        >
                          <FaUser
                            size={22}
                            className={
                              path === `${mainPath}/profile`
                                ? "active_icon"
                                : "inactive_icon"
                            }
                          />
                          <Text onClick={handleCloseToggle}>Profile</Text>
                        </Flex>
                      </NavLink>
                    )}

                    {isMoreMenuVisible && (
                      <>
                        {role !== "Influencer" && (
                          <NavLink to={`${mainPath}/sequences`}>
                            <Flex
                              alignItems={"center"}
                              gap={"10px"}
                              mb={"12px"}
                            >
                              <AiOutlineApi
                                size={22}
                                className={
                                  path === `${mainPath}/sequences`
                                    ? "active_icon"
                                    : "inactive_icon"
                                }
                              />
                              <Text onClick={handleCloseToggle}>Sequences</Text>
                            </Flex>
                          </NavLink>
                        )}

                        {role !== "Influencer" && (
                          <NavLink to={`${mainPath}/company`}>
                            <Flex
                              alignItems={"center"}
                              gap={"10px"}
                              mb={"12px"}
                            >
                              <HiOutlineBuildingOffice
                                size={22}
                                className={
                                  path === `${mainPath}/company`
                                    ? "active_icon"
                                    : "inactive_icon"
                                }
                              />
                              <Text onClick={handleCloseToggle}>Company</Text>
                            </Flex>
                          </NavLink>
                        )}

                        {role !== "Influencer" && (
                          <NavLink to={`${mainPath}/prospect`}>
                            <Flex
                              alignItems={"center"}
                              gap={"10px"}
                              mb={"12px"}
                            >
                              <MdOutlinePersonSearch
                                size={22}
                                className={
                                  path === `${mainPath}/prospect`
                                    ? "active_icon"
                                    : "inactive_icon"
                                }
                              />
                              <Text onClick={handleCloseToggle}>Prospect</Text>
                            </Flex>
                          </NavLink>
                        )}

                        {role !== "Influencer" && (
                          <NavLink to={`${mainPath}/influencer`}>
                            <Flex
                              alignItems={"center"}
                              gap={"10px"}
                              mb={"12px"}
                            >
                              <FiUserPlus
                                size={22}
                                className={
                                  path === `${mainPath}/influencer`
                                    ? "active_icon"
                                    : "inactive_icon"
                                }
                              />
                              <Text onClick={handleCloseToggle}>
                                Influencer
                              </Text>
                            </Flex>
                          </NavLink>
                        )}

                        {role !== "Influencer" &&
                          role !== "Creator Manager" && (
                            <>
                              <NavLink to={`${mainPath}/users`}>
                                <Flex
                                  alignItems={"center"}
                                  gap={"10px"}
                                  mb={"12px"}
                                >
                                  <FaUsers
                                    size={22}
                                    className={
                                      path === `${mainPath}/users`
                                        ? "active_icon"
                                        : "inactive_icon"
                                    }
                                  />
                                  <Text onClick={handleCloseToggle}>Users</Text>
                                </Flex>
                              </NavLink>

                              <NavLink to={`${mainPath}/accounts`}>
                                <Flex
                                  alignItems={"center"}
                                  gap={"10px"}
                                  mb={"12px"}
                                >
                                  <MdOutlineEmail
                                    size={22}
                                    className={
                                      path === `${mainPath}/accounts`
                                        ? "active_icon"
                                        : "inactive_icon"
                                    }
                                  />
                                  <Text onClick={handleCloseToggle}>
                                    Accounts
                                  </Text>
                                </Flex>
                              </NavLink>

                              <NavLink to={`${mainPath}/uploader`}>
                                <Flex
                                  alignItems={"center"}
                                  gap={"10px"}
                                  mb={"12px"}
                                >
                                  <PiUploadSimple
                                    size={22}
                                    className={
                                      path === `${mainPath}/uploader`
                                        ? "active_icon"
                                        : "inactive_icon"
                                    }
                                  />
                                  <Text onClick={handleCloseToggle}>
                                    Import
                                  </Text>
                                </Flex>
                              </NavLink>
                            </>
                          )}

                      </>
                    )}

                    {role !== "Influencer" && (
                      <div
                        onClick={() => setIsMoreMenuVisible(!isMoreMenuVisible)}
                      >
                        <Flex alignItems={"center"} gap={"10px"}>
                          {isMoreMenuVisible && <RxCaretUp size={22} />}
                          <Text>{isMoreMenuVisible && "Less"}</Text>
                        </Flex>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <Link
                to={`${mainPath}/dashboard`}
                className={path === `${mainPath}/dashboard` ? "active" : ""}
              >
                <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                  <MdOutlineDashboardCustomize
                    size={20}
                    className={
                      path === `${mainPath}/dashboard`
                        ? "active_icon"
                        : "inactive_icon"
                    }
                  />
                  <Text fontSize={15}>Dashboard</Text>
                </Flex>
              </Link>

              {/* <Link to="/admin/TestBord" className={path === "/admin/TestBord" ? "active" : ""}>
                                <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                                    <MdOutlineDashboardCustomize size={20} className={path === "/admin/TestBord" ? "active_icon" : "inactive_icon"} />
                                    <Text fontSize={15}>Dashboard</Text>
                                </Flex>
                            </Link> */}
              {role !== "Influencer" && (
                <Link
                  to={`${mainPath}/Inbox`}
                  className={path === `${mainPath}/Inbox` ? "active" : ""}
                >
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <IoIosMail
                      size={20}
                      className={
                        path === `${mainPath}/Inbox`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text
                      fontSize={15}
                      onClick={handleResetTab}
                      style={{ display: "flex", position: "relative" }}
                    >
                      Inbox
                      {totalCount !== 0 && (
                        <span
                          className="notice"
                          style={{
                            position: "absolute",
                            left: "50px",
                            top: "2px",
                            backgroundColor: "rgb(248, 9, 9)",
                            color: "white",
                            borderRadius: "10%",
                            fontSize: "15px",
                            height: "24px",
                            width: "35px",
                          }}
                        >
                          {totalCount}
                        </span>
                      )}
                    </Text>
                  </Flex>
                </Link>
              )}

              {role !== "Influencer" ? (
                <Link
                  to={`${mainPath}/deals?page=1`}
                  className={path === `${mainPath}/deals` ? "active" : ""}
                >
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FaDollarSign
                      size={20}
                      className={
                        path === `${mainPath}/deals`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Deals</Text>
                  </Flex>
                </Link>
              ) : (
                <Link
                  to={`${mainPath}/All_Deals?page=1`}
                  className={path === `${mainPath}/All_Deals` ? "active" : ""}
                >
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FaDollarSign
                      size={20}
                      className={
                        path === `${mainPath}/All_Deals`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Deals</Text>
                  </Flex>
                </Link>
              )}

              <NavLink to={`${mainPath}/Outreach`}>
                <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                  <MdOutlineCampaign
                    size={20}
                    className={
                      path === `${mainPath}/Outreach`
                        ? "active_icon"
                        : "inactive_icon"
                    }
                  />
                  <Text fontSize={15}>Outreach</Text>
                </Flex>
              </NavLink>

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/scouting`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <TbCameraStar
                      size={20}
                      className={
                        path === `${mainPath}/scouting`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Scouting</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/Contract_Review`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FaFileContract
                      size={20}
                      className={
                        path === `${mainPath}/Contract_Review`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Contracts</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/sequences`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <AiOutlineApi
                      size={20}
                      className={
                        path === `${mainPath}/sequences`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Sequences</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/company`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <HiOutlineBuildingOffice
                      size={20}
                      className={
                        path === `${mainPath}/company`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Company</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/prospect`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <MdOutlinePersonSearch
                      size={20}
                      className={
                        path === `${mainPath}/prospect`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Prospect</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && (
                <NavLink to={`${mainPath}/influencer`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FiUserPlus
                      size={20}
                      className={
                        path === `${mainPath}/influencer`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Influencer</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && role !== "Creator Manager" && (
                <NavLink to={`${mainPath}/users`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FaUsers
                      size={20}
                      className={
                        path === `${mainPath}/users`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Users</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && role !== "Creator Manager" && (
                <NavLink to={`${mainPath}/accounts`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <MdOutlineEmail
                      size={20}
                      className={
                        path === `${mainPath}/accounts`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Accounts</Text>
                  </Flex>
                </NavLink>
              )}

              {role !== "Influencer" && role !== "Creator Manager" && (
                <NavLink to={`${mainPath}/uploader`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <PiUploadSimple
                      size={20}
                      className={
                        path === `${mainPath}/uploader`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Import</Text>
                  </Flex>
                </NavLink>
              )}

              {role === "Influencer" && (
                <NavLink to={`${mainPath}/profile`}>
                  <Flex alignItems={"center"} gap={"10px"} mb={"8px"}>
                    <FaUser
                      size={20}
                      className={
                        path === `${mainPath}/profile`
                          ? "active_icon"
                          : "inactive_icon"
                      }
                    />
                    <Text fontSize={15}>Profile</Text>
                  </Flex>
                </NavLink>
              )}

              {/* <Link onClick={() => setIsMoreMenuVisible(!isMoreMenuVisible)}>
                                <Flex alignItems={"center"} gap={"10px"}>
                                    {!isMoreMenuVisible && <RxCaretDown size={22} />}
                                    <Text>{!isMoreMenuVisible && "More"}</Text>
                                </Flex>
                            </Link> */}

              {/* <Link onClick={() => setIsMoreMenuVisible(!isMoreMenuVisible)}>
                                <Flex alignItems={"center"} gap={"10px"}>
                                    {isMoreMenuVisible && <RxCaretUp size={22} />}
                                    <Text>{isMoreMenuVisible && "Less"}</Text>
                                </Flex>
                            </Link> */}
            </>
          )}
        </Box>
      </Box>
      <Box className="menu_links_list">
        <Divider className="mob-none" borderColor={"green.500"} />
        <Box className="mob-none" m={"15px 20px"}>
          {/* <Flex alignItems={"center"} gap={"10px"} my={2} >
                        <GmailAuth/>
                    </Flex> */}

          <Link to="/login">
            <Flex alignItems={"center"} gap={"10px"} onClick={handleLogout}>
              <RiLogoutCircleRLine size={20} />
              <Text fontSize={15}>Logout</Text>
            </Flex>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationPanel;
