import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ProspectTableModal from "../Table/ProspectTableModal";
import { fetchInfluencerMetaData } from "../../services/influencerApi";
import { fetchAllSequences } from "../../services/sequenceApi";
import { fetchUserEmails } from "../../services/userEmailsApi";

const ProspectModal = ({
  isOpen,
  onClose,
  prospectData,
  companyID,
  companyName,
  fetchCompanyData,
  handleRowClick,
  setUpdateProspect,
  setIsOpenProspectModal,
  updateProspect,
  setTargetProspectId,
  setUpdateEmail,
  updateEmail,
  setProspectDelete,
  setProspectData,
}) => {
  const [sequences, setSequences] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [influencers, setInfluencers] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSequences();
      fetchEmails();
      fetchInfluencers();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const fetchInfluencers = async () => {
    try {
      const influencers = await fetchInfluencerMetaData(token);
      setInfluencers(influencers);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const fetchSequences = async () => {
    try {
      const sequences = await fetchAllSequences(token);
      // Handle the list of sequences
      setSequences(sequences);
      // toast({
      //   title: 'Success',
      //   description: 'Sequences fetched successfully',
      //   status: 'success',
      //   position: "bottom",
      //   duration: 3000,
      //   isClosable: true,
      // });
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const fetchEmails = async () => {
    try {
      const emails = await fetchUserEmails(token);
      setUserEmails(emails);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  return (
    <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={"500"} fontSize={"2xl"}>
          Prospects
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProspectTableModal
            sequences={sequences}
            influencers={influencers}
            userEmails={userEmails}
            prospectData={prospectData}
            companyID={companyID}
            companyName={companyName}
            fetchCompanyData={fetchCompanyData}
            handleRowClick={handleRowClick}
            setUpdateProspect={setUpdateProspect}
            onCloseProspect={onClose}
            setIsOpenProspectModal={setIsOpenProspectModal}
            updateProspect={updateProspect}
            setTargetProspectId={setTargetProspectId}
            setUpdateEmail={setUpdateEmail}
            updateEmail={updateEmail}
            setProspectDelete={setProspectDelete}
            setProspectData={setProspectData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProspectModal;
