import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./EventsCalendar.css"; // Custom CSS for styling
import CustomToolbar from "./CustomToolbar"; // Import the custom toolbar
import axios from "axios";
import Legend from "./Legend"; // Import the Legend component

const localizer = momentLocalizer(moment);

const EventsCalendar = ({ closeCalendar }) => {
  const readableDate = new Date(Date.now());
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const [selectedDate, setSelectedDate] = useState(readableDate);

  const [events, setEvents] = useState([]);

  const [events1, setEvents1] = useState([]);
  const calendarRef = useRef(null); // Create a ref for the calendar
  const [filteredEvents, setFilteredEvents] = useState([]);
  const convertData = (array) => {
    // Create a Map to store unique entries by date and type
    const uniqueEntries = new Map();

    // Process each item in the array

    array.forEach((item) => {
      const key = `${item.date}-${item.type}`; // Unique key for each date-type combination

      // Only add if the key is not already in the map
      if (!uniqueEntries.has(key)) {
        uniqueEntries.set(key, {
          title: item.message,
          start: item.date,
          end: item.date,
          allDay: true,
          color:
            item.type === "rough_draft"
              ? "red"
              : item.type === "live"
              ? "blue"
              : "green",
          link: item.link,
          type: item.type,
        });
      }
    });

    // Convert the Map values to an array
    return Array.from(uniqueEntries.values());
  };

  const convertData1 = (array) => {
    return array.map((item) => {
      return {
        title: item.message,
        start: item.date,
        end: item.date,
        allDay: true,
        color:
          item.type === "rough_draft"
            ? "red"
            : item.type === "live"
            ? "blue"
            : "green",
        link: item.link,
        type: item.type,
        dealname: item.deal_name,
      };
    });
  };

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/events/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const eventsData = convertData(response?.data?.events || []);
        const eventsData1 = convertData1(response?.data?.events || []);

        setEvents(eventsData);
        setEvents1(eventsData1);
      })
      .catch((error) => {});

    // Add event listener for clicks outside the calendar
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        closeCalendar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [BASE_URL, token, closeCalendar]);

  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "50%",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "inline-block",
      width: "10px",
      height: "10px",
      margin: "0 2px",
    };
    return {
      style,
    };
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  // const filteredEvents = selectedDate
  //   ? events1.filter((event) => moment(event.start).isSame(selectedDate, 'day'))
  //   : [];

  useEffect(() => {
    if (!selectedDate) {
      setFilteredEvents([]);
    } else {
      const newFilteredEvents = events1.filter((event) =>
        moment(event.start).isSame(selectedDate, "day")
      );
      setFilteredEvents(newFilteredEvents);
    }
  }, [selectedDate, events1]);

  return (
    <div className="calender-container" ref={calendarRef}>
      <div className="close-c" onClick={closeCalendar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94"
          height="94"
          viewBox="0 0 94 94"
          fill="none"
        >
          <g clipPath="url(#clip0_61_285)">
            <path
              d="M50.6817 47L72.3278 25.3539C72.7555 24.8543 72.9791 24.2118 72.9537 23.5547C72.9283 22.8975 72.6559 22.2741 72.1908 21.8091C71.7258 21.3441 71.1024 21.0717 70.4453 21.0463C69.7881 21.0209 69.1456 21.2444 68.6461 21.6722L47 43.3183L25.3539 21.6461C24.8622 21.1544 24.1953 20.8782 23.5 20.8782C22.8046 20.8782 22.1378 21.1544 21.6461 21.6461C21.1544 22.1378 20.8782 22.8046 20.8782 23.5C20.8782 24.1953 21.1544 24.8622 21.6461 25.3539L43.3183 47L21.6461 68.6461C21.3728 68.8802 21.1508 69.1682 20.994 69.4922C20.8373 69.8161 20.7492 70.1689 20.7353 70.5285C20.7215 70.8881 20.782 71.2467 20.9133 71.5818C21.0446 71.9169 21.2437 72.2212 21.4982 72.4757C21.7526 72.7301 22.057 72.9292 22.392 73.0605C22.7271 73.1918 23.0857 73.2524 23.4453 73.2385C23.8049 73.2246 24.1577 73.1365 24.4817 72.9798C24.8056 72.8231 25.0937 72.6011 25.3278 72.3277L47 50.6816L68.6461 72.3277C69.1456 72.7555 69.7881 72.979 70.4453 72.9537C71.1024 72.9283 71.7258 72.6559 72.1908 72.1908C72.6559 71.7258 72.9283 71.1024 72.9537 70.4453C72.9791 69.7881 72.7555 69.1456 72.3278 68.6461L50.6817 47Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_61_285">
              <rect width="94" height="94" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <Calendar
        localizer={localizer}
        // events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        views={["month"]}
        components={{
          toolbar: CustomToolbar, // Use the custom toolbar
          month: {
            dateHeader: ({ label, date }) => (
              <div
                className="rbc-date-cell"
                onClick={() => handleDateClick(date)}
              >
                <span>{label}</span>
                {events
                  .filter((event) => moment(event.start).isSame(date, "day"))

                  .map((event) => (
                    <span
                      key={event.title}
                      className={"event-marker" + event.color}
                    ></span>
                  ))}
              </div>
            ),
          },
        }}
        selectable
      />
      <Legend /> {/* Include the Legend component here */}
      <div className="events-list">
        <h3>Events</h3>
        <style>
          {`
          .events-list a:hover {
            text-decoration: underline;
          }
        `}
        </style>
        <ul>
          {filteredEvents.length > 0 ? (
            filteredEvents?.map((event) => {
              const hasValidLink = event?.link?.trim() !== "";
              const { dealname, title, link, color } = event;

              // Escape special characters in the dealname for the regex
              const escapedDealname = dealname.replace(
                /[-\/\\^$*+?.()|[\]{}]/g,
                "\\$&"
              );
              const dealnameRegex = new RegExp(`(${escapedDealname})`, "g");

              // Create a regex to find the dealname in the title
              // const dealnameRegex = new RegExp(`(${dealname})`, 'g');

              const formattedTitle = hasValidLink
                ? title.replace(
                    dealnameRegex,
                    `<a href="${link}" rel="noopener noreferrer" style="color: steelblue;">$1</a>`
                  )
                : title;
              return (
                <li key={event.id} className={"eventslist-" + event.color}>
                  {hasValidLink ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: formattedTitle }}
                    />
                  ) : (
                    <span>{event.title}</span>
                  )}
                </li>
              );
            })
          ) : (
            <p>No events today</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default EventsCalendar;
