import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

export default function CreateNewEmailTemplate({
  isOpen,
  onClose,
  fetchEmailTemp,
  fetchTemplates,
}) {
  const [templateName, setTemplateName] = useState("");
  const [templateContent, setTemplateContent] = useState("");
  const [keywords, setKeywords] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [nameError, setNameError] = useState("");
  const [contentError, setContentError] = useState("");
  const toast = useToast();

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const handleNameChange = (event) => {
    const value = event.target.value;
    setTemplateName(value);
    setNameError(value ? "" : "Template name is required");
  };

  const handleContentChange = (event) => {
    const value = event.target.value;
    setTemplateContent(value);
    setContentError(value ? "" : "Template content is required");
  };

  const handleKeywordsChange = (event) => {
    const value = event.target.value;
    if (value) {
      setKeywords(value);
      setTemplateContent((prevContent) => prevContent + value + " ");
      setSelectedKeyword("");
    }
  };

  const convertToHtmlFormat = (text) => {
    const lines = text.split("\n");
    const formattedLines = lines.map((line) => line.trim());
    const formattedText = formattedLines.join("<br>");
    const finalText = formattedText
      .replace(/\((.*?)\)/g, "<span>($1)</span>")
      .replace(/\{(.*?)\}/g, "<span>{$1}</span>");
    return finalText;
  };

  const handleSave = async () => {
    try {
      if (!templateName || !templateContent) {
        setNameError(!templateName ? "Template name is required" : "");
        setContentError(!templateContent ? "Template content is required" : "");
        return;
      }

      const formattedBody = convertToHtmlFormat(templateContent);
      const response = await fetch(`${BASE_URL}/create-composer-template/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          data: {
            name: templateName,
            body: formattedBody,
            keywords: keywords.split(",").map((keyword) => keyword.trim()),
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.message === "data is saved successfully") {
        await fetchEmailTemp();
        await fetchTemplates();
        toast({
          title: "Template saved.",
          description: "Your email template has been successfully created.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      setTemplateName("");
      setTemplateContent("");
      setKeywords("");
      onClose();
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to save the email template. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent style={{ maxWidth: "700px", height: "600px" }}>
        <ModalHeader>Create New Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} mb={0}>
          <Flex>
            <Box mr={2} mt={2}>
              Name
            </Box>
            <Input
              type="text"
              className="email_template_name"
              value={templateName}
              placeholder="Enter name"
              onChange={handleNameChange}
            />
          </Flex>
          {nameError && (
            <span style={{ color: "red" }} mb={4}>
              {nameError}
            </span>
          )}
          <Divider mb={4} />

          <Box mb={4}>
            <Textarea
              value={templateContent}
              placeholder="Enter template content"
              className="email_template_content"
              width="100%"
              height="250px"
              onChange={handleContentChange}
            />
            {contentError && (
              <span style={{ color: "red", marginLeft: "3px" }} mb={4}>
                {contentError}
              </span>
            )}
          </Box>

          <Divider mb={4} />

          <Flex>
            <Box mr={2} mt={2}>
              Short Codes
            </Box>
            <Select
              className="email_template_keywords"
              // placeholder="Select Short Codes"
              width="200px"
              value={selectedKeyword}
              onChange={handleKeywordsChange}
            >
              <option value="">Select Short Codes</option>
              <option value="{name}">{"{name}"}</option>
              <option value="{talent_name}">{"{talent_name}"}</option>
              <option value="{media_kit}">{"{media_kit}"}</option>
            </Select>
          </Flex>
        </ModalBody>

        <ModalFooter mb={4}>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
