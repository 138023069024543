import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  Menu,
  MenuGroup,
  Spinner,
  Card,
  ModalFooter
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon, AddIcon } from "@chakra-ui/icons";
import React, { useRef, useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { FaUserPlus } from "react-icons/fa";
import { HiBuildingOffice } from "react-icons/hi2";
import { MdPersonSearch } from "react-icons/md";
import { IoImages } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ProspectFormModal = ({ isOpen, onClose }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const imageInputRef = useRef(null);
  const navigate = useNavigate();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  //const [company, setCompany] = useState({name:"",id:""});
  const [companyList, setcompanyList] = useState([]);
  const [isCompanySearchLoading, setIsCompanySearchLoading] = useState(false);

  const [prospectData, setProspectData] = useState({
    profilePicture: "",
    firstName: "",
    lastName: "",
    email: "",
    position: "",
    companyName: "",
    linkedinURL: "",
    location: "",
    emailValidity: "",
    companyId: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    position: "",
    location: "",
    emailValidity: "",
    linkedinURL: "",
    // metaTag: ""
  });

  const fetchCompanies = async () => {
    try {
      setIsCompanySearchLoading(true);
      const response = await fetch(
        `${BASE_URL}/companies/?search=${prospectData.companyName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      setcompanyList(data?.companies_data);
      setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleProspectDataChange = (e) => {
    // setProspectUpDelete(false)
    const { name, value } = e.target;
    setProspectData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  useEffect(() => {
    if (prospectData.companyName?.trim() !== "") {
      const timeout = setTimeout(() => {
        fetchCompanies();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [prospectData.companyName]);

  // const handleProspectDataChange = (e) => {
  //     const { name, value } = e.target;
  //     setProspectData(prev => ({ ...prev, [name]: value }));
  // }

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
    imageInputRef.current.value = null;
  };

  const handleUploadProfilePicture = () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    setIsFileLoading(true);

    fetch(BASE_URL + "/profile/", {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        return response.json();
      })
      .then((data) => {
        setIsFileLoading(false);
        setProfilePictureURL(data?.profile_url);
        setSelectedImage("");

        if (data.profile_url) {
          toast({
            title: "Profile picture has been uploaded.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Failed to upload profile picture.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsFileLoading(false);

        toast({
          title: "Failed to upload profile picture.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      });
  };

  const handleSubmit = () => {
    // setProspectUpDelete(false)

    if (!prospectData.firstName) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required." }));
      return;
    }

    if (!prospectData.lastName) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required." }));
      return;
    }

    // if (!prospectData.position) {
    //     setErrors(prev => ({ ...prev, talentName: "Position is required." }));
    //     return;
    // }

    // if (!prospectData.email) {
    //     setErrors(prev => ({ ...prev, email: "Email is required." }));
    //     return;
    // }

    // if (!prospectData.location) {
    //     setErrors(prev => ({ ...prev, phone: "location is required." }));
    //     return;
    // }

    // if (!prospectData.linkedinURL) {
    //     setErrors(prev => ({ ...prev, address: "LinkedinUrl is required." }));
    //     return;
    // }

    if (!prospectData.companyName) {
      setErrors((prev) => ({
        ...prev,
        companyName: "Company Name is required.",
      }));
      return;
    }
    setIsLoading(true);

    const ProspectPayload = {
      profile_picture: prospectData.profilePicture,
      // profile_picture: profilePictureURL,
      first_name: prospectData.firstName,
      last_name: prospectData.lastName,
      full_name: prospectData.firstName + " " + prospectData.lastName,
      email: prospectData.email,
      position: prospectData.position,
      company_name: prospectData.companyName,
      linkedin_url: prospectData.linkedinURL,
      location: prospectData.location,
      email_validity: prospectData.emailValidity,
      company: prospectData.companyId,
    };

    fetch(BASE_URL + "/addprospect/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(ProspectPayload),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            console.error("Unauthorized: User session expired");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            navigate("/login");
          }
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);

        if (data.message) {
          toast({
            title: "Prospect created successfully",
            description: data.message,
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });

          setTimeout(() => {
            setProspectData({
              profilePicture: "",
              firstName: "",
              lastName: "",
              email: "",
              position: "",
              //companyName:companyName,
              linkedinURL: "",
              location: "",
              emailValidity: "",
              //companyId: ""
            });
            setProfilePictureURL("");

            onClose();
          }, 3000);
        } else {
          toast({
            title: "Prospect creation Failed",
            description: "Failed to Prospect creation",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        toast({
          title: "Prospect creation Failed",
          description: "Failed to Prospect creation",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      });
  };

  return (
    <Box>
      <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent  height={{ base: "100vh", md: "auto" }}>
          <ModalHeader fontWeight={"500"} fontSize={"2xl"}>
            <Flex alignItems={"center"} gap={2}>
              <MdPersonSearch size={28} />
              New Prospect
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className='companyModal_body'>
            <Box>
              {/* <Flex gap={5} mb={6}>
                                <FormControl maxW={"50%"} border={"2px dashed blue"} borderRadius={"10px"} p={"30px 100px"}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Profile Picture</FormLabel>
                                    <Flex flexDirection={"column"} alignItems={"center"} color={"#6B6F7A"}>
                                        <IoImages size={25} />
                                        <Text fontSize={"sm"} mt={"3px"}>
                                            <Link textDecoration={"underline"} color={"blue"} onClick={() => imageInputRef.current.click()}>Click to upload</Link> an image
                                        </Text>
                                        {
                                            selectedImage &&
                                            <>
                                                <Flex alignItems={"center"} justifyContent={"center"}>
                                                    <Text>{selectedImage?.name}</Text>
                                                    //comment out//
                                                    <RxCross2 size={16} color={"red"} cursor={"pointer"} onClick={() => setSelectedImage(null)}  />  
                                                </Flex>
                                                <Button isLoading={isFileLoading} mt={3} variant='outline' colorScheme='green' size={"sm"} onClick={handleUploadProfilePicture}>Upload</Button>
                                            </>
                                        }
                                        <Input type='file' name='profilePicture' style={{ display: 'none' }} onChange={handleImageChange} ref={imageInputRef} accept='image/*' />
                                    </Flex>
                                </FormControl>
                                {
                                    profilePictureURL &&
                                    <Box position={"relative"}>
                                      //Comment out//
                                        <RxCross2 size={20} color={"#ffffff"} cursor={"pointer"} style={{ 
                                            position: 'absolute', 
                                            right: '0', 
                                            background: '#00AA45',
                                            borderTopRightRadius: "10px"
                                        }} onClick={() => setSelectedImage(null)}  />  
                                        //comment above out//
                                        <Image src={profilePictureURL} alt='image' style={{ height: "110px", width: "110px", borderRadius: "10px", objectFit: 'cover' }} />
                                    </Box>
                                }
                            </Flex> */}
              <Flex gap={5} mb={4}>
                {/* <FormControl isRequired isInvalid={!!errors.firstName}>
                                    <FormLabel>Profile Picture</FormLabel>
                                    <Input name='profilePicture' type='text' placeholder='Profile Picture' value={prospectData.profilePicture} onChange={handleProspectDataChange} />
                                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                                </FormControl> */}

                <FormControl isRequired isInvalid={!!errors.firstName}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={prospectData.firstName}
                    onChange={handleProspectDataChange}
                  />
                  <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.lastName}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={prospectData.lastName}
                    onChange={handleProspectDataChange}
                  />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.linkedinURL}>
                  <FormLabel>LinkedIn URL</FormLabel>
                  <Input
                    name="linkedinURL"
                    type="text"
                    placeholder="Linkedin URL"
                    value={prospectData.linkedinURL}
                    onChange={handleProspectDataChange}
                  />
                  <FormErrorMessage>{errors.linkedinURL}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap={5} mb={4}>
                <FormControl isInvalid={!!errors.profilePicture}>
                  <FormLabel>LinkedIn Picture URL</FormLabel>
                  <Input
                    name="profilePicture"
                    type="text"
                    placeholder="LinkedIn Picture URL"
                    value={prospectData.profilePicture}
                    onChange={handleProspectDataChange}
                  />
                  <FormErrorMessage>{errors.profilePicture}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap={5} mb={4}>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={prospectData.email}
                    onChange={handleProspectDataChange}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel>Position</FormLabel>
                  <Input
                    name="position"
                    type="text"
                    placeholder="Position"
                    value={prospectData.position}
                    onChange={handleProspectDataChange}
                  />
                  {/* <FormErrorMessage>{errors.phone}</FormErrorMessage> */}
                </FormControl>
              </Flex>
              <Flex gap={5} mb={4}>
                <FormControl isRequired isInvalid={!!errors.companyName}>
                  <FormLabel>Company Name</FormLabel>
                  <Menu>
                    <Box>
                      <MenuGroup>
                        <InputGroup>
                          <Input
                            name="brand_agency"
                            className="custom-dropdown"
                            style={{
                              resize: "both",
                              minWidth: "200px",
                            }}
                            fontSize="sm"
                            value={prospectData.companyName}
                            onChange={(e) => {
                              const value = e.target.value;
                              setProspectData({
                                ...prospectData,
                                companyName: value,
                              });

                              setIsCompanySearchLoading(true);
                            }}
                            // onBlur={() => handleCellBlur()}

                            // icon={<MdArrowDropDown />}
                            placeholder="Select a Company"
                            _focus={{ zIndex: "0" }}
                          ></Input>
                          {prospectData.companyName ? (
                            <InputRightElement>
                              {isCompanySearchLoading ? (
                                <Spinner w={4} h={4} color="gray.400" />
                              ) : (
                                <CloseIcon
                                  cursor={"pointer"}
                                  w={2.5}
                                  h={2.5}
                                  color="gray.400"
                                  onClick={(e) =>
                                    setProspectData({
                                      ...prospectData,
                                      companyName: "",
                                    })
                                  }
                                />
                              )}
                            </InputRightElement>
                          ) : (
                            <InputRightElement>
                              <SearchIcon w={3} h={3} color="gray.400" />
                            </InputRightElement>
                          )}
                        </InputGroup>
                        {prospectData?.companyName && (
                          <Card mt={1}>
                            {companyList?.length > 0 && (
                              <Box
                                my={1}
                                maxH={"145px"}
                                overflowY={"auto"}
                                sx={{
                                  "&::-webkit-scrollbar": {
                                    width: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    background: "#dddddd",
                                    borderRadius: "100vh",
                                  },
                                }}
                              >
                                {companyList?.map((company, i) => (
                                  <Text
                                    px={3}
                                    py={1}
                                    fontSize={"sm"}
                                    _hover={{
                                      bg: "#EDF2F7",
                                      cursor: "pointer",
                                    }}
                                    key={i}
                                    onClick={() => {
                                      setProspectData({
                                        ...prospectData,
                                        companyName:
                                          company.company.company_name,
                                        companyId: company.company.company_id,
                                      });
                                    }}
                                  >
                                    {company.company.company_name}
                                  </Text>
                                ))}
                              </Box>
                            )}
                          </Card>
                        )}
                      </MenuGroup>
                    </Box>
                  </Menu>
                  {/* <Input name='companyName' type='text' placeholder='Company Name' value={prospectData.companyName} isReadOnly /> */}
                  <FormErrorMessage>{errors.companyName}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap={5} mb={4}>
                {/* <FormControl isRequired isInvalid={!!errors.linkedinURL}>
                                    <FormLabel>Linkedin URL</FormLabel>
                                    <Input name='linkedinURL' type='text' placeholder='Linkedin URL' value={prospectData.linkedinURL} onChange={handleProspectDataChange} />
                                    <FormErrorMessage>{errors.linkedinURL}</FormErrorMessage>
                                </FormControl> */}
                <FormControl>
                  <FormLabel>Location</FormLabel>
                  <Input
                    name="location"
                    type="text"
                    placeholder="Location"
                    value={prospectData.location}
                    onChange={handleProspectDataChange}
                  />
                  {/* <FormErrorMessage>{errors.address}</FormErrorMessage> */}
                </FormControl>
                <FormControl>
                  <FormLabel>Email Validity</FormLabel>
                  <Input
                    name="emailValidity"
                    type="text"
                    placeholder="Email Validity"
                    value={prospectData.emailValidity}
                    onChange={handleProspectDataChange}
                  />
                  {/* <FormErrorMessage>{errors.address}</FormErrorMessage> */}
                </FormControl>
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter>
          <Button
                  isLoading={isLoading}
                  spinner={<PulseLoader size={8} color="white" />}
                  loadingText="Submitting"
                  spinnerPlacement="end"
                  colorScheme="green"
                  mt={8}
                  ml={"auto"}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProspectFormModal;
