import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import NavigationPanel from "../components/Panel/NavigationPanel";
import TopPanel from "../components/Panel/TopPanel";
import { Outlet } from "react-router-dom";
const CreatorManager = ({ mainPath }) => {
  return (
    <Flex flexWrap={"wrap"} width={"100%"} boxSizing='border-box'>
      <NavigationPanel mainPath={mainPath} />
      <Box flex={"3"} minWidth={"300px"}>
        <TopPanel />
        <Outlet />
      </Box>
    </Flex>
  );
};

export default CreatorManager;
