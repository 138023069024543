import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Select,
  SkeletonText,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import NotificationPopModal from "../Modal/NotificationPopModal";
import {
  Textarea,
  Input,
  Menu,
  MenuGroup,
  InputGroup,
  Card,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputRightElement,
  TagCloseButton,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { Spinner } from "@chakra-ui/react";
import { IoMdTrash, IoIosArrowBack } from "react-icons/io";
import { format, parseISO, set } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "../Table/links.css";
import axios from "axios";
import {
  FaFileContract,
  FaYoutube,
  FaInstagram,
  FaTiktok,
  FaFacebook,
  FaSnapchat,
  FaGlobe,
} from "react-icons/fa";
import { Tag, Wrap, TagLabel } from "@chakra-ui/react";
import { FaFileCircleXmark } from "react-icons/fa6";
import { FaUpload } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Image } from "@chakra-ui/react";
import { FormControl, FormLabel, Link } from "@chakra-ui/react";
import { IoImages } from "react-icons/io5";
import { useRef } from "react";
import { IoLogoYoutube } from "react-icons/io5";
import { IoLogoInstagram } from "react-icons/io5";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { IoLogoTiktok } from "react-icons/io5";
import { BiCaretDown } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setUserImg } from "../../store/store";

const ProfileTable = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const imageInputRef = useRef(null);
  const [isFileLoading, setIsFileLoading] = useState(false);
  // const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false)

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [ID, setID] = useState("");
  const [TalName, setTalName] = useState("");
  const [InfluencersId, setInfluencersId] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(false);
  const [viewData, setViewData] = useState("");
  const [viewData1, setViewData1] = useState("");
  const [deleteInvoiceId, setDeleteInvoiceId] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [roles, setRoles] = useState(role);
  const [mainPath, setMainPath] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [profileData, setProfileData] = useState("");

  const [editedInvoice, setEditedInvoice] = useState("");
  const [activeMetaTagDropdown, setActiveMetaTagDropdown] = useState(false);

  const [selectedMetaTags, setSelectedMetaTags] = useState([]);

  const [metaTags, setMetaTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMetaTagData, setFilteredMetaTagData] = useState([]);

  const [inputCompanyValue, setInputCompanyValue] = useState("");
  const [company, setCompany] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companySearch, setCompnaySearch] = useState(false);

  const [inputValue, setInputValue] = useState({
    // profile_picture: "",
    first_name: "",
    last_name: "",
    phone: "",
    birthday: "",
    address: "",
    youTube_username: "",
    dedicated_youTube_rate: "",
    partial_youTube_rate: "",
    youTube_shorts_rate: "",
    IG_reel_rate: "",
    IG_Story_rate: "",
    IG_Feed_rate: "",
    tikTok_rate: "",
    usage_rate: "",
    instagram_username: "",
    tikTok_username: "",
    meta_tags: [],
  });

  const [date, setDate] = useState({
    rough_draft_date: null,
    live_date: null,
    invoice_date: null,
    payout_date: null,
    date_received: null,
  });

  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (profileData) {
      // Extract invoice numbers from viewData1
      const invoiceNumbers = Array.isArray(viewData1)
        ? viewData1.map((invoice) => invoice.invoice_number).filter(Boolean) // Ensure valid invoice numbers
        : [];

      setInputCompanyValue(viewData?.brand_agency);
      setCompany(viewData?.brand_agency);

      setInputValue({
        first_name: profileData.first_name,
        // profile_picture :profilePictureUrl,
        last_name: profileData.last_name,
        meta_tags: profileData.meta_tags,
        phone: profileData.phone,
        birthday: profileData.birthday,
        address: profileData.address,
        instagram_username: profileData.instagram_username,
        youTube_username: profileData.youTube_username,
        tikTok_username: profileData.tikTok_username,
        YouTube_Rate: profileData.dedicated_youTube_rate,
        partial_youTube_rate: profileData.partial_youTube_rate,
        youTube_shorts_rate: profileData.youTube_shorts_rate,
        IG_reel_rate: profileData.IG_reel_rate,
        IG_Story_rate: profileData.IG_Story_rate,
        IG_Feed_rate: profileData.IG_Feed_rate,
        tikTok_rate: profileData.tikTok_rate,
        usage_rate: profileData.usage_rate,
        dedicated_youTube_rate: profileData.dedicated_youTube_rate,
      });

      // setDate({
      //   rough_draft_date: viewData.rough_draft_date,
      //   live_date: viewData.live_date,
      //   invoice_date: viewData.invoice_date,
      //   payout_date: viewData.payout_date,
      //   date_received: viewData.date_received,
      // })
    }
  }, [profileData]);

  useEffect(() => {
    if (viewData1 && editedInvoice) {
      // Extract current invoice numbers from inputValue

      const originalInvoiceNumbers = viewData1.map(
        (item) => item.invoice_number
      );
      // Find removed invoice numbers
      const removedInvoiceNumbers = originalInvoiceNumbers.filter(
        (number) => !editedInvoice.includes(number)
      );

      // Find removed invoice details
      const removedInvoices = viewData1.filter((item) =>
        removedInvoiceNumbers.includes(item.invoice_number)
      );

      const RemoveInvoicesId = removedInvoices.map((item) => item.id);
      setDeleteInvoiceId(RemoveInvoicesId);
    }
  }, [viewData1, editedInvoice]);

  useEffect(() => {
    const Pathname = window?.location?.pathname;
    const Parts = Pathname.split("/");
    const Extractpath = Parts[Parts.length - 1];
    const Tal_name = Parts[Parts.length - 2];
    setTalName(Tal_name);
    setID(Extractpath);
  });

  const FetchProfileData = async (ID) => {
    setIsLoading(true);
    try {
      const result = await axios.get(`${BASE_URL}/influencer-profile/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProfilePictureUrl(result?.data?.user_profile_data.profile_picture_url);

      setProfileData(result.data?.influencer_data);
      localStorage.setItem(
        "userImg",
        result?.data?.user_profile_data.profile_picture_url
      );
      dispatch(setUserImg(result?.data?.user_profile_data.profile_picture_url));

      const metaTags = result.data?.influencer_data?.meta_tags;

      const tags =
        typeof metaTags === "string" ? metaTags.split(",") : metaTags;
      setTags(tags);
      setSelectedMetaTags(tags);

      if (result) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (ID) {
      FetchProfileData(ID);
    }
  }, [ID]);

  // Call FetchProfileData when updateApi changes
  useEffect(() => {
    if (updateApi && ID) {
      FetchProfileData(ID);
    }
  }, [updateApi, ID]);

  useEffect(() => {
    FetchProfileData();
  }, []);

  useEffect(() => {
    if (updateApi) {
      FetchProfileData();
    }
  }, [updateApi]);

  useEffect(() => {
    const routesConfig = [
      {
        path: "/admin",
        role: "Admin",
      },
      {
        path: "/creator",
        role: "Creator Manager",
      },
      {
        path: "/influencer",
        role: "Influencer",
      },
    ];

    const userRoute = routesConfig.find((route) => route.role === roles)?.path;
    setMainPath(userRoute);
  }, [roles]);

  const handleExit = () => {
    const allDealsPage = localStorage.getItem("allDealsPage");
    if (allDealsPage) {
      const TabIndex = localStorage.getItem("TabIndex");
      const allDealsPage = localStorage.getItem("allDealsPage");
      const DealsPage = localStorage.getItem("page");
      navigate(
        `${mainPath}/deals?page=${DealsPage}&AllPages=${allDealsPage}&Tabs=${TabIndex}`
      );
    } else if (roles === "Influencer") {
      navigate(`${mainPath}/All_Deals?page=1`);
    } else {
      let talentName = location.state?.talent_name; // Try to get talent_name from location.state
      if (!talentName) {
        const pathnameParts = window.location.pathname.split("/");
        talentName = pathnameParts[pathnameParts.length - 2]; // Assuming talent_name is the second last part of the pathname
      }

      let id = location.state?.influencer_id;
      if (!id) {
        id = InfluencersId;
      }

      navigate(`${mainPath}/${talentName}/cm/${id}`, {
        state: {
          id: id,
          // id: location.state?.influencer_id,
          tal_name: talentName, // Use the extracted or fallback talentName
        },
      });

      // navigate(`/admin/${location.state.talent_name} `, { state: { id: location.state.influencer_id, tal_name: location.state.talent_name } });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null
    const parsedDate = parseISO(dateString);
    return format(parsedDate, "MM/dd/yyyy");
  };

  const handleSave = async () => {
    // const updatedData = { ...inputValue,profile_picture_url:profilePictureUrl };

    const updatedData = {
      ...inputValue,
      profile_picture_url: profilePictureUrl,
      meta_tags: selectedMetaTags,
    };

    try {
      const response = await fetch(BASE_URL + "/influencer-profile-update/", {
        method: "PATCH",
        body: JSON.stringify(updatedData), // Corrected this line
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
        return; // exit the function after redirect
      }

      if (!response.ok) {
        console.error("Response Status:", response.status);
        console.error("Response Status Text:", response.statusText);
        throw new Error("Network response was not ok");
      }

      // Check if the response is JSON
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const result = await response.json();

        toast({
          title: "Profile Updated",
          description: "profile successfully updated.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        setSelectedImage("");
        setIsEditing(false);
        setUpdateApi(true);
      } else {
        console.error("Expected JSON response but received:", contentType);
        throw new Error("Received non-JSON response");
      }
    } catch (error) {
      console.error("Save failed:", error);

      toast({
        title: "Error",
        description: error.message || "Error updating deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setUpdateApi(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(!isEditing);
    setUpdateApi(false);
    setCompnaySearch(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;

    const value = event.target.value;

    if (name === "meta_tags") {
      // Split the input value by commas to create an array
      const tagsArray = value
        .split(",")
        .map((tag) => tag.trim())
        .filter(Boolean);

      setInputValue((prev) => ({ ...prev, [name]: tagsArray }));
    }
    setInputValue((prev) => ({ ...prev, [name]: value }));
    if (name === "invoice") {
      // Process the value for invoice field
      const newItems = value
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean); // Split by comma and clean up

      // Set the edited invoice data
      setEditedInvoice(newItems);
    }
  };

  const handleDateChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDate((prev) => ({ ...prev, [name]: value || null }));
  };

  const formatNotes = (notes) => {
    return notes?.split("\n")?.map((line, index) => <p key={index}>{line}</p>);
  };

  const formatSplits = (value) => {
    if (value == null || value === "") {
      return "";
    }
    const Values = `${value}%`;
    return Values;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const generateLink = (text) => {
    return <Link style={{ color: "SteelBlue" }}>{text}</Link>;
  };

  const handleFileUpload = async (file) => {
    const Deal_ids = viewData?.deal_id;

    if (!file) return;
    const formData = new FormData();
    formData.append("contract_file", file);

    try {
      const response = await fetch(`${BASE_URL}/deal-contract/${Deal_ids}/`, {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // If you need to pass a token
        },
      });
      const data = await response.json();

      if (data) {
        //    setReview(data.review.response)
        toast({
          title: "File uploaded",
          description: "Your file has been uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Upload failed",
          description: "There was an error uploading your file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Upload faileds",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
      FetchProfileData();
      //   window.location.reload()
    }
  };

  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
    imageInputRef.current.value = null;
  };

  const formatAmount = (value) => {
    const Formatvalue = parseFloat(value);
    const newValue = Formatvalue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return newValue;
  };

  const handleUploadProfilePicture = () => {
    const formData = new FormData();
    formData.append("profile_picture", selectedImage);
    setIsFileLoading(true);

    fetch(BASE_URL + "/update-picture/", {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
          throw new Error("Unauthorized: User session expired");
        }
        return response.json();
      })
      .then((data) => {
        setIsFileLoading(false);
        setProfilePictureUrl(data?.profile_picture);
        setSelectedImage("");

        if (data?.profile_picture) {
          toast({
            title: "Profile picture has been uploaded.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Failed to upload profile picture.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsFileLoading(false);

        toast({
          title: "Failed to upload profile picture.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      });
  };

  // useEffect(()=>{
  // handleSave()
  // },[profilePictureUrl])

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleRemove = async () => {
    // Optionally clear the profile picture URL locally
    setProfilePictureUrl(null);

    try {
      const response = await fetch(`${BASE_URL}/delete-picture/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // Add any required authorization headers, e.g., 'Authorization': `Bearer ${token}`
        },
        // If any body content is needed, add it here. For DELETE, it's often not required.
      });

      if (response.ok) {
        // Successfully deleted the picture

        // Optionally clear the profile picture URL or update the state
        setProfilePictureUrl(null);
      } else {
        // Handle errors
        const errorData = await response.json();
        console.error("Error removing profile picture:", errorData);
        // Optionally show an error message to the user
        toast({
          title: "Error",
          description: "Failed to remove profile picture.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Network error:", error);
      // Handle network or other unexpected errors
      toast({
        title: "Network Error",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleMetaTagClick = (tag) => {
    // Add the selected tag to your state that tracks selected tags.
    if (!selectedMetaTags.includes(tag)) {
      setSelectedMetaTags([...selectedMetaTags, tag]);
    }

    // Remove the selected tag from the list of displayed tags.
    const updatedFilteredMetaTagData = filteredMetaTagData.filter(
      (t) => t !== tag
    );
    setFilteredMetaTagData(updatedFilteredMetaTagData);

    // Don't toggle the dropdown visibility here. This keeps it open.
  };

  const handleMetaTagClose = (metaTag) => {
    const updatedMetaTags = selectedMetaTags.filter((item) => item !== metaTag);
    setSelectedMetaTags(updatedMetaTags);
  };
  const filteredMetaTags = metaTags?.filter((tag) =>
    tag.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const GenerateMetaTag = (value) => {
    const tagsArray = typeof value === "string" ? value.split(",") : value;
    // const tagsArray = Array.isArray(value) ? value : [value];

    return (
      <Wrap
        maxH="50px"
        overflowY="auto"
        pr="2"
        sx={{
          "&::-webkit-scrollbar": { width: "2px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": { background: "#888" },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
        }}
      >
        {tagsArray?.map((tag, index) => (
          <Tag
            key={index}
            variant="solid"
            colorScheme="purple"
            size={"sm"}
            borderRadius="full"
            _hover={{ bg: "#603fad" }}
          >
            <TagLabel>{tag}</TagLabel>
          </Tag>
        ))}
      </Wrap>
    );
  };

  const fetchMetaTags = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-metatag/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      const tags = data?.metatag || [];
      const availableTags = tags.filter(
        (tag) => !selectedMetaTags.includes(tag)
      );

      setMetaTags(availableTags);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMetaTags();
  }, [selectedMetaTags]);

  useEffect(() => {
    if (activeMetaTagDropdown) {
      // fetchMetaTags();
    }
  }, [activeMetaTagDropdown]);

  return (
    <>
      <ChakraProvider />

      <Box px={5} py={3} className="deal_page_container new_deal_form">
        <SkeletonText
          noOfLines={20}
          spacing="2"
          skeletonHeight="6"
          isLoaded={!isLoading}
        >
          <Box className="top_action_btn">
            <Button
              className="cancel_btn"
              size={"sm"}
              colorScheme="blue"
              onClick={handleEdit}
            >
              {isEditing ? "Cancel" : "Edit"}
            </Button>

            {isEditing && (
              <Button size={"sm"} colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
            )}
          </Box>

          <Box>
            <Tabs className="tabs all-tab">
              <TabList>
                <Tab className="tab_head">Personal</Tab>
                <Tab className="tab_head">YouTube</Tab>
                <Tab className="tab_head">Instagram</Tab>
                <Tab className="tab_head">TikTok</Tab>
                <Tab className="tab_head">Usage</Tab>
                <Tab
                  className="tab_head"
                  onClick={() => setActiveMetaTagDropdown(true)}
                >
                  Meta Tags
                </Tab>
              </TabList>

              <TabPanels
                // maxH="365px"
                // overflowY="auto"
                pr="2"
                w="250px"
                sx={{
                  "&::-webkit-scrollbar": { width: "2px" },
                  "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                  "&::-webkit-scrollbar-thumb": { background: "#888" },
                  "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}
              >
                <TabPanel
                  className={`  tab_deatil personal_data ${
                    !isEditing ? "edit_tab" : ""
                  }`}
                >
                  <div className="overflow_box">
                    <div className="overflow_height">
                      <div className="form_list">
                        <span className="detail">Profile Picture</span>
                        {isEditing && !profilePictureUrl ? (
                          <FormControl
                            maxW={"50%"}
                            border={"2px dashed blue"}
                            borderRadius={"10px"}
                            p={"30px 100px"}
                            className="edit-profile"
                          >
                            <FormLabel
                              fontSize="sm"
                              position="absolute"
                              top="-3"
                              left="3"
                              zIndex="1"
                              background="#ffffff"
                              px="1"
                            >
                              Profile Picture
                            </FormLabel>
                            <Flex
                              flexDirection={"column"}
                              alignItems={"center"}
                              color={"#6B6F7A"}
                            >
                              <IoImages size={25} />
                              <Text fontSize={"sm"} mt={"3px"}>
                                <Link
                                  textDecoration={"underline"}
                                  color={"blue"}
                                  onClick={() => imageInputRef.current.click()}
                                >
                                  Click to upload
                                </Link>{" "}
                                an image
                              </Text>
                              {selectedImage && (
                                <>
                                  <Flex
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                  >
                                    <Text>{selectedImage?.name}</Text>
                                    {/* <RxCross2 size={16} color={"red"} cursor={"pointer"} onClick={() => setSelectedImage(null)}  />   */}
                                  </Flex>
                                  <Button
                                    isLoading={isFileLoading}
                                    mt={3}
                                    variant="outline"
                                    colorScheme="green"
                                    size={"sm"}
                                    onClick={handleUploadProfilePicture}
                                  >
                                    Upload
                                  </Button>
                                </>
                              )}
                              <Input
                                type="file"
                                name="profilePicture"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                                ref={imageInputRef}
                                accept="image/png, image/jpeg, image/webp"
                                alt=""
                              />
                            </Flex>
                          </FormControl>
                        ) : (
                          <>
                            <span>
                              {profilePictureUrl && (
                                <Image
                                  alt="Profile Picture"
                                  src={profilePictureUrl}
                                  borderRadius="full"
                                  boxSize="140px"
                                  objectFit="cover"
                                />
                              )}
                            </span>
                            {profilePictureUrl && isEditing && (
                              <button
                                onClick={handleRemove}
                                className="remove-button-profile"
                              >
                                <p className="buttton-text-remove">Remove</p>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      <div className="field_list">
                        <div className="form_list">
                          <span className="detail">First Name</span>
                          {isEditing ? (
                            <Box>
                              <Input
                                name="first_name"
                                value={inputValue.first_name}
                                type="text"
                                onChange={handleChange}
                              />
                            </Box>
                          ) : (
                            <span>{profileData.first_name}</span>
                          )}
                        </div>

                        <div className="form_list">
                          <span className="detail">Last Name</span>
                          {isEditing ? (
                            <Menu>
                              <Box>
                                <MenuGroup>
                                  <Input
                                    type="text"
                                    name="last_name"
                                    value={inputValue.last_name}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    autoFocus
                                  />
                                </MenuGroup>
                              </Box>
                            </Menu>
                          ) : (
                            <span> {profileData.last_name}</span>
                          )}
                        </div>
                      </div>
                      <div className="field_list">
                        <div className="form_list">
                          <span className="detail">Phone Number</span>
                          {isEditing ? (
                            <Menu>
                              <Box>
                                <MenuGroup>
                                  <Input
                                    type="number"
                                    name="phone"
                                    value={inputValue.phone}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    autoFocus
                                  />
                                </MenuGroup>
                              </Box>
                            </Menu>
                          ) : (
                            <span>{profileData.phone}</span>
                          )}
                        </div>

                        <div className="form_list">
                          <span className="detail">Birthday</span>
                          {isEditing ? (
                            <Menu>
                              <Box>
                                <MenuGroup>
                                  <Input
                                    type="date"
                                    name="birthday"
                                    value={inputValue.birthday}
                                    onChange={handleChange}
                                    // onBlur={handleBlur}
                                    autoFocus
                                  />
                                </MenuGroup>
                              </Box>
                            </Menu>
                          ) : (
                            <span>{formatDate(profileData.birthday)}</span>
                          )}
                        </div>
                      </div>
                      <div className="form_list">
                        <span className="detail">Shipping Address</span>
                        {isEditing ? (
                          <Menu>
                            <Box>
                              <MenuGroup>
                                <Textarea
                                  type="text"
                                  name="address"
                                  value={inputValue.address}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                  autoFocus
                                />
                              </MenuGroup>
                            </Box>
                          </Menu>
                        ) : (
                          <span>{profileData.address}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>

                {/* <======================================youTube=========================================================> */}

                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">YouTube Username</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="youTube_username"
                              value={inputValue.youTube_username}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <Link
                        style={{ color: "SteelBlue" }}
                        href={`https://www.youtube.com/${profileData?.youTube_username}`}
                        isExternal
                      >
                        {profileData.youTube_username}
                      </Link>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Dedicated YouTube Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="dedicated_youTube_rate"
                              value={inputValue.dedicated_youTube_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>
                        {" "}
                        {formatAmount(profileData?.dedicated_youTube_rate)}
                      </span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">Partial YouTube Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="partial_youTube_rate"
                              value={inputValue.partial_youTube_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>
                        {formatAmount(profileData?.partial_youTube_rate)}
                      </span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">YouTube Shorts Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="youTube_shorts_rate"
                              value={inputValue.youTube_shorts_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>
                        {" "}
                        {formatAmount(profileData.youTube_shorts_rate)}
                      </span>
                    )}
                  </div>
                </TabPanel>

                {/* <===================================================Ig============================================> */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">Instagram UserName</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="instagram_username"
                              value={inputValue.instagram_username}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <Link
                        style={{ color: "SteelBlue" }}
                        href={`https://www.instagram.com/${profileData.instagram_username}`}
                        isExternal
                      >
                        {" "}
                        {profileData.instagram_username}
                      </Link>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">IG Reel Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="IG_reel_rate"
                              value={inputValue.IG_reel_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatAmount(profileData.IG_reel_rate)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">IG Story Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="IG_Story_rate"
                              value={inputValue.IG_Story_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{formatAmount(profileData.IG_Story_rate)}</span>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">IG Feed Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="IG_Feed_rate"
                              value={inputValue.IG_Feed_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatAmount(profileData?.IG_Feed_rate)}</span>
                    )}
                  </div>
                </TabPanel>

                {/* <===========================================tiktok====================================================> */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">TikTok UserName</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="text"
                              name="tikTok_username"
                              value={inputValue.tikTok_username}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <Link
                        style={{ color: "SteelBlue" }}
                        href={`https://www.tiktok.com/${profileData.tikTok_username}`}
                        isExternal
                      >
                        {" "}
                        {profileData.tikTok_username}
                      </Link>
                    )}
                  </div>

                  <div className="form_list">
                    <span className="detail">TikTok Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="tikTok_rate"
                              value={inputValue.tikTok_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatAmount(profileData.tikTok_rate)}</span>
                    )}
                  </div>
                </TabPanel>
                {/* ++++++++++++++++++++++++++++++++++++++++++++++Usage++++++++++++++++++++++++++++++++++++++++++++++++ */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    <span className="detail">Usage Rate</span>
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Input
                              type="number"
                              name="usage_rate"
                              value={inputValue.usage_rate}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span> {formatAmount(profileData.usage_rate)}</span>
                    )}
                  </div>
                </TabPanel>
                {/* ++++++++++++++++++++++++++++++++++++++++++++++Meta Tag++++++++++++++++++++++++++++++++++++++++++++++++ */}

                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list form_list2">
                    <span className="detail meta_heading">Meta Tag</span>
                    {isEditing ? (
                      <Menu>
                        <FormLabel
                          fontSize="sm"
                          position="absolute"
                          top="-3"
                          left="3"
                          zIndex="1"
                          background="#ffffff"
                          px="1"
                        >
                          {/* Meta Tags */}
                        </FormLabel>

                        {/* Uncomment this section if you want to use a Select dropdown instead of InputGroup */}
                        {/* <Select name='metaTag' placeholder=' ' value={InfluencerData.metaTag} onChange={handleInfluencerDataChange}>
          {metaTags?.map((tag, index) => (
            <option key={index}>{tag}</option>
          ))}
        </Select> */}

                        {/* <InputGroup cursor={"pointer"} onClick={() => setActiveMetaTagDropdown(!activeMetaTagDropdown)}>
          <Input mb={2} type='text' sx={{ pointerEvents: "none" }} readOnly />
          <InputRightElement>
            <BiCaretDown w={3} h={3} color='gray.400' />
          </InputRightElement>
        </InputGroup> */}

                        {/* {activeMetaTagDropdown && ( */}
                        <Card>
                          <InputGroup>
                            <Input
                              type="text"
                              placeholder="Search Meta Tags"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                            />
                          </InputGroup>
                          {filteredMetaTags?.length > 0 && (
                            <Box
                              my={1}
                              maxH={"145px"}
                              overflowY={"auto"}
                              sx={
                                {
                                  /* ... your custom styles */
                                }
                              }
                            >
                              {filteredMetaTags?.map((tag, index) => (
                                <Text
                                  px={3}
                                  py={1}
                                  fontSize={"sm"}
                                  _hover={{ bg: "#EDF2F7", cursor: "pointer" }}
                                  key={index}
                                  onClick={() => handleMetaTagClick(tag)}
                                >
                                  {tag}
                                </Text>
                              ))}
                            </Box>
                          )}
                        </Card>
                        {/* // )} */}
                        {/* <br></br> */}
                        <div>
                          {selectedMetaTags?.map((metaTag, index) => (
                            <Tag
                              className="custom_meta"
                              key={index}
                              mr={2}
                              mb={2}
                              fontWeight={"400"}
                              size={"md"}
                              borderRadius="full"
                              variant="subtle"
                              colorScheme="purple"
                            >
                              <TagLabel className="custom_meta_text">
                                {metaTag}
                              </TagLabel>
                              <TagCloseButton
                                onClick={() => handleMetaTagClose(metaTag)}
                              />
                            </Tag>
                          ))}
                        </div>
                      </Menu>
                    ) : (
                      <>
                        {tags?.map((metaTag, index) => (
                          <Tag
                            className="custom_meta"
                            key={index}
                            mr={2}
                            mb={2}
                            fontWeight={"400"}
                            size={"md"}
                            borderRadius="full"
                            variant="subtle"
                            colorScheme="purple"
                          >
                            <TagLabel className="custom_meta_text">
                              {metaTag}
                            </TagLabel>
                            {/* <TagCloseButton onClick={() => handleMetaTagClose(metaTag)} /> */}
                          </Tag>
                        ))}
                      </>
                    )}
                  </div>
                </TabPanel>

                {/* <===============================================================================================> */}
                <TabPanel
                  className={`tab_deatil ${!isEditing ? "edit_tab" : ""}`}
                >
                  <div className="form_list">
                    {isEditing ? (
                      <Menu>
                        <Box>
                          <MenuGroup>
                            <Textarea
                              type="text"
                              name="notes"
                              value={inputValue.notes}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              autoFocus
                            />
                          </MenuGroup>
                        </Box>
                      </Menu>
                    ) : (
                      <span>{formatNotes(viewData.notes)}</span>
                    )}
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </SkeletonText>
      </Box>
    </>
  );
};

export default ProfileTable;
