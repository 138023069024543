import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { Toast } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/react";
import InboxModel from "../Modal/InboxModel";
import { MdAlignHorizontalLeft } from "react-icons/md";
import InboxEmailsModel from "../Modal/InboxEmailsModel";
import InboxCardModel from "../Modal/InboxCardModel";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import InboxAllEmails from "./InboxAllEmails";
import { useDispatch } from "react-redux";
import { setFunction } from "../../store/store";
import { IoSettings } from "react-icons/io5";

const InboxTable = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const toast = useToast();
  const { colorMode } = useColorMode();
  const [inbox, setInbox] = useState(false);
  const [talentMailStatus, setTalentMailStatus] = useState([]);
  const [isOpenInboxModel, setIsOpenInboxModel] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [isOpenInboxEmailsModel, setIsOpenInboxEmailsModel] = useState(false);
  const [isopenInboxCardModel, setIsopenInboxCardModel] = useState(false);
  const [showAllInbox, setShowAllInbox] = useState(false);
  const [allEmailsData, setAllEmailsData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [genralUnReadCount, setGenralUnReadCount] = useState(null);
  const [autoRespondStatus, setAutoRespondStatus] = useState(false);

  const navigate = useNavigate();
  const UserEmail = localStorage.getItem("userEmail");

  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  // Get the user's role from Redux or localStorage
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  // Find the path based on the user's role
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const handleInbox = async () => {
    try {
      const response = await fetch(BASE_URL + "/check-email-status/", {
        method: "POST",
        // body: JSON.stringify({ company_name: companyName }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.status === 401) {
        setInbox(true);
        setIsOpenInboxModel(true);
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        setInbox(false);
        setIsOpenInboxModel(false);
        await getTalentDetails();
        // handleGetEmail("", "unread");
        setIsopenInboxCardModel(true);
        return;
        // toast({
        //   title: "Show data",
        //   description: "Show data now.",
        //   status: "Success",
        //   position: "bottom",
        //   duration: 3000,
        //   isClosable: true,
        // });
      }
    } catch (error) {
      // toast({
      //   title: "Failed",
      //   description: "Failed to fetch prospect Deals.",
      //   status: "error",
      //   position: "bottom",
      //   duration: 3000,
      //   isClosable: true,
      // });

      setInbox(true);
      setIsOpenInboxModel(true);
      console.error("Error:", error);
    }
  };

  const getTalentDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-talent-mail-status/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setTalentMailStatus(data.data);
      setAutoRespondStatus(data?.auto_respond_status);
      setGenralUnReadCount(data.general_unread_count);

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleInbox();
  }, []);

  const handleSettingModel = () => {
    setIsOpenInboxEmailsModel(true);
  };

  const handleGetEmail = async (pagetoken = "", filter = "") => {
    setIsLoading(true);
    const email = UserEmail;
    try {
      const response = await fetch(
        `${BASE_URL}/get-general-mail/?page=1&page_size=10&filter_type=${filter}`,
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        setAllEmailsData(data);
        setIsLoading(false);

        // setShowAllInbox(true)
        // navigate(`${userRoute}/InboxAllEmails`,{ state: data })

        // Optional: Handle the data you received from the GET request
        // return;
        // toast({
        //   title: "Show data",
        //   description: "Show data now.",
        //   status: "success",
        //   position: "bottom",
        //   duration: 3000,
        //   isClosable: true,
        // });
      }
    } catch (error) {
      // toast({
      //   title: "Failed",
      //   description: "Failed to fetch general mail.",
      //   status: "error",
      //   position: "bottom",
      //   duration: 3000,
      //   isClosable: true,
      // });

      // setInbox(true);
      // setIsOpenInboxModel(true);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    localStorage.setItem("handleGetEmail", handleGetEmail);
    dispatch(setFunction(handleGetEmail));
    // handleGetEmail()
  }, []);

  return (
    <>
      {/* <IoSettings
          className="Icon-set"
          style={{
            position: "absolute",
            right: "0",
            fontSize: "1.5rem",
            marginRight: "20px",
            marginTop: "5px",
          }}
          onClick={handleSettingModel}
        /> */}
      {isopenInboxCardModel && (
        <InboxCardModel
          allEmailsData={allEmailsData}
          handleGetEmail={handleGetEmail}
          setIsOpenInboxModel={setIsOpenInboxModel}
          isLoading={isLoading}
          talentMailStatus={talentMailStatus}
          handleSettingModel={handleSettingModel}
          genralUnReadCount={genralUnReadCount}
        />
      )}
      {!isOpenInboxModel ? (
        <InboxEmailsModel
          isOpen={isOpenInboxEmailsModel}
          onClose={() => setIsOpenInboxEmailsModel(false)}
          autoRespondStatus={autoRespondStatus}
        />
      ) : (
        <InboxModel
          isOpen={isOpenInboxModel}
          onClose={() => setIsOpenInboxModel(false)}
          setShowSetting={setShowSetting}
        />
      )}
    </>
  );
};

export default InboxTable;
