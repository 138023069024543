import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  IconButton,
  Icon,
  useToast,
  Skeleton,
  InputLeftElement,
  Input,
  InputGroup,
  Flex,
  InputRightElement,
} from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { MdCheckCircle, MdDelete, MdHdrPlus } from "react-icons/md";
import { TemplateEditorModal } from "./TemplateEditorModal";
import { format } from "date-fns";
import { AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "../Table/datepicker.css";
import { IoDuplicate } from "react-icons/io5";

export const AddEmailModal = ({
  isOpen,
  onClose,
  sequenceId,
  addEmails,
  fetchSequence,
  // setTemplateEditorOpen,
  // setIsCreateNewTemplate,
  emailTemplates,
  fetchTemplates,
  selectedJob,
  setEmailTemplates,
}) => {
  // ..
  const [filterValue, setFilterValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [emailTemplate, setEmailTemplate] = useState();
  const [isTemplateEditorModalOpen, setIsTemplateEditorModalOpen] =
    useState(false);
  const [isCreateNewTemplate, setIsCreateNewTemplate] = useState(false);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const toast = useToast();
  const navigate = useNavigate();

  const handleAddJobs = async (templateId) => {
    const job_no = selectedJob ? selectedJob.job_no + 1 : 1;
    const payload = {
      template_id: templateId,
      sequence_id: sequenceId,
      type: "Automated Email",
      job_no: job_no,
    };
    try {
      const response = await fetch(BASE_URL + `/job/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        fetchSequence();
        toast({
          title: "job",
          description: "Successfully Added",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "job",
        description: "Failed to add",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // ************************
  const handleCopyJob = async (template) => {
    const payload = {
      name: `${template.name}(Copy)`,
      body: template.body,
      subject: template.subject,
    };
    try {
      const response = await fetch(BASE_URL + `/addtemplate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        // Add the new template to the emailTemplates state
        const newTemplate = {
          ...template,
          id: data.template_id,
          name: `${template.name}(Copy)`,
        };
        setEmailTemplates((prevTemplates) => [newTemplate, ...prevTemplates]);

        // If search is active, add the new template to the searchResults state
        if (filterValue) {
          setSearchResults((prevResults) => [newTemplate, ...prevResults]);
        }
        fetchTemplates();
        if (fetchSequence) {
          fetchSequence();
        }

        toast({
          title: "Template Copy",
          description: "Template Copy Successfully Added",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        // onClose();
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Template Copy",
        description: "Template Copy Failed to add",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // **************************

  const handleDeleteTemplate = async (templateId) => {
    const payload = {
      template_id: templateId,
    };
    try {
      const response = await fetch(BASE_URL + `/deletetemplate/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        const updatedTemplates = emailTemplates.filter(
          (template) => template.id !== templateId
        );
        setEmailTemplate(updatedTemplates);
        // fetchData()
        fetchTemplates();
        // Update searchResults state if applicable
        if (filterValue) {
          const updatedSearchResults = searchResults.filter(
            (template) => template.id !== templateId
          );
          setSearchResults(updatedSearchResults);
        }

        toast({
          title: "template",
          description: "Successfully Deleted",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Error",
        description: "Error",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const emailTemplatesList = () => {
    return (
      <>
        <Box borderColor="gray.200">
          {(filterValue ? searchResults : emailTemplates) &&
            (filterValue ? searchResults : emailTemplates).map(
              (template, index) => {
                return (
                  <Box
                    key={template.id}
                    mb={2}
                    p={3}
                    px={5}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderRadius={10}
                    boxShadow={"0 2px 4px rgba(0, 0, 0, 0.1)"}
                    _hover={{
                      boxShadow: "2px 4px 8px rgba(0, 128, 128, 0.4)",
                    }}
                  >
                    <Box>
                      <Text color={"teal.400"}>{template.name}</Text>
                      <Text fontSize={"sm"} color={"gray.400"}>
                        last updated:{" "}
                        {format(
                          new Date(template.updated_ts),
                          "MM/dd/yyyy HH:mm:ss"
                        )}
                      </Text>
                    </Box>
                    <Box display={"flex"} gap={2}>
                      <IconButton
                        borderRadius={"50%"}
                        onClick={(event) => handleAddJobs(template.id)}
                        size={"sm"}
                        icon={
                          <Icon
                            boxSize={6}
                            color={"green.500"}
                            as={AiFillPlusCircle}
                          />
                        }
                      />

                      <IconButton
                        borderRadius={"50%"}
                        title="Copy Template"
                        onClick={(event) => handleCopyJob(template)}
                        size={"sm"}
                        icon={
                          <Icon
                            boxSize={6}
                            color={"red.500"}
                            as={IoDuplicate}
                          />
                        }
                      />

                      <IconButton
                        borderRadius={"50%"}
                        onClick={(event) => handleDeleteTemplate(template.id)}
                        size={"sm"}
                        icon={
                          <Icon boxSize={6} color={"red.500"} as={MdDelete} />
                        }
                      />
                    </Box>
                  </Box>
                );
              }
            )}
        </Box>
      </>
    );
  };

  const handleCreateTemplateClick = () => {
    setIsTemplateEditorModalOpen(true);
    setIsCreateNewTemplate(true);
  };
  // ......search ....

  const fetchData = async (searchQuery) => {
    try {
      const response = await fetch(
        BASE_URL + `/alltemplate/?name=${searchQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to fetch data: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      setSearchResults(data["Email Templates"] || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTemplates();
  }, [emailTemplate]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setFilterValue(value);

    fetchData(value);
  };

  const clearSearch = () => {
    setFilterValue("");
    setSearchResults([]);
  };

  const handleOnClose = (e) => {
    onClose();
    clearSearch();
  };

  return (
    <>
      {/* <Modal size={'4xl'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text as={"b"}>Email Template</Text>
              <Text my={1}>
                Choose an existing template or create a new One.
              </Text>
              <Button colorScheme="teal" onClick={handleCreateTemplateClick}>
                Create template
              </Button>
            </Box>
            <Box my={2}>
              <Text as={"b"} color={"gray.200"}>
                Existing template
              </Text>
              {emailTemplatesList()}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal> */}

      {/* drawer */}
      <Drawer onClose={handleOnClose} isOpen={isOpen} size={"md"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader
            bgGradient={
              "linear-gradient(90deg, rgba(56,88,152,1) 60%, rgba(0,134,255,0.5) 100%)"
            }
            color={"white"}
          >
            Add automated email
          </DrawerHeader>
          <DrawerBody>
            <Box>
              <Text as={"b"}>Email Template</Text>
              <Text my={1}>
                Choose an existing template or create a new One.
              </Text>
              <div
                className="my-custom-class"
                style={{ display: "-webkit-box" }}
              >
                <Button colorScheme="gray" onClick={handleCreateTemplateClick}>
                  Create template
                </Button>

                <Box size={"sm"}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      placeholder="Search Templates..."
                      width={"200px"}
                      borderRadius={"100vh"}
                      value={filterValue}
                      onChange={handleInputChange}
                    />
                    {filterValue && (
                      <InputRightElement cursor="pointer">
                        <CloseIcon
                          w={3}
                          h={3}
                          color="gray.400"
                          onClick={clearSearch}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </Box>
              </div>
            </Box>

            <Box my={2}>
              <Text my={2} fontSize={"lg"} color={"gray.500"}>
                Existing template
              </Text>
              {emailTemplatesList()}
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <TemplateEditorModal
        isOpen={isTemplateEditorModalOpen}
        isCreateNewTemplate={isCreateNewTemplate}
        handleAddJobs={handleAddJobs}
        onClose={() => {
          setIsTemplateEditorModalOpen(false);
          setIsCreateNewTemplate(false);
        }}
      />
    </>
  );
};
