import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Select, Text, useToast } from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { PulseLoader } from 'react-spinners';
import { FaUserPlus } from 'react-icons/fa';
import { HiBuildingOffice } from 'react-icons/hi2';
import { IoImages } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';


const CompanyFormModal = ({ isOpen, onClose }) => {

    // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
    const token = localStorage.getItem('token');
    const navigate = useNavigate()

    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profilePictureURL, setProfilePictureURL] = useState("");
    const [companyData, setCompanyData] = useState({
        companyLogo: "",
        companyName: "",
        companyDomain: "",
        companyLinkedin: "",
        companyIndustry: "",
        location: "",
        companySpecialties: ""
    });
    const [errors, setErrors] = useState({
        companyLogo: "",
        companyName: "",
        companyDomain: "",
        companyLinkedin: "",
        companyIndustry: "",
        location: "",
        companySpecialties: ""
    })

    const imageInputRef = useRef(null);

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
        imageInputRef.current.value = null;
    }

    const handleUploadProfilePicture = () => {
        const formData = new FormData();
        formData.append("file", selectedImage);
        setIsFileLoading(true);

        fetch(BASE_URL + '/profile/', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            if (response.status === 401) {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token");
                navigate('/login');
            }
            return response.json();
        })
        .then(data => {
            setIsFileLoading(false);
            setProfilePictureURL(data?.profile_url);
            setSelectedImage("");

            if(data.profile_url) {
                toast({
                    title: 'Profile picture has been uploaded.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
            else {
                toast({
                    title: 'Failed to upload profile picture.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        })
        .catch(error => {
            setIsFileLoading(false);

            toast({
                title: 'Failed to upload profile picture.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })

            console.error('Error:', error);
        })
    }

    const handleCompanyDataChange = (e) => {
        const { name, value } = e.target;
        setCompanyData(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: "" }));
    }

    const handleSubmit = () => {
        if (!companyData.companyName) {
            setErrors(prev => ({ ...prev, companyName: "Comapny Name is required." }));
            return;
        }

        if (!companyData.companyDomain) {
            setErrors(prev => ({ ...prev, companyDomain: "Company domain is required." }));
            return;
        }

        // if (!companyData.companyLinkedin) {
        //     setErrors(prev => ({ ...prev, companyLinkedin: "Linkedin is required." }));
        //     return;
        // }

        // if (!companyData.companyIndustry) {
        //     setErrors(prev => ({ ...prev, companyIndustry: "Industry is required." }));
        //     return;
        // }

        // if (!companyData.companySpecialties) {
        //     setErrors(prev => ({ ...prev, companySpecialties: "Specialities is required." }));
        //     return;
        // }
        // if (!companyData.location) {
        //     setErrors(prev => ({ ...prev, location: "Loctaion is required." }));
        //     return;
        // }

      
        setIsLoading(true);

        const CompanyPayload = {
            company_logo: companyData.companyLogo,
            // company_logo: profilePictureURL,
            company_name: companyData.companyName,
            company_domain: companyData.companyDomain,
            company_linkedin_url: companyData.companyLinkedin,
            company_industry: companyData.companyIndustry,
            location: companyData.location,
            company_specialties: companyData.companySpecialties
        }

        fetch(BASE_URL + '/addcompany/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                
            },
            body: JSON.stringify(CompanyPayload)
        })
        .then(response => {
            if (response.status === 401) {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token");
                navigate('/login');
            }
            return response.json();
        })
        .then(data => {
            setIsLoading(false);
    
            if(data.message) {
                toast({
                    title: 'Company created successfully',
                    description: data.message,
                    status: 'success',
                    position: 'bottom',
                    duration: 3000,
                    isClosable: true,
                })
    
                setTimeout(() => {
                    setCompanyData({
                        companyLogo: "",
                        companyName: "",
                        companyDomain: "",
                        companyLinkedin: "",
                        companyIndustry: "",
                        location: "",
                        companySpecialties: ""
                    })
                    setProfilePictureURL("");
    
                    onClose();
                }, 3000);
            }
            else {
                toast({
                    title: 'Company creation Failed',
                    description: 'Failed to Company creation',
                    status: 'error',
                    position: 'bottom',
                    duration: 3000,
                    isClosable: true,
                })
            }
        })
        .catch(error => {
            setIsLoading(false);
    
            toast({
                title: 'Company creation Failed',
                description: "Failed to Company creation",
                status: 'error',
                position: 'bottom',
                duration: 3000,
                isClosable: true,
            })
    
            console.error('Error:', error);
        })
    }

    return (
        <Box>
            <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent height={{ base: "100vh", md: "auto" }} >
                    <ModalHeader fontWeight={"500"} fontSize={"2xl"}>
                        <Flex alignItems={"center"} gap={2}>
                            <HiBuildingOffice size={28} />
                            New Company
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody className='companyModal_body'>
                        <Box>
                            {/* //uploder */}
                        {/* <Flex gap={5} mb={6}>
                                <FormControl maxW={"50%"} border={"2px dashed blue"} borderRadius={"10px"} p={"30px 100px"}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Company Logo</FormLabel>
                                    <Flex flexDirection={"column"} alignItems={"center"} color={"#6B6F7A"}>
                                        <IoImages size={25} />
                                        <Text fontSize={"sm"} mt={"3px"}>
                                            <Link textDecoration={"underline"} color={"blue"} onClick={() => imageInputRef.current.click()}>Click to upload</Link> an image
                                        </Text>
                                        {
                                            selectedImage &&
                                            <>
                                                <Flex alignItems={"center"} justifyContent={"center"}>
                                                    <Text>{selectedImage?.name}</Text>
                                                    
                                                </Flex>
                                                <Button isLoading={isFileLoading} mt={3} variant='outline' colorScheme='green' size={"sm"} onClick={handleUploadProfilePicture}>Upload</Button>
                                            </>
                                        }
                                        <Input type='file' name='profilePicture' style={{ display: 'none' }} onChange={handleImageChange} ref={imageInputRef} accept='image/*' />
                                    </Flex>
                                </FormControl>
                                {
                                    profilePictureURL &&
                                    <Box position={"relative"}>
                                        <Image src={profilePictureURL} alt='image' style={{ height: "110px", width: "110px", borderRadius: "10px", objectFit: 'cover' }} />
                                    </Box>
                                }
                            </Flex> */}
                            <Flex gap={5} mb={4}>
                                {/* <FormControl isRequired> */}
                                    {/* <FormLabel>Company Logo</FormLabel> */}
                                    {/* <Input name='companyLogo' type='text' placeholder='Company Logo' value={companyData.companyLogo} onChange={handleCompanyDataChange} /> */}
                                    {/* <FormErrorMessage>{errors.firstName}</FormErrorMessage> */}
                                {/* </FormControl> */}
                                <FormControl isRequired isInvalid={!!errors.companyName}>
                                    <FormLabel>Company Name</FormLabel>
                                    <Input name='companyName' type='text' placeholder='Company Name'  value={companyData.companyName} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.companyName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={!!errors.companyDomain}>
                                    <FormLabel>Company Domain</FormLabel>
                                    <Input name='companyDomain' type='text' placeholder='Company Domain' value={companyData.companyDomain} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.companyDomain}</FormErrorMessage>
                                </FormControl>
                                
                            </Flex>
                            <Flex gap={5} mb={4}>
                            <FormControl  isInvalid={!!errors.profile_picture && !!errors.profile_picture_uploaded} isDisabled={selectedImage}>
                            <FormLabel>Logo URL</FormLabel>
                                  <Input name="companyLogo" type="text" placeholder='Logo URL' value={companyData.companyLogo} onChange={handleCompanyDataChange}/>
                                  <FormErrorMessage>{errors.profile_picture}</FormErrorMessage>
                                </FormControl>
                                </Flex>
                            <Flex gap={5} mb={4}>
                                <FormControl isInvalid={!!errors.companyLinkedin}>
                                    <FormLabel>Company Linkedin</FormLabel>
                                    <Input name='companyLinkedin' type='email' placeholder='Company Linkedin' value={companyData.companyLinkedin} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.companyLinkedin}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.companyIndustry}>
                                    <FormLabel>Company Industry</FormLabel>
                                    <Input name='companyIndustry' type='text' placeholder='Company Industry' value={companyData.companyIndustry} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.companyIndustry}</FormErrorMessage>
                                </FormControl>
                                
                            </Flex>
                            <Flex gap={5} mb={4}>
                            <FormControl isInvalid={!!errors.location}>
                                    <FormLabel>Location</FormLabel>
                                    <Input name='location' type='text' placeholder='Location' value={companyData.location} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.location}</FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!!errors.companySpecialties}>
                                    <FormLabel>Company Specialties</FormLabel>
                                    <Input name='companySpecialties' type='text' placeholder='Company Specialties' value={companyData.companySpecialties} onChange={handleCompanyDataChange} />
                                    <FormErrorMessage>{errors.companySpecialties}</FormErrorMessage>
                                </FormControl>
                                
                            </Flex>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button isLoading={isLoading} spinner={<PulseLoader size={8} color='white' />} loadingText="Submitting" spinnerPlacement='end' colorScheme='green' ml={"auto"} onClick={handleSubmit}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default CompanyFormModal