const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL; // Replace with your API's base URL
const token = localStorage.getItem("token");

export const createCampaign = async (payload, token) => {
  try {
    const response = await fetch(`${BASE_URL}/create_campaigns/`, {
      //http://127.0.0.1:8000/create_campaigns/
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed response not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
