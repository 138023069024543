import React, { useRef } from "react";

import EmailEditor from "react-email-editor";

export const ReactEmailEditor = () => {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      alert("Output HTML has been logged in your developer console.");
    });
  };

  const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      alert("Design JSON has been logged in your developer console.");
    });
  };

  const onDesignLoad = (data) => {};

  const onLoad = () => {
    emailEditorRef.current.editor.addEventListener(
      "onDesignLoad",
      onDesignLoad
    );
    emailEditorRef.current.editor.loadDesign(); //sample
  };
  const onready = () => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  return (
    <div className="container">
      <div>
        <button onClick={saveDesign}>Save Design</button>
        <button onClick={exportHtml}>Export HTML</button>
      </div>

      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        minHeight="70vh"
        maxWidth="600px"
        style={{
          maxWidth: "80%",
        }}
      />
    </div>
  );
};
