import React, { useEffect, useMemo, useState } from "react";
import { Past_Preview_COLUMNS } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import Checkbox from "./Checkbox";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import PreviousModal from "../Modal/PreviousModal";
import "./PastPreview.css";
import { IoMdTrash, IoIosArrowBack } from "react-icons/io";
import PreviousDeleteDialogBox from "../Modal/PreviousDeleteDialogBox";

const Past_Preview = ({ flag, setFlag }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const [Past, setPast] = useState(false);

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const columns = useMemo(() => Past_Preview_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [ModalResponse, setModalResponse] = useState(null);
  const [FileName, setFileName] = useState("");
  const [isOpenPreviousModal, setIsOpenPreviousModal] = useState(false);
  const [isOpenPreviousDeleteDialogBox, setIsOpenPreviousDeleteDialogBox] =
    useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: usersData || [],
      initialState: { pageIndex: 0, pageSize: 5 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const handleRowCellClick = (row, cell) => {
    if (cell.column.id == "file_name") {
      setModalResponse(row.original.response);
      setFileName(row.original.file_name);
      setIsOpenPreviousModal(true);
    }
  };

  useEffect(() => {}, [usersData, columns]);

  const fetchPreviousData = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/get-reviews/?page=${pageIndex + 1}&page_size=${pageSize}`,
        {
          method: "GET",
          // body: formData,
          headers: {
            Authorization: `Bearer ${token}`, // If you need to pass a token
          },
        }
      );
      const data = await response.json();

      if (data) {
        setUsersData(data.results);
        setPageCount(data?.total_pages);

        // toast({
        //   title: "File uploaded",
        //   description: "Your file has been uploaded successfully.",
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        // });
      } else {
        // toast({
        //   title: "Upload failed",
        //   description: "There was an error uploading your file.",
        //   status: "error",
        //   duration: 3000,
        //   isClosable: true,
        // });
      }
    } catch (error) {
      toast({
        title: "Upload failed",
        description: "There was an error uploading your file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPreviousData();
  }, [flag, pageIndex, pageCount]);

  useEffect(() => {}, [usersData, columns]);

  const handleDeleteRows = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      const ReviewsId = selectedFlatRows.map(
        (row) => row?.original?.reviews_id
      );
      const response = await fetch(BASE_URL + "/reviews/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ reviews_id: ReviewsId }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (response.status === 200) {
        setUsersData((prevData) => {
          const updatedData = prevData.filter(
            (review) => !ReviewsId.includes(review.reviews_id)
          );

          return updatedData;
        });
        fetchPreviousData();
        toast({
          title: "Deletion Success",
          description: "Deal deleted successfully",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete deals.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "An error occurred while deleting deals.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      setIsOpenPreviousDeleteDialogBox(false);
    }
  };

  return (
    <>
      <Box px={5} py={3} className="past_prev_table_padding">
        <SkeletonText
          noOfLines={20}
          spacing="2"
          skeletonHeight="6"
          isLoaded={!isLoading}
        >
          {selectedFlatRows.length > 0 ? (
            <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
              <Box>{selectedFlatRows.length} selected</Box>
              <Button
                onClick={() => setIsOpenPreviousDeleteDialogBox(true)}
                size={"sm"}
                colorScheme="red"
                variant="solid"
              >
                <IoMdTrash size={20} color="white" />
              </Button>
            </Flex>
          ) : (
            <Box textAlign="right" mb={3}></Box>
          )}
          <Box
            fontSize={14}
            mb={1}
            boxShadow="xs"
            h={"calc(100vh - 180px)"}
            w={"calc(100vw - 290px)"}
            rounded={"md"}
            sx={{
              position: "relative",
            }}
          >
            <TableContainer
              {...getTableProps()}
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              overflowY="scroll"
              sx={{
                "&::-webkit-scrollbar": { height: "10px", width: "0px" },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#3182ce",
                  borderRadius: "10px",
                },
              }}
            >
              <Table variant="simple" width={"100%"}>
                <Thead bg={"green.400"} className="fix-header">
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                          <Flex alignItems={"center"}>
                            {column.render("Header")}
                            <Box>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <HiArrowDown />
                                ) : (
                                  <HiArrowUp />
                                )
                              ) : (
                                ""
                              )}
                            </Box>
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <Tr
                        {...row.getRowProps()}
                        _hover={{
                          bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                        }}
                      >
                        {row.cells.map((cell) => (
                          <Td
                            {...cell.getCellProps()}
                            onClick={() => handleRowCellClick(row, cell)}
                          >
                            {cell.column.id === "created_at" ? (
                              format(new Date(cell.value), "MM/dd/yyyy HH:mm a")
                            ) : cell.column.id === "file_name" ? (
                              <a className="anchers">{cell.render("Cell")}</a>
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Flex alignItems={"center"} justifyContent={"space-between"} className="past_prev_page_cont">
            <Flex>
              Page&nbsp;
              <Text fontWeight={500}>
                {pageIndex + 1} of {pageCount}
              </Text>
            </Flex>
            <Flex alignItems={"center"} gap={3}>
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronLeft
                  size={24}
                  onClick={() => previousPage()}
                  disabled={pageIndex === 0}
                />
              </Box>
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronRight
                  size={24}
                  onClick={() => nextPage()}
                  disabled={pageIndex === pageCount - 1}
                />
              </Box>
            </Flex>
          </Flex>
        </SkeletonText>
      </Box>
      <PreviousModal
        isOpen={isOpenPreviousModal}
        onClose={() => setIsOpenPreviousModal(false)}
        ModalResponse={ModalResponse}
        FileName={FileName}
      />

      <PreviousDeleteDialogBox
        isOpen={isOpenPreviousDeleteDialogBox}
        onClose={() => {
          setIsOpenPreviousDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />
    </>
  );
};

export default Past_Preview;
