import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { AiOutlineLogin } from "react-icons/ai";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { PulseLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { setRole } from "../store/store";
import { setUserImg } from "../store/store";
// import { useToast } from '@chakra-ui/react';
import { setUserEmail } from "../store/store";
import { setToken } from "../store/store";
import { useNotification } from "../services/NotificationContext";

const LoginPage = () => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const { handleEmailRead } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleLoginDataChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = () => {
    if (!loginData.email) {
      setErrors((prev) => ({ ...prev, email: "Email is required." }));
      return;
    }

    if (!loginData.password) {
      setErrors((prev) => ({ ...prev, password: "Password is required." }));
      return;
    }

    setIsLoading(true);

    fetch(BASE_URL + "/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);

        if (data.status === true) {
          localStorage.setItem("userEmail", data.email);
          localStorage.setItem("token", data.token);
          localStorage.setItem("userName", data.first_name);
          //create dymainc after api update
          // localStorage.setItem("UserFullName",)
          // localStorage.setItem("userImg",data.profile_picture || null)
          localStorage.setItem("userImg", data.profile_picture || null);
          localStorage.setItem("Role", data.account_type);
          handleEmailRead();
          dispatch(setUserImg(data.profile_picture || null));
          dispatch(setUserEmail(data.email));
          dispatch(setToken(data.token)); // Store the token
          window.location.reload();
          // navigate("/admin/dashboard");
          const userRole = data.account_type;
          let redirectPath;

          switch (userRole) {
            case "Admin":
              redirectPath = "/admin/dashboard";
              toast({
                title: "Admin",
                description: "Admin logIn",
                status: "success",
                isClosable: true,
                duration: "3000",
              });
              break;
            case "Creator Manager":
              redirectPath = "/creator/dashboard";
              toast({
                title: "Creator",
                description: "Creator logIn",
                status: "success",
                isClosable: true,
                duration: "3000",
              });
              break;
            case "Influencer":
              redirectPath = "/influencer/dashboard";
              toast({
                title: "Influencer",
                description: "Influencer logIn",
                status: "success",
                isClosable: true,
                duration: "3000",
              });
              break;
            default:
              redirectPath = "/login";
          }
          dispatch(setRole(data.account_type));
          navigate(redirectPath, { state: { role: data.account_type } });
          // window.location.reload()
        } else {
          toast({
            title: "Login Error",
            description: "Failed to login",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        toast({
          title: "Login Error",
          description: "Failed to login",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      });
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Flex height={"100vh"} alignItems={"center"} justifyContent={"center"}>
      <Card maxW="md" borderTop={"3px solid #ffa500"} position={"relative"}>
        {/* <Image position={"absolute"} top={"-50px"} right={"-50px"} boxShadow={"0px 0px 10px #A0AEC0"} borderRadius={"full"} h={"100px"} w={"100px"} src='https://aiad.s3.amazonaws.com/images/bbb6f242-8a07-451f-8909-f04b78eaa29f.jpg' alt='Creative Matters' /> */}
        <CardBody>
          <Stack spacing="3">
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Heading
                display={"flex"}
                alignItems={"center"}
                gap={"5px"}
                size={"md"}
                color={"#00AA45"}
              >
                <AiOutlineLogin />
                Login to Creatives Matter
              </Heading>
              <Image
                boxShadow={"0px 0px 10px #A0AEC0"}
                borderRadius={"full"}
                h={"100px"}
                w={"100px"}
                src="https://aiad.s3.amazonaws.com/images/bbb6f242-8a07-451f-8909-f04b78eaa29f.jpg"
                alt="Creative Matters"
              />
            </Flex>
            <FormControl isRequired isInvalid={!!errors.email}>
              <FormLabel>Email address</FormLabel>
              <Input
                height={"45px"}
                width={"400px"}
                type="email"
                name="email"
                value={loginData.email}
                onChange={handleLoginDataChange}
                placeholder="Your email address"
                onKeyDown={handleEnterPress}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  height={"45px"}
                  width={"400px"}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={loginData.password}
                  onChange={handleLoginDataChange}
                  placeholder="Enter password"
                  onKeyDown={handleEnterPress}
                />
                <InputRightElement height={"45px"}>
                  <Button
                    size="sm"
                    bg={"none"}
                    _hover={{ bg: "none" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VscEyeClosed size={24} />
                    ) : (
                      <VscEye size={24} />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password}</FormErrorMessage>
            </FormControl>
            {/* <Link><Text textAlign={"right"} textDecoration={"underline"}>Forgot your password?</Text></Link> */}
          </Stack>
        </CardBody>
        <CardFooter>
          <Button
            isLoading={isLoading}
            spinner={<PulseLoader size={8} color="white" />}
            display={"flex"}
            alignItems={"center"}
            justifyContent={isLoading ? "center" : "space-between"}
            width={"100%"}
            _hover={{ bg: "#00AA45" }}
            color={"#ffffff"}
            bg={"#00AA45"}
            onClick={handleSubmit}
            rightIcon={<FiLogIn />}
          >
            Log In
          </Button>
        </CardFooter>
      </Card>
    </Flex>
  );
};

export default LoginPage;
