import React, { useState, createRef, forwardRef } from "react";
import logo from "./logo.png";
import { useEffect } from "react";
import "../Table/Invoice.css";
import Pdf from "./Pdf";
import { useRef } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { ToastProvider, useToast } from "@chakra-ui/react";
import { Tag } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
// import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Flex,
  InputGroup,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

// const formatCurrency = (amount) => {
//   return new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD'
//   }).format(amount);
// };

const formatCurrency = (amount) => {
  if (amount == null || isNaN(amount)) {
    return "$0.00";
  }
  const newValue = parseFloat(amount).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return newValue;
};

const Invoice = ({
  setPrintButton,
  isOpen,
  onClose,
  brandForInvoice,
  DealAmountForInvoice,
  BrandRepForInvoice,
  tal_name,
  DeliverablsForInvoice,
  LinkForInvoice,
  LoctionForInvoice,
  MailForInvoice,
  InvoiceForInvoice,
  DealIdForInvoice,
  onDateprops,
  PaymentTermForInvoice,
  fetchinfluencerDealsData,
  fetchAllInfluencerDealsData,
  setActiveDealsTab,
  activeDealsTab,
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const [show, setShow] = useState(false);

  const [CustomerDetails, setCustomerDetails] = useState(brandForInvoice);
  const [Contact, setContact] = useState(BrandRepForInvoice);
  const [Amount, setAmount] = useState(formatCurrency(DealAmountForInvoice));
  const [CreatorName, setCreatorName] = useState(tal_name.replace(/-/g, " "));
  const [Deliverables, setDeliverables] = useState([DeliverablsForInvoice]);

  const [Linkss, setLink] = useState([LinkForInvoice]);
  const [url, setUrl] = useState(null);

  const [location, setLocation] = useState(LoctionForInvoice);
  const [InvoiceNumber, setInvoiceNumber] = useState(); // InvoiceForInvoice
  const [PassDate, setPassDate] = useState(null);
  const [payment_term, setpayment_term] = useState(PaymentTermForInvoice);
  const [Mail, setMail] = useState(MailForInvoice);
  const [showDeliverable, setShowDeliverable] = useState([]);

  const [currentDate, setCurrentDate] = useState("");
  const toast = useToast();

  useEffect(() => {
    setDeliverables([DeliverablsForInvoice]);
    setAmount(formatCurrency(DealAmountForInvoice));
    setCustomerDetails(brandForInvoice);
    setContact(BrandRepForInvoice);
    setCreatorName(tal_name.replace(/-/g, " "));
    setLink([LinkForInvoice]);
    setLocation(LoctionForInvoice);
    setpayment_term(PaymentTermForInvoice);
    setMail(MailForInvoice);
  }, [
    DeliverablsForInvoice,
    DealAmountForInvoice,
    brandForInvoice,
    BrandRepForInvoice,
    tal_name,
    LinkForInvoice,
    LoctionForInvoice,
    PaymentTermForInvoice,
    MailForInvoice,
  ]);

  useEffect(() => {
    setDeliverables([DeliverablsForInvoice]);
    setAmount(formatCurrency(DealAmountForInvoice));
    setCustomerDetails(brandForInvoice);
    setContact(BrandRepForInvoice);
    setCreatorName(tal_name.replace(/-/g, " "));
    setLink([LinkForInvoice]);
    setLocation(LoctionForInvoice);
    setpayment_term(PaymentTermForInvoice);
    setMail(MailForInvoice);
  }, [isOpen]);

  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString("en-US"); // Format: MM/DD/YYYY
    setCurrentDate(formattedDate);
  }, []);

  const pdfContentRef = useRef(null);

  const componentRef = createRef();

  const handleCustomerDetails = (e) => {
    setCustomerDetails(e.target.value);
  };

  const handleContact = (e) => {
    setContact(e.target.value);
  };

  const handleAmount = (e) => {
    const value = e.target.value;

    setAmount(value);
  };

  const handleCreatorName = (e) => {
    setCreatorName(e.target.value);
  };

  const handleDeliverable = (e) => {
    setDeliverables(e.target.value);
  };

  const handleLink = (e) => {
    setLink(e.target.value);
  };

  const HandleCompanyAddress = (e) => {
    setLocation(e.target.value);
  };

  const HandleMailAddress = (e) => {
    setMail(e.target.value);
  };

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value);
  };

  const convertHtmlToPdfAndSend = async () => {
    // Reference to the viewport meta tag
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    const originalContent = viewportMetaTag
      ? viewportMetaTag.getAttribute("content")
      : null;
    const removeViewportContent = () => {
      if (viewportMetaTag) {
        viewportMetaTag.setAttribute("content", "");
      }
    };

    const restoreViewportContent = () => {
      if (viewportMetaTag && originalContent !== null) {
        viewportMetaTag.setAttribute("content", originalContent);
      }
    };
    // Function to find all placeholders like LiveLink1, LiveLink2, etc.
    const findPlaceholders = () => {
      const placeholders = [];

      let index = 1;

      let placeholder = document.getElementById(`LiveLink${index}`);

      while (placeholder !== null) {
        placeholder = document.getElementById(`LiveLink${index}`);

        placeholders.push(placeholder);
        index++;
      }
      return placeholders;
    };

    // Function to replace placeholders with actual links
    const replacePlaceholdersWithLinks = (placeholders, links) => {
      placeholders.forEach((placeholder, index) => {
        if (index < links.length) {
          placeholder.innerHTML = `<a href="${links[index].url}">${links[index].text}</a>`;
        }
      });
    };

    const input = pdfContentRef.current;
    input.classList.add("html2canvas");
    input.classList.add("link-none");
    removeViewportContent();

    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const adjustViewportForPdf = () => {
      if (viewportMetaTag) {
        // Adjust viewport for iOS devices
        viewportMetaTag.setAttribute(
          "content",
          "width=device-width, initial-scale=0.25"
        );
      }
    };

    // Before generating the PDF, adjust the viewport for iOS devices
    if (isIOS) {
      adjustViewportForPdf();
    } else {
      removeViewportContent();
    }

    // Extract and replace placeholders with actual links
    const placeholders = findPlaceholders();

    // Extract all anchor tags from the content
    const links = Array.from(input.querySelectorAll("a")).map((link) => {
      return {
        url: link.href,
        text: link.textContent,
        rect: link.getBoundingClientRect(), // Use getBoundingClientRect for accurate position
      };
    });

    replacePlaceholdersWithLinks(placeholders, links);

    const linkss = input.querySelectorAll("a");
    linkss.forEach((link) => {
      link.classList.add("Links-Invoice");
    });

    //code for replacing payment

    // Get the input element by its ID
    var inputElement = document.getElementById("payment_terms_input");

    if (inputElement) {
      // Create the new div element
      var newDivElement = document.createElement("div");

      // Create the new p element
      var newParagraphElement = document.createElement("p");

      // Set the text of the p element
      newParagraphElement.textContent = payment_term;

      // Append the p element to the div
      newDivElement.appendChild(newParagraphElement);

      // Set styles for the div element
      newDivElement.style.textAlign = "left";
      newDivElement.style.wordWrap = "break-word";
      newDivElement.style.paddingLeft = "10px";
      newDivElement.style.marginTop = "5px"; // Adjust the padding value as needed

      // Replace the input element with the new div
      inputElement.parentNode.replaceChild(newDivElement, inputElement);
    }

    /////////////////////////////////

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // Get the input element by its ID for company address
    var companyAddressInputElement = document.getElementById(
      "Comapny_address_input"
    );

    if (companyAddressInputElement) {
      // Create the new div element for company address
      var newCompanyAddressDivElement = document.createElement("div");

      // Create the new p element for company address
      var newCompanyAddressParagraphElement = document.createElement("p");

      // Set the text of the p element
      newCompanyAddressParagraphElement.textContent = location; // Set the appropriate variable for the company address text
      // Replace newline characters with <br> tags
      newCompanyAddressParagraphElement.innerHTML = location.replace(
        /\n/g,
        "<br>"
      );
      // Append the p element to the div
      newCompanyAddressDivElement.appendChild(
        newCompanyAddressParagraphElement
      );

      // Set styles for the div element
      newCompanyAddressDivElement.style.textAlign = "left";
      newCompanyAddressDivElement.style.wordWrap = "break-word";
      newCompanyAddressDivElement.style.paddingLeft = "10px";
      // newCompanyAddressDivElement.style.marginTop = "5px"; // Adjust the padding value as needed

      // Replace the input element with the new div
      companyAddressInputElement.parentNode.replaceChild(
        newCompanyAddressDivElement,
        companyAddressInputElement
      );
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // Get the input element by its ID
    var customerDetailsInputElement = document.getElementById(
      "Customer_Details_input"
    );

    if (customerDetailsInputElement) {
      // Create the new div element for customer details
      var newCustomerDetailsDivElement = document.createElement("div");

      // Create the new p element for customer details
      var newCustomerDetailsParagraphElement = document.createElement("p");

      // Set the text of the p element
      newCustomerDetailsParagraphElement.innerHTML = CustomerDetails.replace(
        /\n/g,
        "<br>"
      ); // Replace newline characters with <br> tags

      // Append the p element to the div
      newCustomerDetailsDivElement.appendChild(
        newCustomerDetailsParagraphElement
      );

      // Set styles for the div element
      newCustomerDetailsDivElement.style.textAlign = "left";
      newCustomerDetailsDivElement.style.wordWrap = "break-word";
      newCustomerDetailsDivElement.style.paddingLeft = "10px";
      // newCustomerDetailsDivElement.style.marginTop = "25px"; // Adjust the margin value as needed

      // Replace the input element with the new div
      customerDetailsInputElement.parentNode.replaceChild(
        newCustomerDetailsDivElement,
        customerDetailsInputElement
      );
    }

    ////////////////////////////////////////////////

    // Generate the canvas
    const canvas = await html2canvas(input, {
      scale: isIOS ? 3 : 2, // Higher scale for iOS devices
      useCORS: true,
      allowTaint: true,
      // width: input.scrollWidth,
      height: input.scrollHeight * 1.9, // Increase height slightly
    });

    const imgData = canvas.toDataURL("image/png", 0.7);
    const pdf = new jsPDF();

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    // Add image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, "", "FAST");
    linkss.forEach((link) => link.classList.remove("Links-Invoice"));
    // Offsets to move links down and to the left
    const offsetX = -23; // Move links 5 units to the left
    const offsetY = isIOS ? -204 : -136; // Move links 5 units down

    // Calculate the total height of all links including gaps
    const offset = 10; // Adjust this value as needed to move the link down
    const gapBetweenLinks = 6; // Adjust this value to set the gap between links
    const totalLinksHeight = links.reduce((total, link) => {
      return (
        total + (link.rect.height / canvas.height) * imgHeight + gapBetweenLinks
      );
    }, 0);

    // Calculate the starting Y position to center all links vertically
    let customOffset = 0;
    if (links.length === 2) {
      customOffset = 8;
    } else if (links.length === 1) {
      customOffset = 18;
    } else if (links.length === 4) {
      customOffset = -8;
    }
    const startY = imgHeight / 2 + totalLinksHeight;
    // Add each link to the PDF
    links.forEach((link, index) => {
      const linkWidth = (link.rect.width / canvas.width) * imgWidth;
      const linkHeight = (link.rect.height / canvas.height) * imgHeight;
      const pdfX = (imgWidth - linkWidth) / 2 + offsetX; // Center the link horizontally
      const pdfY =
        startY +
        offsetY -
        (links.length - index) *
          (gapBetweenLinks +
            1 +
            (link.rect.height / canvas.height) * imgHeight) +
        customOffset; // Calculate vertical position

      const pdfWidth = linkWidth;
      const pdfHeight = (link.rect.height / canvas.height) * imgHeight;

      // Calculate the width of the text
      const textWidth =
        pdf.getStringUnitWidth(link.text) * pdf.internal.getFontSize();

      // Ensure that the clickable area matches the text width
      const clickableWidth = Math.max(linkWidth, textWidth) - 48; // Ensure clickable width is at least the width of the text
      const clickableHeight = linkHeight + 2.5; // Use the height calculated for the link

      // Debug output for link positions

      // Add link to PDF
      pdf.link(pdfX, pdfY, clickableWidth, clickableHeight, { url: link.url });

      // Optionally, you can add some text near the link for visual representation
      const textX = pdfX;
      const textY = pdfY;

      // Set text color to Steel Blue
      pdf.setTextColor(70, 130, 180); // Steel Blue color

      // pdf.text(link.text, textX, textY); // Add text

      // Draw underline with margin
      //  pdf.setDrawColor(70, 130, 180); // Steel Blue color
      //  pdf.setDrawColor(255, 0, 0); // Red color for debugging

      // pdf.rect(pdfX, pdfY, clickableWidth, clickableHeight, 'S');
    });

    input.classList.remove("html2canvas");
    restoreViewportContent();

    input.classList.remove("link-none");
    // Save the PDF
    //  pdf.save('download.pdf');

    // Restore viewport content before sending the request
    // restoreViewportContent();

    // Prepare for form submission
    const pdfBlob = pdf.output("blob");

    let data = new FormData();
    data.append(
      "pdf_path",
      pdfBlob,
      `${brandForInvoice} ${tal_name}-Invoice${InvoiceNumber}.pdf`
    );
    data.append("company", brandForInvoice);
    data.append("brand_rep", BrandRepForInvoice);
    data.append("deal_id", DealIdForInvoice);

    for (const [key, value] of data.entries()) {
    }

    // restoreViewportContent();

    // Post request configuration
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BASE_URL}/send-invoice/`,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
        Authorization: `Bearer ${token}`,
        Connection: "keep-alive",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      const datas = response.data;

      if (datas && response.status === 200) {
        setPassDate(currentDate);
        onDateprops(currentDate);

        toast({
          title: "Successful",
          description: "Invoice Sent Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Clear form data
        setDeliverables([]);
        setAmount();
        setCustomerDetails();
        setContact();
        setCreatorName();
        setLink([]);
        setLocation();
        setpayment_term();
        setMail();
        onClose();

        if (activeDealsTab) {
          fetchAllInfluencerDealsData();
          setActiveDealsTab(false);
        } else {
          fetchinfluencerDealsData();
        }
      } else {
        toast({
          title: "Sending Pdf Failed",
          description: "Failed to Send Pdf.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to Send Mail",
        duration: 3000,
        status: "error",
        position: "bottom",
        isClosable: true,
      });
      console.error("Error sending PDF:", error);
    }
    //  finally {
    //   // // Cleanup
    //   //     // Remove the class from all links
    //   // input.classList.remove('link-none')
    //   // restoreViewportContent();
    // }
  };

  const handleInvoiceNumber = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handlePaymentTerm = (e) => {
    setpayment_term(e.target.value);
  };

  const getInvoiceNumber = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/generate-invoice-number/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setInvoiceNumber(response.data.invoice_number);
      }
    } catch (error) {
      console.error("Error fetching invoice number:", error.message || error);
    }
  };

  useEffect(() => {
    getInvoiceNumber();
  }, []);

  useEffect(() => {
    const myUrl = Linkss && Linkss[0]?.split(",");

    setUrl(myUrl);
  }, [Linkss]);

  useEffect(() => {
    const Delivers = Deliverables && Deliverables[0]?.split("\n");

    setShowDeliverable(Delivers);
  }, [Deliverables]);

  const handleReset = () => {
    setDeliverables([]);
    setAmount();
    setCustomerDetails();
    setContact();
    setCreatorName();
    setLink([]);
    setLocation();
    setpayment_term();
    setMail();
  };

  return (
    <div>
      <Box className="starter">
        <Modal isOpen={isOpen} onClose={onClose} size={"3xl"}>
          <ModalOverlay />
          <ModalContent className="custom_pdf_popup">
            <ModalCloseButton onClick={handleReset} />
            <ModalBody className="modal-body-p " ref={pdfContentRef}>
              <div className="mobile-width">
                <div
                  className="form-2"
                  style={{ justifyContent: "space-around" }}
                >
                  <Box className="company_logo" style={{ width: "100%" }}>
                    <Box>
                      <img
                        src={logo}
                        style={{ width: "20%", justifyContent: "space-around" }}
                      />
                      <h1 className="titlecm-tc">Creatives Matter LLC</h1>
                    </Box>
                    <h2>Invoice</h2>
                  </Box>
                  <div>
                    <Flex className="space-row">
                      <Box className="one-row-design    edit-file-design">
                        <Flex className="flex-invoice-main">
                          <Box className="invoice-tab">
                            <h2>INVOICE#</h2>
                          </Box>
                          <Box className="input-type-cl">
                            <Input
                              value={InvoiceNumber}
                              type="text"
                              onChange={(e) => handleInvoiceNumber(e)}
                            />
                          </Box>
                        </Flex>
                      </Box>
                      <Box className="one-row-design  edit-file-design">
                        <Flex className="date-issue-ff flex-invoice-main ">
                          <Box className="invoice-tab">
                            <h2 className="date-title-lc min-w-d">
                              DATE ISSUED:{" "}
                            </h2>
                          </Box>
                          <Box className="input-type-cl">
                            <Input
                              id="date"
                              value={currentDate}
                              type="text"
                              onChange={(e) => {
                                handleDateChange(e);
                              }}
                            />
                          </Box>
                          {/* <span>{currentDate}</span> */}
                        </Flex>
                      </Box>
                    </Flex>

                    <Box>
                      <Box>
                        <Flex className="edit-file-design large  ">
                          <h1>CUSTOMER DETAILS:</h1>
                          <textarea
                            id="Customer_Details_input"
                            value={CustomerDetails}
                            type="text"
                            onChange={(e) => handleCustomerDetails(e)}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              border: "1px solid #ccc",
                              lineHeight: "30px",
                              width: "100%",
                              // padding: '8px',
                              borderRadius: "1px",
                              fontSize: "14px",
                              whiteSpace: "pre-wrap", // Preserves white spaces and wraps text to the next line
                              overflowWrap: "break-word",
                              // Breaks long words and wraps to the next line
                              width: "60%",
                              height: "40px",
                              //  paddingTop: '30px',
                              marginTop: "10px",
                            }}
                          />
                        </Flex>
                      </Box>

                      <Box>
                        <Flex className="edit-file-design">
                          <h3>Contact:&nbsp;</h3>
                          <Input
                            value={`${Contact}`}
                            type="text"
                            onChange={(e) => handleContact(e)}
                          />
                        </Flex>
                      </Box>

                      <Box>
                        <Flex className="edit-file-design  Addresses">
                          <h3 className="company-text">Company Address: </h3>
                          <textarea
                            id="Comapny_address_input"
                            value={location}
                            type="text"
                            onChange={(e) => HandleCompanyAddress(e)}
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              border: "1px solid #ccc",
                              lineHeight: "30px",
                              width: "100%",
                              // padding: '8px',
                              borderRadius: "1px",
                              fontSize: "14px",
                              whiteSpace: "pre-wrap", // Preserves white spaces and wraps text to the next line
                              overflowWrap: "break-word",
                              // Breaks long words and wraps to the next line
                              width: "60%",
                              height: "40px",
                              //  paddingTop: '30px',
                              marginTop: "10px",
                            }}
                          />
                        </Flex>
                      </Box>

                      <Box>
                        <Flex className="edit-file-design">
                          <h3>Email/Phone:&nbsp;</h3>
                          <Input
                            value={Mail}
                            type="email"
                            onChange={(e) => HandleMailAddress(e)}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  </div>
                </div>
                <div className="Table-1">
                  <table>
                    <thead>
                      <tr>
                        <th width="35%">Creator’s Name</th>
                        <th colspan="2">Campaign Details</th>
                      </tr>
                      <tr>
                        <td width="35%" className="edit-file-design link ">
                          <Input
                            type="text"
                            value={CreatorName}
                            onChange={(e) => handleCreatorName(e)}
                          />
                        </td>
                        <td colspan="2" className="edit-file-design link  ">
                          {/* <Textarea
              id='Deliverable'
              type='text'
              value={Deliverables}
              onChange={(e)=>handleDeliverable(e)}
              />  */}
                          <Box>
                            {showDeliverable &&
                              showDeliverable.map((Del, index) => {
                                return (
                                  <Box key={index} mb={2}>
                                    {/* <Tag> */}
                                    <p>{Del}</p>
                                    {/* </Tag> */}
                                  </Box>
                                );
                              })}
                          </Box>

                          {/* <Textarea
              type='text'
              value={Links}
              onChange={(e)=>handleLink(e)}
              /> */}
                          <Box>
                            {url &&
                              url.map((ur, index) => {
                                // Check if the URL is absolute; if not, make it absolute
                                if (!ur) {
                                  return null; // Skip rendering if the URL is an empty string
                                }
                                const isAbsoluteUrl =
                                  /^(http|https):\/\//i.test(ur);
                                const href = isAbsoluteUrl
                                  ? ur
                                  : `http://${ur}`;

                                return (
                                  <Box key={index} mb={2}>
                                    <p>
                                      {" "}
                                      <a
                                        className="Links-Invoice  "
                                        href={href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        fontSize="sm"
                                        id={`LiveLink${index + 1}`}
                                      >
                                        LiveLink {index + 1}
                                      </a>
                                    </p>
                                  </Box>
                                );
                              })}
                          </Box>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="payments edit-file-design">
                          <b className="bolds-p">Payment Terms:</b>
                          <textarea
                            id="payment_terms_input"
                            type="text"
                            value={payment_term}
                            onChange={(e) => handlePaymentTerm(e)}
                          ></textarea>
                        </td>

                        <td>
                          <b>Total Amount</b>
                        </td>
                        <td className="edit-file-design ">
                          <b>
                            <Input
                              type="text"
                              value={Amount}
                              onChange={(e) => handleAmount(e)}
                            />
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div>
                  <h2 className="heading-w-border">PAYMENT TO</h2>
                  <Divider />
                  <div className="inner-text">
                    <p>
                      <span className="span-bold">Bank of America:</span>{" "}
                      <span className="lablel_desc">
                        100 North Tryon Street, Charlotte, NC 28202
                      </span>
                    </p>
                    <p>
                      <span className="span-bold">Payee Name:</span>{" "}
                      <span className="lablel_desc"></span>Creatives Matter LLC
                    </p>
                    <p>
                      <span className="span-bold">Account No (USD):</span>{" "}
                      <span className="lablel_desc">488119962570</span>
                    </p>
                    <p>
                      <span className="span-bold">Routing (ACH):</span>{" "}
                      <span className="lablel_desc">
                        <span>1110</span>00025
                      </span>
                    </p>
                    <p>
                      <span className="span-bold">Wires (Domestic):</span>{" "}
                      <span className="lablel_desc">
                        <span>0260</span>09593
                      </span>
                    </p>
                    <p>
                      <span className="span-bold">
                        SWIFT (International/USD):
                      </span>{" "}
                      <span className="lablel_desc">BOFAUS3N</span>
                    </p>
                    <p>
                      <span className="span-bold">
                        SWIFT (International/Non-USD):
                      </span>{" "}
                      <span className="lablel_desc">BOFAUS6S</span>
                    </p>
                    <p>
                      <span className="span-bold">Zelle Email:</span>{" "}
                      <span className="lablel_desc">
                        <span>Michael@CreativesMatter.com</span>
                      </span>
                    </p>
                    <p>
                      <span className="span-bold">Paypal Email:</span>{" "}
                      <span className="lablel_desc">
                        <span>Info@CreativesMatter.com</span>
                      </span>
                    </p>
                  </div>
                </div>

                {/* <div className='margin-top-last'>
         <h2 className='heading-w-border'>ZELLE TO</h2>
         <div className='inner-text'>
         <p>Payee Email: Michael@CreativesMatter.com</p>
         </div>
      </div>

      <div className='margin-top-last'>
         <h2 className='heading-w-border'>PAYPAL TO</h2>
         <div className='inner-text'>
         <p>Payee Email: Info@CreativesMatter.com</p>
         </div>
      </div> */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={convertHtmlToPdfAndSend}>
                Send
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </div>
  );
};

export default Invoice;
