import React, { useState } from "react";
import DealsTable from "./DealsTable";
import AllDealsTab from "./AllDealsTab";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { logDOM } from "@testing-library/react";
import "../Table/Deals.css";
import { useSelector } from "react-redux";

const DealsTab = () => {
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const [roles, setRoles] = useState(role);
  const getInitialTabIndex = () => {
    const storedIndex = localStorage.getItem("TabIndex");
    return storedIndex ? parseInt(storedIndex, 10) : 0;
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(getInitialTabIndex);
  const [activeTab, setActiveTab] = useState(getInitialTabIndex);

  useEffect(() => {
    // Update tabIndex from URL query parameters when location changes
    const queryParams = new URLSearchParams(window.location.search);
    const indexFromQuery = queryParams.get("Tabs");
    if (indexFromQuery !== null) {
      setTabIndex(parseInt(indexFromQuery, 10));
    }
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem("TabIndex", tabIndex);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("Tabs", tabIndex);

    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

    // Modify the current history entry
    window.history.replaceState(null, "", newUrl);

    localStorage.setItem("TabIndex", tabIndex);
  }, [tabIndex]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  useEffect(() => {
    const currentTabs = localStorage.getItem("TabIndex");
    setActiveTab(currentTabs);
  }, [tabIndex]);

  return (
    <div>
      {/* {roles !== 'Influencer' ? ( */}
      <Tabs
        index={tabIndex}
        defaultIndex={activeTab}
        onChange={handleTabsChange}
      >
        <TabList>
          <Tab>Creators</Tab>
          <Tab>All</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <DealsTable />
          </TabPanel>
          <TabPanel>
            <AllDealsTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* ) : <AllDealsTab /> } */}
    </div>
  );
};

export default DealsTab;
