import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";

import "react-quill/dist/quill.snow.css";
import "./quill.css";
import QuillToolbar, { modules, formats } from "./quillConstants";

import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromHTML,
  RichUtils,
  Modifier,
  ContentState,
  getDefaultKeyBinding,
} from "draft-js";
import { convertToHTML } from "draft-convert";
import "draft-js/dist/Draft.css";
import "./DraftJs.css";
import { signature } from "./signature";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ReactEmailEditor } from "./ReactEmailEditor";
import { DraftJsEditor } from "./DraftJsEditor";

export const CustomEditor = ({
  // subjectRef,
  // activeRef,
  templateSubject,
  setTemplateSubject,
  isDisabled,
  templateBody,
  setTemplateBody,
  personalizeList,
}) => {
  const editorRef = useRef(null);
  const [subject, setSubject] = useState(templateSubject);
  const [value, setValue] = useState(templateBody);
  const textAreaRef = useRef(null);
  const subjectRef = useRef(null);
  const [activeInputRef, setActiveInputRef] = useState(null);

  const contentBlocks = convertFromHTML(templateBody);
  const contentStateInitial = ContentState.createFromBlockArray(contentBlocks);
  const initialEditorState = EditorState.createWithContent(contentStateInitial);
  const [editorState, setEditorState] = useState(initialEditorState);

  const focusEditor = () => {
    editorRef.current.focus();
    setActiveInputRef(editorRef);
  };

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToHTML(contentState);
    setTemplateBody(contentRaw);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
      if (newEditorState !== editorState) {
        onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  let className = "RichEditor-editor";
  const currentContentState = editorState.getCurrentContent();
  if (!currentContentState.hasText()) {
    if (currentContentState.getBlockMap().first().getType() !== "unstyled") {
      className += " RichEditor-hidePlaceholder";
    }
  }

  useEffect(() => {
    setValue(templateBody);

    setSubject(templateSubject);
  }, [templateBody, templateSubject]);

  const handleSetValue = (value) => {
    setValue(value);
    setTemplateBody(value);
  };
  const handleSetSubject = (e) => {
    setSubject(e.target.value);
    setTemplateSubject(e.target.value);
  };

  const handleTextAreaFocus = () => {
    setActiveInputRef(editorRef);
  };

  const handleSubjectInputFocus = () => {
    setActiveInputRef(subjectRef);
  };

  const insertVariableInEditor = (variable) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();
    const focusOffset = selectionState.getFocusOffset();

    // Create a new ContentState with the variable inserted
    const contentStateWithVariable = Modifier.insertText(
      currentContent,
      selectionState,
      ` ${variable} `
    );

    // Create a new EditorState with the updated ContentState
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithVariable,
      "insert-characters"
    );

    // Set the selection to be after the inserted variable
    const newSelection = selectionState.merge({
      focusOffset: focusOffset + variable.length + 2,
      anchorOffset: focusOffset + variable.length + 2,
    });

    const newEditorStateWithSelection = EditorState.forceSelection(
      newEditorState,
      newSelection
    );

    // Update the state
    setEditorState(newEditorStateWithSelection);
  };

  const insertVariable = (variable) => {
    if (activeInputRef === editorRef) {
      const personlization = `{${variable}}`;

      if (editorRef.current) {
        // insertVariableInEditor(personlization)
        const quill = editorRef.current.getEditor();
        const cursorPosition = quill.getSelection().index;

        quill.insertText(cursorPosition, " ");
        // quill.insertText(cursorPosition + 1, personlization, {
        //   background: "rgb(255, 255, 0)", // Change to the desired background color
        //   color: "blue", // Change to the desired text color
        // });
        quill.insertText(cursorPosition + 1, personlization);
        quill.insertText(cursorPosition + 1 + personlization.length, " ");
        quill.setSelection(cursorPosition + 1 + personlization.length + 1);
      }
    } else if (activeInputRef === subjectRef) {
      const cursorPosition = subjectRef.current.selectionStart;
      const text = templateSubject;
      const newText =
        text.slice(0, cursorPosition) +
        `{${variable}}` +
        text.slice(cursorPosition);
      setSubject(newText);
      setTemplateSubject(newText);

      // Move the cursor after the inserted text
      subjectRef.current.selectionStart = cursorPosition + variable.length + 2;
      subjectRef.current.selectionEnd = cursorPosition + variable.length + 2;
    }
  };

  return (
    <Box>
      <Flex p={1} justifyContent={"space-between"} alignItems={"center"}>
        <HStack gap={5} w={"100%"}>
          <Text as={"b"}>Subject:</Text>
          <Input
            isDisabled={isDisabled}
            ref={subjectRef}
            type="text"
            variant="flushed"
            value={subject}
            onChange={handleSetSubject}
            placeholder="Subject"
            onFocus={handleSubjectInputFocus}
          ></Input>
        </HStack>
      </Flex>
      <Divider />

      {/* <ReactEmailEditor/> */}
      {/* <DraftJsEditor personalizeList={personalizeList} insertVariable={insertVariable}/> */}
      {/* <div className="RichEditor-root">
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
     
      
      <div className={className} onClick={focusEditor}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={onChange}
          placeholder="Tell a story..."
          ref={editorRef}
          spellCheck
          onFocus={handleTextAreaFocus}
        />
      </div>

      <Flex  alignItems={"center"}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>
        <Menu>
          <MenuButton
            border={0}
            bgColor={"transparent"}
            color={"teal"}
            _hover={{ bgColor: "transparent", textDecoration: "underline" }}
            _expanded={{ bgColor: "transparent", color: "teal" }}
            as={Button}
            size={"sm"}
            rightIcon={<ChevronDownIcon />}
          >
            Personlization
          </MenuButton>
          <MenuList>
            {personalizeList.map((variable) => (
              <MenuItem key={variable} onClick={() => insertVariable(variable)}>
                {variable}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      
    </div> */}

      <Flex alignItems={"center"} className="new_template">
        <QuillToolbar />
        <Center height="30px">
          <Divider orientation="vertical" />
        </Center>
        <Menu>
          <MenuButton
            border={0}
            bgColor={"transparent"}
            color={"teal"}
            _hover={{ bgColor: "transparent", textDecoration: "underline" }}
            _expanded={{ bgColor: "transparent", color: "teal" }}
            as={Button}
            size={"sm"}
            rightIcon={<ChevronDownIcon />}
          >
            Personalization
          </MenuButton>
          <MenuList>
            {personalizeList.map((variable) => (
              <MenuItem key={variable} onClick={() => insertVariable(variable)}>
                {variable}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      <ReactQuill
        preserveWhitespace={true}
        // placeholder={"Write something or insert a heart ♥"}
        ref={editorRef}
        theme="snow"
        value={value}
        onChange={handleSetValue}
        style={{ height: "300px" }}
        modules={modules}
        formats={formats}
        onFocus={handleTextAreaFocus}
      />
    </Box>
  );
};
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
};

const StyleButton = ({ onToggle, style, active, label }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onToggle(style);
  };

  let className = "RichEditor-styleButton";
  if (active) {
    className += " RichEditor-activeButton";
  }

  return (
    <span className={className} onMouseDown={handleClick}>
      {label}
    </span>
  );
};

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" },
];

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" },
];

const InlineStyleControls = ({ editorState, onToggle }) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};
