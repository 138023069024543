import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  StackDivider,
  Box,
  Text,
  Input,
  Icon,
  Divider,
  useColorMode,
  Textarea,
  Button,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { MdDelete, MdSettings } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import { useState } from "react";
import { JobDeleteDialogBox } from "../Modal/JobDeleteDialogBox";
import { useEffect } from "react";
import { parseISO, format } from "date-fns";
import { forEach } from "lodash";
import { useNavigate } from "react-router-dom";

export const EmailStatus = ({
  campaign_id,
  status_id,
  statusData,
  fetchData,
}) => {
  const [selectedJob, setSelectedJob] = useState();
  const [isOpenDeleteDialogBox, setIsOpenDeleteDialogBox] = useState(false);
  const [jobIdTodelete, setJobIdToDelete] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { colorMode } = useColorMode();

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const toast = useToast();

  const jobKeys = Object.keys(statusData);

  useEffect(() => {
    const jobKeys = Object.keys(statusData);
    for (const key of jobKeys) {
      if (statusData[key].job_id !== null) {
        setSelectedJob(statusData[key]);
        break;
      }
    }
  }, []);

  const handleJobDeleteClick = (job_id) => {
    setJobIdToDelete(job_id);
    // if(jobIdTodelete)
    setIsOpenDeleteDialogBox(true);
  };

  useEffect(() => {
    setSelectedJob(statusData.job_1);
  }, [statusData]);

  const deleteJob = async () => {
    setIsDeleting(true);
    const payload = {
      job_id: jobIdTodelete,
      status_id: status_id,
    };
    try {
      const response = await fetch(BASE_URL + `/deletejob/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      const message = await response.json();

      if (message.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      setIsDeleting(false);
      if (response.ok) {
        toast({
          title: "Deleted",
          description: "Deletion Successful",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        setIsOpenDeleteDialogBox(false);
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Fetch Error.",
        description: "Failed to save the template",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
    fetchData();
  };

  const EmailTemplateBox = ({ jobDetails }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [editedTemplateSubj, setEditedTemplateSubj] = useState(
      jobDetails?.template_subject
    );
    const [editedTemplateDesc, setEditedTemplateDesc] = useState(
      jobDetails?.template_desc
    );

    const toggleEditable = () => {
      setIsEditable(!isEditable);
    };

    const handleTemplateDescChange = (event) => {
      setEditedTemplateDesc(event.target.value);
    };

    const handleTemplateSave = async () => {
      const payload = {
        template_id: jobDetails.template_id,
        template_type: jobDetails.template_type,
        template_subject: editedTemplateSubj,
        template_desc: editedTemplateDesc,
        campaign: campaign_id,
      };
      setIsUpdating(true);
      try {
        const response = await fetch(BASE_URL + `/updatetemplate/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });
        const res = await response.json();
        console.error("message:", res.message);
        setIsUpdating(false);
        // setIsEditable(!isEditable);
        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        if (response.ok) {
          toast({
            title: "Updated",
            description: "updation Successful",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        // setIsLoading(false);
        toast({
          title: "Fetch Error.",
          description: "Failed to save the template",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      }

      fetchData();
    };
    return (
      <>
        <Box padding={4} display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Text mr={2} textColor={"gray.500"}>
              Subject
            </Text>
            {isEditable ? (
              <Input
                type="text"
                value={editedTemplateSubj}
                onChange={(e) => setEditedTemplateSubj(e.target.value)}
              ></Input>
            ) : (
              <Text as={"b"}>{editedTemplateSubj}</Text>
            )}
          </Box>
          <Box>
            {isEditable ? (
              <Button
                isLoading={isUpdating}
                colorScheme="teal"
                size="sm"
                variant={"outline"}
                onClick={handleTemplateSave}
              >
                {" "}
                Save{" "}
              </Button>
            ) : (
              <Button
                isLoading={isUpdating}
                colorScheme="teal"
                size="sm"
                onClick={toggleEditable}
              >
                {" "}
                Edit{" "}
              </Button>
            )}
          </Box>
        </Box>
        <Divider />
        <Box padding={4}>
          {isEditable ? (
            <Textarea
              value={editedTemplateDesc}
              onChange={handleTemplateDescChange}
              size="lg"
              rows={15} // Adjust the number of rows as needed
            />
          ) : (
            <Text whiteSpace="pre-line">{editedTemplateDesc}</Text>
          )}
        </Box>
      </>
    );
  };

  //   job Statuscard component
  const StatusCard = ({ jobKey, jobData }) => {
    return (
      <Card w="2xs" bg={"gray.100"}>
        {/* <CardHeader>
              <Heading size="md">Client Report</Heading>
            </CardHeader> */}

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Icon boxSize={5} as={FiMail} mr={1} color="teal.400" />
                <Text size="md" as={"b"} textTransform="uppercase">
                  {jobKey}
                </Text>
              </Box>

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Icon boxSize={5} as={MdSettings} mr={2} color="teal.500" />
                {/* <Tooltip label='Delete this job?' bg='red.600'> */}
                <Icon
                  boxSize={6}
                  borderRadius={"5px"}
                  as={MdDelete}
                  onClick={() => {
                    handleJobDeleteClick(jobData.job_id);
                  }}
                  color="teal.500"
                  _hover={{ bgColor: "gray.300" }}
                />
                {/* </Tooltip> */}
              </Box>
            </Box>
            <Box>
              <Text pt="2" fontSize="sm">
                Send email: {jobData.template_subject}
              </Text>
            </Box>
            <Box>
              {/* <Text pt="2" fontSize="sm">
                wait for <Input width={"50px"} bg={"white"} value={3} onChange={()=>{}} /> days,
                then
              </Text> */}
              {/* <Text>{jobData.status}</Text> */}
              <Text as={"b"}>Scheduled run time: </Text>
              <Text>
                {format(new Date(jobData.next_run_time), "dd/MM/yyyy HH:mm:ss")}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );
  };
  return (
    <Box display={"flex"} gap={4}>
      <Box>
        <Box
          sx={{
            "&::-webkit-scrollbar": { width: "3px" },
            "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
            "&::-webkit-scrollbar-thumb": { background: "#888" },
            "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
            scrollbarWidth: "3px",
          }}
          height={"420px"}
          overflowY={"auto"}
          //   display={'flex'}
          //   flexDirection={'column'}
          //   gap={1}
        >
          {jobKeys.map(
            (jobKey) =>
              statusData[jobKey].job_id && (
                <Box
                  key={jobKey}
                  onClick={() => setSelectedJob(statusData[jobKey])}
                  _hover={{
                    // border: "1px soild green",
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    cursor: "pointer",
                  }}
                  my={1}
                  mr={2}
                  border={"2px"}
                  borderRadius={"5px"}
                  borderColor={
                    selectedJob?.job_id === statusData[jobKey].job_id
                      ? "teal.500"
                      : "transparent"
                  }
                >
                  <StatusCard jobKey={jobKey} jobData={statusData[jobKey]} />
                </Box>
              )
          )}

          <Box mt={4} mr={2}>
            <Button colorScheme="teal" variant={"outline"} width={"full"}>
              Add New Job
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        width={"800px"}
        height={"420px"}
        border="1px"
        borderColor="gray.200"
        borderRadius={"5px"}
        overflow={"auto"}
      >
        <EmailTemplateBox jobDetails={selectedJob}></EmailTemplateBox>
      </Box>

      {/* jobDelete alert */}
      <JobDeleteDialogBox
        isOpen={isOpenDeleteDialogBox}
        onClose={() => setIsOpenDeleteDialogBox(false)}
        onYes={deleteJob}
        onNo={() => setIsOpenDeleteDialogBox(false)}
        isDeleting={isDeleting}
      />
    </Box>
  );
};
