import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SequenceDetails } from "../components/Sequences/SequenceDetails";

export const SequencesLists = ({
  filterValue,
  setFilterValue,
  handleInputChange,
  sequences,
  updateSelectedSequence,
}) => {
  const [selectedSequence, setSelectedSequence] = useState(sequences[0]);
  const isDisabled = true;

  const handleSequenceClick = (seq) => {
    setSelectedSequence(seq);
    updateSelectedSequence(seq);
    setFilterValue("");
  };

  return (
    <Box p={1} bgColor={"#F5F5F5"} m={1} display={"flex"} h={"70vh"}>
      <Box
        p={2}
        px={4}
        w={"30%"}
        bgColor={"white"}
        h={"100%"}
        overflow={"auto"}
        sx={{
          "&::-webkit-scrollbar": { width: "5px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
          scrollbarWidth: "10px",
        }}
        // boxShadow={"0 4px 8px rgba(0, 128, 128, 0.4) inset"}
      >
        {/* all existing sequences */}
        <Box m={1} mt={3}>
          <Text as={"b"} fontSize={"lg"}>
            Start with pre-made Sequences
          </Text>

          <Input
            type="text"
            placeholder="Search Sequences..."
            width={"300px"}
            value={filterValue}
            borderRadius={"100vh"}
            onChange={handleInputChange}
          />
          {sequences?.length > 0 ? (
            sequences?.map((seq, index) => {
              return (
                <Card
                  key={seq.id}
                  maxW="md"
                  my={2}
                  p={3}
                  overflow="hidden"
                  variant="elevated"
                  onClick={() => {
                    //   setSelectedSequence(seq);
                    handleSequenceClick(seq);
                  }}
                  border={
                    selectedSequence && selectedSequence.id === seq.id
                      ? "3px solid teal"
                      : ""
                  }
                  _hover={{
                    boxShadow: "2px 4px 8px rgba(0, 128, 128, 0.4)",
                  }}
                >
                  <CardBody>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Heading size="xs" textTransform="uppercase">
                        {seq.name}
                      </Heading>

                      {/* <IconButton
                        borderRadius={"50%"}
                        onClick={(event) => handleSequenceDelete(seq.id)}
                        size={'sm'}
                        icon={
                          <Icon
                            boxSize={5}
                            color={"green.500"}
                            as={MdDelete}
                          />
                        }
                      /> */}
                    </Flex>

                    <Text pt="2" fontSize="sm">
                      {seq.description}
                    </Text>
                  </CardBody>
                </Card>
              );
            })
          ) : (
            <Box>
              <Text>No sequence available</Text>
            </Box>
          )}
        </Box>
      </Box>
      {/* right container */}
      <Box
        w={"70%"}
        margin={"auto"}
        p={5}
        h={"100%"}
        overflow={"auto"}
        sx={{
          "&::-webkit-scrollbar": { width: "5px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
          scrollbarWidth: "5px",
        }}
      >
        <Box h={"90%"} mt={2} className="seq_details">
          {selectedSequence && (
            <SequenceDetails
              isDisabled={isDisabled}
              selectedSequence={selectedSequence}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
