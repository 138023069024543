import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { FaUserPlus } from "react-icons/fa";
import CampaignDialogBox from "../Modal/CampaignDialogBox";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { IoImages } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { BiCaretDown } from "react-icons/bi";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Table/datepicker.css";
import { AiFillCalendar } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";

const ScoutFormModel = ({ isOpen, onClose, fetchScoutData }) => {
  const [realEmail, setRealEmail] = useState(null);
  const [realId, setRealId] = useState(null);
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const routesConfig = [
    {
      path: "/admin",
      role: "Admin",
    },
    {
      path: "/creator",
      role: "Creator Manager",
    },
    {
      path: "/influencer",
      role: "Influencer",
    },
  ];
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const UserEmail =
    useSelector((state) => state.userEmail) ||
    localStorage.getItem("userEmail");

  useEffect(() => {
    // Set realEmail only when UserEmail changes or on component mount
    setRealEmail(UserEmail);
  }, [UserEmail]); // Dependency array ensures it runs only when UserEmail changes

  const toast = useToast();
  const imageInputRef = useRef(null);
  const wishListRef = useRef(null);
  const blackListRef = useRef(null);

  const [startDate, setStartDate] = useState(null);
  const handleDateChange = (date) => {
    setStartDate(date);
    handleScoutDataChange({ target: { name: "birthday", value: date } });
  };
  const [isOpenCampaignDialogBox, setIsOpenCampaignDialogBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const [filteredCompanyDomainData, setFilteredCompanyDomainData] = useState(
    []
  );
  const [managerData, setManagerData] = useState([]);
  const [selectedDomains, setSelectedDomains] = useState({
    domainIDs: [],
    domainNames: [],
  });

  const [searchMetaTag, setSearchMetaTag] = useState("");
  const [filteredMetaTagData, setFilteredMetaTagData] = useState([]);
  const [selectedBlackListMetaTags, setSelectedBlackListMetaTags] = useState({
    metaTagIDs: [],
    metaTags: [],
  });

  const [activeMetaTagDropdown, setActiveMetaTagDropdown] = useState(false);
  const [metaTags, setMetaTags] = useState([]);
  const [selectedMetaTags, setSelectedMetaTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [ScoutData, setScoutData] = useState({
    profile_picture: "",
    name: "",
    talentName: "",
    email: "",
    Vertical: "",
    youtubeUserName: "",
    instagramUserName: "",
    tiktokUserName: "",

    assigned_to: "",
  });
  const [errors, setErrors] = useState({
    profile_picture: "",
    name: "",
    talentName: "",
    email: "",
    phone: "",
    Vertical: "",
    birthday: "",
    // metaTag: ""
  });

  // const ExampleCustomInput =  React.forwardRef(({ value, onClick }, ref) => (
  //     <Button variant={"outline"} display={'flex'} justifyContent={'start'} w={'410px'} leftIcon={<AiFillCalendar/>} onClick={onClick} ref={ref} >
  //    {value?value:"MM/DD/YY"}
  //     </Button>
  //   ));

  const handleScoutDataChange = (e) => {
    const { name, value } = e.target;
    setRealId(value);
    setScoutData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = () => {
    setIsOpenCampaignDialogBox(true);
  };

  const handleYes = () => {
    setIsOpenCampaignDialogBox(false);
    handleSubmittingData(true);
  };

  const handleNo = () => {
    setIsOpenCampaignDialogBox(false);
    handleSubmittingData(false);
  };

  const handleSubmittingData = (flag) => {
    if (!ScoutData.profile_picture) {
      setErrors((prev) => ({
        ...prev,
        profile_picture: "profile picture url  is required.",
      }));
      return;
    }

    if (!ScoutData.name) {
      setErrors((prev) => ({ ...prev, name: "name is required." }));
      return;
    }

    if (!ScoutData.talentName) {
      setErrors((prev) => ({
        ...prev,
        talentName: "Talent name is required.",
      }));
      return;
    }

    if (!ScoutData.email) {
      setErrors((prev) => ({ ...prev, email: "Email is required." }));
      return;
    }

    setIsLoading(true);

    const InfluencerPayload = {
      scout_data: {
        // Wrap the data inside "scout_data"
        profile_picture: ScoutData.profile_picture,
        name: ScoutData.name,
        talent_name: ScoutData.talentName,
        email: ScoutData.email,
        Vertical: ScoutData.Vertical,
        youTube_username:
          ScoutData.youtubeUserName && `@${ScoutData.youtubeUserName}`,
        instagram_username:
          ScoutData.instagramUserName && `${ScoutData.instagramUserName}`,
        tikTok_username:
          ScoutData.tiktokUserName && `@${ScoutData.tiktokUserName}`,

        assigned_to: ScoutData.assigned_to,
        // flag: flag
      },
    };
    fetch(BASE_URL + "/create-scout/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(InfluencerPayload),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);

        if (data.message) {
          toast({
            title: "Onboarding successful",
            description: data.message,
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });

          setTimeout(() => {
            setScoutData({
              profile_picture: "",
              name: "",
              talentName: "",
              email: "",
              Vertical: "",
              youtubeUserName: "",
              instagramUserName: "",
              tiktokUserName: "",
              assigned_to: "",
            });

            // setSelectedMetaTags([]);
            fetchScoutData();
            setProfilePictureURL("");
            onClose();
          });
        } else if (data.error) {
          const errorMessage = data.details?.email
            ? data.details.email[0]
            : "Failed to Onboard";

          toast({
            title: "Onboarding Failed",
            description: errorMessage,
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);

        toast({
          title: "Onboarding Error",
          description: "Failed to Onboarding",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      });
  };

  const fetchManagerOptions = async (id) => {
    try {
      const response = await fetch(BASE_URL + `/get-influencer-users/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (data) {
        // setManagerOptions(data.data);
        setManagerData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching Manager Options:", error);
    }
  };
  useEffect(() => {
    if (realEmail && managerData) {
      var manager = managerData?.find((option) => option.email === realEmail);
    }
    if (manager) {
      setRealId(manager.id);
    }
  }, [realEmail, managerData]);

  useEffect(() => {
    fetchManagerOptions();
  }, [selectedMetaTags]);

  useEffect(() => {
    if (activeMetaTagDropdown) {
      fetchManagerOptions();
    }
  }, [activeMetaTagDropdown]);

  useEffect(() => {
    if (wishListRef.current) {
      wishListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    if (blackListRef.current) {
      blackListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [filteredCompanyDomainData, filteredMetaTagData]);

  return (
    <Box>
      <Drawer size={"xl"} isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"#ffffff"} />
          <DrawerHeader
            mb={3}
            bg={"linear-gradient(to right, #00AA45, #32bb6a, #66cc8f)"}
            color={"#ffffff"}
            fontWeight={"500"}
            fontSize={"2xl"}
          >
            <Flex alignItems={"center"} gap={2}>
              <FaUserPlus size={28} />
              New Creator
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Box>
              <Flex gap={5} mb={6}>
                <FormControl isRequired isInvalid={!!errors.profile_picture}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    ProfilePictureURL
                  </FormLabel>
                  <Input
                    name="profile_picture"
                    type="text"
                    value={ScoutData.profile_picture}
                    onChange={handleScoutDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.profile_picture}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.talentName}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Talent Name
                  </FormLabel>
                  <Input
                    name="talentName"
                    type="text"
                    value={ScoutData.talentName}
                    onChange={handleScoutDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.talentName}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.name}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    First Name
                  </FormLabel>
                  <Input
                    name="name"
                    type="text"
                    value={ScoutData.name}
                    onChange={handleScoutDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap={5} mb={6}>
                <FormControl isRequired isInvalid={!!errors.email}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Email
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={ScoutData.email}
                    onChange={handleScoutDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Vertical
                  </FormLabel>
                  <Input
                    name="Vertical"
                    type="text"
                    value={ScoutData.Vertical}
                    onChange={handleScoutDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.Vertical}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex gap={5} mb={6}></Flex>
              <Flex gap={5} mb={6}>
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="64px"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    YouTube Username
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents="none"
                      color="gray.500"
                      fontSize="1.2em"
                      children="@"
                    />
                    <Input
                      name="youtubeUserName"
                      type="text"
                      value={ScoutData.youtubeUserName}
                      onChange={handleScoutDataChange}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="64px"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Instagram Username
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents="none"
                      color="gray.500"
                      fontSize="1.2em"
                      children="@"
                    />
                    <Input
                      name="instagramUserName"
                      type="text"
                      value={ScoutData.instagramUserName}
                      onChange={handleScoutDataChange}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="64px"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    TikTok Username
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      pointerEvents="none"
                      color="gray.500"
                      fontSize="1.2em"
                      children="@"
                    />
                    <Input
                      name="tiktokUserName"
                      type="text"
                      value={ScoutData.tiktokUserName}
                      onChange={handleScoutDataChange}
                    />
                  </InputGroup>
                </FormControl>
              </Flex>

              <Flex gap={3} mb={6}>
                <FormControl isRequired isInvalid={!!errors.metaTag}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Assign to
                  </FormLabel>

                  <Select
                    name="assigned_to"
                    className="custom-dropdown"
                    style={{ resize: "both", minWidth: "200px" }}
                    fontSize="sm"
                    //   onChange={(e) => {
                    //
                    //   }}
                    onChange={handleScoutDataChange}
                    // value={userData.account_type}
                    // placeholder="Select a Manager"
                    _focus={{ zIndex: "0" }}
                    value={realId}
                  >
                    {managerData.map((option) => (
                      <option key={option.id} id={option.id} value={option.id}>
                        {option.first_name + " " + option.last_name}
                      </option>
                    ))}
                  </Select>

                  <FormErrorMessage>{errors.assigned_to}</FormErrorMessage>
                </FormControl>
              </Flex>
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button
              isLoading={isLoading}
              spinner={<PulseLoader size={8} color="white" />}
              loadingText="Creating"
              spinnerPlacement="end"
              colorScheme="green"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <CampaignDialogBox
        isOpen={isOpenCampaignDialogBox}
        onClose={() => setIsOpenCampaignDialogBox(false)}
        onNo={handleNo}
        onYes={handleYes}
      />
    </Box>
  );
};

export default ScoutFormModel;
