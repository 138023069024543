import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const ConfirmCampaignDialogBox = ({ isOpen, onClose, onYes }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [routeconfigpath, setRouteConfigPath] = useState("");

  const routesConfig = [
    {
      path: "/admin",
      role: "Admin",
    },
    {
      path: "/creator",
      role: "Creator Manager",
    },
    {
      path: "/influencer",
      role: "Influencer",
    },
  ];

  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  useEffect(() => {
    const routeConfig = routesConfig.find((route) => route.role === role);
    if (routeConfig) {
      setRouteConfigPath(routeConfig.path);
    }
  }, [role]);

  const handleYesClick = async () => {
    setLoading(true);
    await onYes();
    navigate(`${routeconfigpath}/Outreach`);
    setLoading(false);
  };

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Create Campaign
          </AlertDialogHeader>

          <AlertDialogBody>
            Do you really want to Create this Campaign?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button colorScheme="yellow" onClick={onClose}>
              No
            </Button>
            <Button
              isLoading={loading}
              colorScheme="red"
              ml={3}
              onClick={handleYesClick}
            >
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
