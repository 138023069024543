import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { PiUploadSimpleThin } from "react-icons/pi";
import { ImFileExcel } from "react-icons/im";
import { BsXCircle } from "react-icons/bs";
import { VscNewFile } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { id } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const DataUploader = () => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { colorMode } = useColorMode();
  const toast = useToast();
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelectedFile, setIsSelectedFile] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [jobStatus, setJobStatus] = useState(null);
  const [uploadedFileId, setUploadedFileId] = useState();

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setIsSelectedFile(false);
    fileInputRef.current.value = null;
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(1)) + " " + sizes[i];
  };

  const uploadFile = () => {
    return new Promise(async (resolve, reject) => {
      if (selectedFile) {
        setIsLoading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);

        try {
          const response = await fetch(BASE_URL + "/upload-csv/", {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status === 401) {
            console.error("Unauthorized: User session expired");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            navigate("/login");
          }
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();

          setIsLoading(false);

          if (data) {
            setUploadedFileId(data.scheduled_upload_id);
            setSelectedFile(null);
            setIsSelectedFile(true);
          }

          resolve(data); // Resolve the promise with the data if successful
        } catch (error) {
          setUploadedFileId(null);
          setIsLoading(false);
          console.error("Error:", error);
          reject(error); // Reject the promise with the error
        }
      } else {
        reject(new Error("No file selected")); // Reject the promise if no file is selected
      }
    });
  };

  const handleUpload = async () => {
    toast.promise(uploadFile(), {
      success: (resolvedData) => {
        // Use the resolved data's message property in the toast
        return {
          title: "CSV File Uploaded",
          description: resolvedData.message,
          status: "success",
          position: "top-right",
          isClosable: true,
        };
      },
      error: (rejectedData) => {
        setShowToast(false);
        return {
          title: "Upload Failed",
          description: "something went wrong",
          status: "error",
          position: "top-right",
          isClosable: true,
        };
      },
      loading: {
        title: "Uploading",
        description: "File is being processed by the server",
        status: "info",
        position: "top-right",
        isClosable: true,
      },
    });
  };

  const handleSampleCSVDownload = () => {
    const url = process.env.PUBLIC_URL + "/marketing_data.csv";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "marketing_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    // Function to fetch job status
    let interval = {};
    const updateSourceStatus = async (uploadedFile) => {
      try {
        const response = await fetch(BASE_URL + "/check-status/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ scheduled_upload_id: uploadedFile }),
        }); // Replace with your actual API endpoint
        const data = await response.json();

        if (data.code === "token_not_valid") {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        setJobStatus(data.upload_status);
        // Assuming the status is returned in the response
        if (
          data.upload_status === "job not found" ||
          data.upload_status === "processed" ||
          data.upload_status === "failed"
        ) {
          // setJobStatus(data.upload_status)
          setShowToast(false);
          clearInterval(interval);
        } else {
          setShowToast(true);
        }
      } catch (error) {
        console.error("Error fetching job status:", error);
        // Handle errors here if needed
      }
    };

    // Loop through the sourceDetails and start setInterval for pending sources
    if (uploadedFileId) {
      interval = setInterval(() => {
        updateSourceStatus(uploadedFileId);
      }, 5000);
    } else {
      clearInterval(interval);
    }

    // return () => {
    //   // Clear all the interval timers when the component unmounts
    //     clearInterval(interval)

    // };
  }, [uploadedFileId, jobStatus]);

  useEffect(() => {
    if (jobStatus === "processed" || jobStatus === "job not found") {
      toast({
        title: "CSV File Uploaded",
        description: "file processing done",
        status: "success",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    } else if (jobStatus === "failed") {
      toast({
        title: "Failed",
        description: "file processing Failed",
        status: "warning",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [jobStatus]);

  return (
    <Box>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        height={"calc(100vh - 60px)"}
      >
        <Card
          direction={{ base: "column", sm: "row" }}
          overflow="hidden"
          variant="outline"
        >
          <Stack>
            <CardBody>
              <Box
                border={"2px dashed #00AA45"}
                borderRadius={"10px"}
                p={"60px 150px"}
              >
                <Flex flexDirection={"column"} alignItems={"center"}>
                  <VscNewFile size={25} />
                  <Text fontSize={"14px"} mt={"5px"}>
                    Upload a CSV file
                  </Text>
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept="text/csv"
                  />
                  <Button
                    mt={"30px"}
                    size={"sm"}
                    pl={"30px"}
                    pr={"30px"}
                    colorScheme="green"
                    onClick={() => fileInputRef.current.click()}
                  >
                    Browse Files
                  </Button>
                </Flex>
              </Box>
            </CardBody>
          </Stack>

          <Stack>
            <CardBody>
              <Link to={"#"} onClick={handleSampleCSVDownload}>
                <Heading
                  size="sm"
                  fontWeight={"500"}
                  textDecoration={"underline"}
                  _hover={{ color: "#2F855A" }}
                >
                  Sample CSV Downloadable File
                </Heading>
              </Link>
              <Box py="2" fontSize={"12px"}>
                {selectedFile ? (
                  <Flex
                    alignItems={"center"}
                    gap={"10px"}
                    bg={colorMode === "light" ? "#EDF2F7" : "#2C313D"}
                    p={"10px"}
                    borderRadius={"5px"}
                  >
                    <ImFileExcel size={30} color="#1F6E42" />
                    <Box>
                      <Text fontWeight={"500"}>{selectedFile.name}</Text>
                      <Text fontSize={"11px"}>
                        ({formatFileSize(selectedFile.size)})
                      </Text>
                    </Box>
                    <BsXCircle
                      size={15}
                      color="red"
                      onClick={() => {
                        setSelectedFile(null);
                        setIsSelectedFile(true);
                      }}
                      cursor={"pointer"}
                    />
                  </Flex>
                ) : (
                  <Text>No file browsed yet.</Text>
                )}
              </Box>
            </CardBody>
            <CardFooter
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                isLoading={showToast}
                loadingText={jobStatus}
                spinnerPlacement="end"
                leftIcon={<PiUploadSimpleThin />}
                variant="solid"
                size={"sm"}
                colorScheme="purple"
                onClick={handleUpload}
                isDisabled={isSelectedFile}
              >
                Upload
              </Button>
            </CardFooter>
          </Stack>
        </Card>
      </Flex>
    </Box>
  );
};

export default DataUploader;
