// import React from 'react'
import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import NavigationPanel from "../components/Panel/NavigationPanel";
import TopPanel from "../components/Panel/TopPanel";
import { Outlet } from "react-router-dom";
const Influencerlog = ({ mainPath }) => {
  return (
    <Flex flexWrap={"wrap"} width={"100%"} boxSizing='border-box'>
      <NavigationPanel mainPath={mainPath} />
      <Box flex={"3"} minWidth={"300px"}>
        <TopPanel />
        <Outlet />
        {/* <AlertDialogBox /> */}
        {/* <Flex height={"calc(100vh - 60px)"} alignItems={"center"} justifyContent={"center"} fontWeight={"500"} fontSize={"20px"}>Hold steady, enhancements are in progress !!!</Flex> */}
      </Box>
    </Flex>
  );
};

export default Influencerlog;
