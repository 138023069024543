import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from "@chakra-ui/react";
import ClockConfirmationModel from "./ClockConfirmationModel";

const ClockModel = ({ isOpen, onClose, clockDate, DeleteFolowUp }) => {
  const [isOpenClockConfirmationModel, setIsOpenClockConfirmationModel] =
    useState(false);

  const handleCancelClock = () => {
    setIsOpenClockConfirmationModel(true);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Follow Up Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Sheduled date: {clockDate}</Text>
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button> */}
            <Button
              variant="outline"
              colorScheme="red"
              onClick={() => handleCancelClock()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ClockConfirmationModel
        isOpen={isOpenClockConfirmationModel}
        onClose={() => setIsOpenClockConfirmationModel(false)}
        DeleteFolowUp={DeleteFolowUp}
      />
    </>
  );
};

export default ClockModel;
